export class Utils {

    static sort(object, props) {
        return object.sort((a,b) => {
            if(a[props] > b[props]) return 1;
            if(a[props] < b[props]) return -1;
            if(a[props] == b[props]) return 0;
        })
    }

    static isMobile() {
        return window.innerWidth <= 576;
    }

    static getMobileOperatingSystem() {
        var userAgent = navigator.userAgent || navigator.vendor;
    
        // Windows Phone must come first because its UA also contains "Android"
        if (/windows phone/i.test(userAgent)) {
            return "Windows Phone";
        }
    
        if (/android/i.test(userAgent)) {
            return "Android";
        }
    
        // iOS detection from: http://stackoverflow.com/a/9039885/177710
        if (/iPad|iPhone|iPod/.test(userAgent)) {
            return "iOS";
        }
    
        return "unknown";
    }

    static uuid(): string {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
          var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
          return v.toString(16);
        });
      }
}
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-inserimento-ricerca-modal',
  templateUrl: './inserimento-ricerca-modal.component.html',
  styleUrls: ['./inserimento-ricerca-modal.component.scss']
})
export class InserimentoRicercaModalComponent implements OnInit {
  @Input() slug;
  qui_cerchi: string;
  proponi: string
  prefix: string = 'Qui CERCHI';
  suffix: string = 'Vai in PROPONI per candidarti';

  btnRicerca: string = 'Sono nel posto giusto.';
  btnProposta: string = 'Voglio candidarmi.';


  constructor(
    private activeModal: NgbActiveModal,
    private router: Router
  ) { }

  ngOnInit(): void {
    switch (this.slug) {
      case 'figura-professionale-cerca': {
        this.prefix = 'Qui sei un';
        this.qui_cerchi = 'RECRUITER e CERCHI professionisti da assumere';
        this.suffix = 'Vai in PROPONI per candidarti';
        this.btnRicerca = 'Voglio ASSUMERE';
        this.btnProposta = 'Voglio CANDIDARMI';
        break;
      }
      case 'figura-artistica-cerca': {
        this.prefix = 'Qui sei un';
        this.qui_cerchi = 'RECRUITER e CERCHI artisti da assumere';
        this.suffix = 'Vai in PROPONI per candidarti';
        this.btnRicerca = 'Voglio ASSUMERE';
        this.btnProposta = 'Voglio CANDIDARMI';
        break;
      }
      case 'noleggio-equipments-cerca': {
        this.prefix = 'Qui CERCHI';
        this.qui_cerchi = 'forniture per il tuo lavoro';
        this.suffix = 'Vai in PROPONI per fornire le tue forniture';
        this.btnProposta = 'PROPONI forniture';
        break;
      }
      case 'noleggio-locations-cerca': {
        this.prefix = 'Qui CERCHI';
        this.qui_cerchi = 'Location per il tuo lavoro';
        this.suffix = 'Vai in PROPONI per fornire la tua Location';
        this.btnProposta = 'PROPONI Location';
        break;

      }
      case 'noleggio-arredo-costumi-cerca': {
        this.prefix = 'Qui CERCHI';
        this.qui_cerchi = 'Arredi e Costumi per il tuo lavoro';
        this.suffix = 'Vai in PROPONI per fornire le tue soluzioni';
        this.btnProposta = 'PROPONI soluzioni';
        break;

      }
      case 'noleggio-trasporti-cerca': {
        this.prefix = 'Qui CERCHI';
        this.qui_cerchi = 'Servizi di Trasporti per il tuo lavoro';
        this.suffix = 'Vai in PROPONI per fornire Servizi di Trasporti';
        this.btnProposta = 'PROPONI soluzioni';
        break;

      }
      case 'noleggio-auto-moto-nautica-cerca': {
        this.prefix = 'Qui CERCHI';
        this.qui_cerchi = 'Auto Moto Nautica per il tuo lavoro';
        this.suffix = 'Vai in PROPONI per fornire Servizi di noleggio';
        this.btnProposta = 'PROPONI soluzioni';
        break;

      }
      case 'noleggio-animali-cerca': {
        this.prefix = 'Qui CERCHI';
        this.qui_cerchi = 'Animali per il tuo lavoro';
        this.suffix = 'Vai in PROPONI per fornire Animali per i progetti';
        this.btnProposta = 'PROPONI Animali';
        break;

      }
      case 'film-services-italia-cerca': {
        this.prefix = 'Qui CERCHI';
        this.qui_cerchi = 'Agenzie o professionisti per i permessi OSAP';
        this.suffix = 'Vai in PROPONI per fornire Servizi di permessi OSAP';
        this.btnProposta = 'PROPONI soluzioni';
        break;

      }
      case 'space-stage-studios-cerca': {
        this.prefix = 'Qui CERCHI';
        this.qui_cerchi = 'Servizi di film studios, sale posa per il tuo lavoro';
        this.suffix = 'Vai in PROPONI per offrire la tua impresa';
        this.btnProposta = 'PROPONI soluzioni';
        break;

      }
    }
  }

  dismiss() {
    this.activeModal.dismiss();
  }

  goToProposta() {
    this.dismiss();
    const slug = this.slug.replace('-cerca', '');
    this.router.navigateByUrl(`/inserimento-proposta/${slug}`);
  }

}

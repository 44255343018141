import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { LoadingService } from '../services/loading.service';
import { ProposalService } from '../services/proposal.service';
import { ResearchService } from '../services/research.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { JobSendedComponent } from '../job-sended/job-sended.component';
import { MatchService } from '../services/match.service';
import { Gallery } from 'angular-gallery';
import { ShareSchedaComponent } from '../share-scheda/share-scheda.component';
import { CookieService } from 'ngx-cookie-service';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-preview-forms',
  templateUrl: './preview-forms.component.html',
  styleUrls: ['./preview-forms.component.scss']
})
export class PreviewFormsComponent implements OnInit {
  service: ProposalService | ResearchService;
  slug: string;
  uuid: string;
  match_uuid: string;

  isCerca: boolean;
  isScheda: boolean;

  form;
  title;
  alreadyCandidate: boolean;
  section: string;
  token: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private proposalService: ProposalService,
    private researchService: ResearchService,
    private userService: UserService,
    private matchService: MatchService,
    private loadingService: LoadingService,
    private cookieService: CookieService,
    private modal: NgbModal,
    private gallery: Gallery,
    private _location: Location
  ) {
    this.loadingService.start();
    this.route.params
      .subscribe(params => {
        this.slug = params.slug;
        this.isCerca = this.slug.includes('cerca');
        this.uuid = params.uuid;
        this.match_uuid = params.match_uuid;
      });

    this.route.queryParams.subscribe((params) => {
      this.section = params['section'];
      this.token = params['t'];
    })
  }

  ngOnInit(): void {
    this.service = this.isCerca ? this.researchService : this.proposalService;

    if (this._location.path().startsWith('/inserimento-ricerca/scheda/') && this.match_uuid) {
      this.loadingService.start();
      this.isScheda = true;
      const slug = this.slug.replace('-cerca', '');

      this.userService.isViewableScheda(this.match_uuid, this.uuid, slug).subscribe((isViewable) => {
        if (isViewable) {
          this.instaciateForm();
          this.setViewsProposal();
          this.isCandidate();
          return;
        }
        this.loadingService.stop();
        this.router.navigateByUrl('/diventa-premium?limit=true');
      });
      return;
    }
    if (this._location.path().startsWith('/inserimento-proposta/scheda/') && this.match_uuid) {
      this.isScheda = true;
      this.setViewsSearch();
    }
    this.instaciateForm();
  }

  instaciateForm() {
    this.loadingService.start();
    this.service.read(this.uuid).subscribe((form) => {
      this.form = form;
      this.title = this.form.fields.find(f => f.field_name == 'proposal' || f.field_name == 'search');
      this.form.fields = this.form.fields.filter(f => f.value !== undefined);
      this.loadingService.stop();
    })
  }

  setViewsProposal() {
    this.proposalService.setViews(this.match_uuid, this.uuid).subscribe((doc) => {
      console.info(doc);
    }, (err) => {
      console.error(err);
    })
  }

  setViewsSearch() {
    this.researchService.setViews(this.match_uuid, this.uuid).subscribe((doc) => {
      console.info(doc);
    }, (err) => {
      console.error(err);
    })
  }

  isCandidate() {
    this.proposalService.isCandidate(this.match_uuid, this.uuid).subscribe((response: boolean) => {
      this.alreadyCandidate = response;
    }, (err) => {
      console.error(err);
    })
  }

  candidate() {
    this.loadingService.start();
    this.matchService.candidate(this.match_uuid, this.uuid).subscribe((doc) => {
      this.loadingService.stop();
      this.alreadyCandidate = true;
      const ref = this.modal.open(JobSendedComponent, { centered: true });
      this.alreadyCandidate = true;
      const wa = this.form.fields.find(f => f.field_name == "receive_material_by_wa");
      if (!wa || !wa.stringValue) {
        return;
      }
      ref.componentInstance.waNumber = wa.stringValue;
      ref.componentInstance.uuidproposal = this.uuid;
      ref.componentInstance.uuidresearch = this.match_uuid;
      ref.componentInstance.slug = this.slug;
    }, (err) => {
      this.loadingService.stop();
      console.error(err);
    })
  }

  basicFields() {
    let basicFields = this.form.fields.filter(f => ['text', 'number', 'dropdown', 'multi_dropdown', 'date'].includes(f.display_type));
    const privateFields = ['how_did_you_find_about_cinebooking', 'artistic_qualification', 'how_did_i_find_about_cinebooking'];
    basicFields = basicFields.filter(b => !privateFields.includes(b.field_name));
    return basicFields.filter(b => b.stringValue || b.value).filter(b => !b.private);
  }

  textAreaFields() {
    return this.form.fields.filter(f => ['textarea'].includes(f.display_type));
  }

  photosFields() {
    return this.form.fields.filter(f => ['file'].includes(f.display_type) && f.file_type == "1");
  }

  attachmentFields() {
    const attachmentFields = this.form.fields.filter(f => ['file'].includes(f.display_type) && f.file_type != "1");
    return attachmentFields.filter(a => a.value);
  }

  filename(address) {
    return address.split('/').pop();
  }

  publish() {
    this.loadingService.start();
    this.service.publish(this.uuid).subscribe(() => {
      this.matchService.count();
      this.router.navigateByUrl(`inserimento-proposta/status/${this.slug}/${this.uuid}`);
    }, (err) => {
      this.loadingService.stop();
      console.info(err);
    })
  }

  openGallery(index: number) {
    const photos = this.photosFields().pop();
    const images = photos.value.map((p) => { return { path: p } });
    const prop = {
      images: images,
      index
    };
    this.gallery.load(prop);
  }

  openShare() {
    const ref = this.modal.open(ShareSchedaComponent, { centered: true, backdrop: true });
    ref.componentInstance.onShare.subscribe((email: string) => {
      const fields = this.basicFields().map(f => { return { label: f.label, value: f.stringValue } });
      const params = { email: email, user_id: this.form.user_id, fields: fields };
      this.loadingService.start();
      this.proposalService.shareScheda(params).subscribe(() => {
        this.loadingService.stop();
        ref.close();
      }, (err) => {
        this.loadingService.stop();
        ref.close();
      })
    })
  }

  back() {
    if(this.token) {
      this._location.back();
      return;
    }
    if (!this.isCerca) {
      if (!this.match_uuid) {
        return this.goToProposal();
      }
      if (this.match_uuid) {
        return this.goToResearch();
      }
    }
    if (this.isCerca) {
      if (!this.match_uuid) {
        return this.goToResearch();
      }
      if (this.match_uuid) {
        return this.goToProposal();
      }
    }
  }

  goToProposal() {
    let uri = '/my-account/my-listings';
    if (this.section) {
      uri += `?section=${this.section}`
    }
    return this.router.navigateByUrl(uri);
  }

  goToProposalConCondidature() {
    let uri = '/my-account/my-listings';
    if (this.section) {
      uri += `?section=${this.section}`
    }
    if (this.match_uuid) {
      uri += uri.includes('?section') ? `&match_uuid=${this.match_uuid}` :  `?match_uuid=${this.match_uuid}`;
    }
    return this.router.navigateByUrl(uri);
  }

  goToResearch() {
    let uri = '/my-account/my-search';
    if (this.section) {
      uri += `?section=${this.section}`
    }
    return this.router.navigateByUrl(uri);
  }

  goToResearchConCondidature() {
    let uri = '/my-account/my-search';
    if (this.section) {
      uri += `?section=${this.section}`
    }
    if (this.match_uuid) {
      uri += uri.includes('?section') ? `&match_uuid=${this.match_uuid}` :  `?match_uuid=${this.match_uuid}`;
    }
    return this.router.navigateByUrl(uri);
  }

  color(pre = '', post = '') {
    return this.isCerca ? `${pre}cerca${post}` : `${pre}proponi${post}`;
  }

  deleteAttachment(field, value) {
    this.loadingService.start();
    const params = {
      uuid: this.uuid,
      field: field,
      value: value
    };

    this.service.deleteAttachemnt(params).subscribe(() => {
      const iFields = this.form.fields.findIndex(f => f.field_name == field.field_name);
      if (iFields === -1) {
        this.loadingService.stop();
        return;
      }
      const iValue = this.form.fields[iFields].value.findIndex(v => v == value);
      if (iValue === -1) {
        this.loadingService.stop();
        return;
      }
      this.form.fields[iFields].value.splice(iValue, 1);
      this.form.fields[iFields].stringValue.splice(iValue, 1);
      this.loadingService.stop();
    }, (err) => {
      this.loadingService.stop();
    })
  }

  isOwner() {
    const user_id = this.cookieService.get('user_id');
    if(!user_id) {
      return;
    }
    return this.form.user_id == user_id;
  }

  isLinkHttpUrl(string) {
    let url;
    
    try {
      url = new URL(string);
    } catch (_) {
      return false;  
    }
  
    return url.protocol === "http:" || url.protocol === "https:";
  }
  
}

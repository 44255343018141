import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CancellationSubscription } from 'src/core/cancellation-subscription.core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CanceledSubscriptionService {
  private resource = 'canceledSubscription/';
  constructor(
    private http: HttpClient
  ) { }

  insert(cancellation: CancellationSubscription) {
    return this.http.post(`${environment.api}${this.resource}insert`, cancellation);
  }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { CancellationSubscription } from 'src/core/cancellation-subscription.core';
import { LoadingService } from '../services/loading.service';
import { CookieService } from 'ngx-cookie-service';
import { Title } from '@angular/platform-browser';
import { CanceledSubscriptionService } from '../services/canceled-subscription.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-disdici-abbonamento',
  templateUrl: './disdici-abbonamento.component.html',
  styleUrls: ['./disdici-abbonamento.component.scss']
})
export class DisdiciAbbonamentoComponent implements OnInit {
  cancellation: CancellationSubscription = new CancellationSubscription();
  onSubmit: boolean;
  onSuccess: boolean;
  onFailed: boolean;
  error: string;

  @ViewChild('reason') reason;
  @ViewChild('message') message;

  constructor(
    private canceledSubscriptionService: CanceledSubscriptionService,
    private cookieService: CookieService,
    private loadingService: LoadingService,
    private router: Router,
    private title: Title
  ) {
    this.title.setTitle('Disdici abbonamento – Cinebooking');
  }

  ngOnInit(): void {
  }

  submit() {
    this.onSubmit = true;

    if(this.reason.invalid) {
      return;
    }
    if(this.reason.value == 'altro' && this.message.invalid) {
      return;
    }

    this.cancellation.user = this.cookieService.get('user_id');
    this.loadingService.start();
    this.canceledSubscriptionService.insert(this.cancellation).subscribe(() => {
      this.loadingService.stop();
      this.onSuccess = true;
      this.onSubmit = false;
      this.router.navigateByUrl('/my-account/edit-account');
    }, (error) => {
      console.error(error);
      this.onFailed = true;
      this.error = error.error;
      this.loadingService.stop();
    })
  }
}

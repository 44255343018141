import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-coming-soon',
  templateUrl: './coming-soon.component.html',
  styleUrls: ['./coming-soon.component.scss']
})
export class ComingSoonComponent implements OnInit {
  countryCode: string;
  nation;
  nations = [
    { name: "ALBANIA", code: "AL" },
    { name: "ARGENTINA", code: "AR" },
    { name: "AUSTRALIA", code: "AU" },
    { name: "AUSTRIA", code: "AT" },
    { name: "BELGIUM", code: "BE" },
    { name: "BRAZIL", code: "BR" },
    { name: "BULGARIA", code: "BG" },
    { name: "CANADA", code: "CA" },
    { name: "CHILE", code: "CL" },
    { name: "CHINA", code: "CN" },
    { name: "COLOMBIA", code: "CO" },
    { name: "CROATIA", code: "HR" },
    { name: "CUBA", code: "CU" },
    { name: "CYPRUS", code: "CY" },
    { name: "CZECH REPUBLIC", code: "CZ" },
    { name: "DENMARK", code: "DK" },
    { name: "EGYPT", code: "EG" },
    { name: "FINLAND", code: "FI" },
    { name: "FRANCE", code: "FR" },
    { name: "GERMANY", code: "DE" },
    { name: "GREECE", code: "GR" },
    { name: "HUNGARY", code: "HU" },
    { name: "INDIA", code: "IN" },
    { name: "IRELAND", code: "IE" },
    { name: "ISRAEL", code: "IL" },
    { name: 'ITALIA', code: 'IT' },
    { name: "JAPAN", code: "JP" },
    { name: "LITHUANIA", code: "LT" },
    { name: "MALTA", code: "MT" },
    { name: "MEXICO", code: "MX" },
    { name: "MOROCCO", code: "MA" },
    { name: "NETHERLANDS", code: "NL" },
    { name: "NORWAY", code: "NO" },
    { name: "PERÙ", code: "PE" },
    { name: "POLAND", code: "PL" },
    { name: "PORTUGAL", code: "PT" },
    { name: "ROMANIA", code: "RO" },
    { name: "RUSSIA", code: "RU" },
    { name: "SCOTLAND", code: "SCT" },
    { name: "SERBIA", code: "RS" },
    { name: "SLOVAKIA", code: "SK" },
    { name: "SOUTH AFRICA", code: "ZA" },
    { name: "SPAIN", code: "ES" },
    { name: "SWEDEN", code: "SE" },
    { name: "SWITZERLAND", code: "CH" },
    { name: "TUNISIA", code: "TN" },
    { name: "TURKEY", code: "TR" },
    { name: "UNITED ARAB EMIRATES", code: "AE" },
    { name: "UNITED KINGDOM", code: "GB" },
    { name: "UNITED STATES", code: "US" },
  ];

  constructor(
    private title: Title,
    private route: ActivatedRoute
  ) {
    this.title.setTitle('Coming soon – Cinebooking');
    this.route.queryParams.subscribe((params) => {
      if(!params || !params['cc']) {
        return;
      }
      this.countryCode = params['cc'];
      this.nation = this.nations.find(n => n.code == this.countryCode);
    });
  }

  ngOnInit(): void {
  }

}

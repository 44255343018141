import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-inserimento-proposta-modal',
  templateUrl: './inserimento-proposta-modal.component.html',
  styleUrls: ['./inserimento-proposta-modal.component.scss']
})
export class InserimentoPropostaModalComponent implements OnInit {
  @Input() slug;
  qui_proponi: string;
  prefix: string = 'Qui PROPONI';
  suffix: string = 'Verifica le altre sezioni per figure artistiche e professionali';

  constructor(
    private activeModal: NgbActiveModal,
    private router: Router
  ) { }

  ngOnInit(): void {
    switch (this.slug) {
      case 'figura-artistica': {
        this.prefix = 'Qui sei un';
        this.qui_proponi = 'Attore, Figurante, Ballerina, Cantante, Modella, Hostess, altro';
        this.suffix = 'Verifica le altre sezioni per qualifiche in figure professionali e tecniche della Crew';
        break;
      }
      case 'figura-professionale': {
        this.prefix = 'Qui sei un';
        this.qui_proponi = ' Filmmaker, Sceneggiatore, Truccatrice o personale della CREW tecnica';
        this.suffix = 'Verifica le altre sezioni per figure artistiche per attori, figuranti, ballerini e altro, vai in Cast Artistico';
        break;
      }
      case 'noleggio-equipments': {
        this.qui_proponi = 'attrezzature e forniture per il SET';
        break;
      }
      case 'noleggio-locations': {
        this.qui_proponi = 'appartamenti, negozi, uffici';
        break;
      }
      case 'noleggio-arredo-costumi': {
        this.qui_proponi = 'arredi e costumi';
        break;
      }
      case 'noleggio-trasporti': {
        this.qui_proponi = 'trasporti a noleggio da azienda';
        break;
      }
      case 'noleggio-auto-moto-nautica': {
        this.qui_proponi = 'autoveicoli, moto, natanti';
        break;
      }
      case 'noleggio-animali': {
        this.qui_proponi = 'animali';
        break;
      }
      case 'film-services-italia': {
        this.qui_proponi = 'agenzia di permessi occupazione suolo pubblico (osap)';
        break;
      }
      case 'space-stage-studios': {
        this.qui_proponi = 'teatri, sale posa, green/screen, uffici, attrezzerie, sartorie';
        break;
      }
    }
  }

  dismiss() {
    this.activeModal.dismiss();
  }

  goToProposte() {
    this.dismiss();
    this.router.navigateByUrl(`/inserimento-proposta`);
  }

}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StripeService {
  private resource = 'stripe/';
  constructor(
    private http: HttpClient
  ) { }

  getSubscriptionInformation(user_id: string) {
    return this.http.post(`${environment.api}${this.resource}getSubscriptionInformation`, { user_id: user_id });
  }
}

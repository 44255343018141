import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { CountMatch } from 'src/core/count-match.core';
import { Utils } from 'src/utils/utils';
import { MatchService } from '../services/match.service';
import { UserService } from '../services/user.service';
import { SingInPopupComponent } from '../sing-in-popup/sing-in-popup.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  autentichate: boolean;
  user_id: string;
  user: string;
  user_profile: string;
  countMatchs: CountMatch = new CountMatch();

  menu: boolean;
  dropdownmenu: boolean;
  subMenu: boolean;
  chooseNation: boolean;

  countryCode: string;
  nation = { name: 'ITALIA', code: 'IT' };
  nations = [
    { name: "ALBANIA", code: "AL" },
    { name: "ARGENTINA", code: "AR" },
    { name: "AUSTRALIA", code: "AU" },
    { name: "AUSTRIA", code: "AT" },
    { name: "BELGIUM", code: "BE" },
    { name: "BRAZIL", code: "BR" },
    { name: "BULGARIA", code: "BG" },
    { name: "CANADA", code: "CA" },
    { name: "CHILE", code: "CL" },
    { name: "CHINA", code: "CN" },
    { name: "COLOMBIA", code: "CO" },
    { name: "CROATIA", code: "HR" },
    { name: "CUBA", code: "CU" },
    { name: "CYPRUS", code: "CY" },
    { name: "CZECH REPUBLIC", code: "CZ" },
    { name: "DENMARK", code: "DK" },
    { name: "EGYPT", code: "EG" },
    { name: "FINLAND", code: "FI" },
    { name: "FRANCE", code: "FR" },
    { name: "GERMANY", code: "DE" },
    { name: "GREECE", code: "GR" },
    { name: "HUNGARY", code: "HU" },
    { name: "INDIA", code: "IN" },
    { name: "IRELAND", code: "IE" },
    { name: "ISRAEL", code: "IL" },
    { name: 'ITALIA', code: 'IT' },
    { name: "JAPAN", code: "JP" },
    { name: "LITHUANIA", code: "LT" },
    { name: "MALTA", code: "MT" },
    { name: "MEXICO", code: "MX" },
    { name: "MOROCCO", code: "MA" },
    { name: "NETHERLANDS", code: "NL" },
    { name: "NORWAY", code: "NO" },
    { name: "PERÙ", code: "PE" },
    { name: "POLAND", code: "PL" },
    { name: "PORTUGAL", code: "PT" },
    { name: "ROMANIA", code: "RO" },
    { name: "RUSSIA", code: "RU" },
    { name: "SCOTLAND", code: "SCT" },
    { name: "SERBIA", code: "RS" },
    { name: "SLOVAKIA", code: "SK" },
    { name: "SOUTH AFRICA", code: "ZA" },
    { name: "SPAIN", code: "ES" },
    { name: "SWEDEN", code: "SE" },
    { name: "SWITZERLAND", code: "CH" },
    { name: "TUNISIA", code: "TN" },
    { name: "TURKEY", code: "TR" },
    { name: "UNITED ARAB EMIRATES", code: "AE" },
    { name: "UNITED KINGDOM", code: "GB" },
    { name: "UNITED STATES", code: "US" },
  ];

  constructor(
    private userService: UserService,
    private matchService: MatchService,
    private cookieService: CookieService,
    private modal: NgbModal,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.route.queryParams.subscribe((params) => {
      if(!params || !params['cc']) {
        return;
      }
      this.countryCode = params['cc'];
      this.nation = this.nations.find(n => n.code == this.countryCode);
    });

    router.events.forEach((event) => {
      if(event instanceof NavigationStart) {
        this.nation = { name: 'ITALIA', code: 'IT' };
      }
    });
  }

  ngOnInit(): void {
    this.userService.onAutentichate.subscribe(() => {
      this.verifyPermission();
    });
    this.userService.onUpdateUser.subscribe(() => {
      this.setUser();
    });
    this.matchService.countMatchs.subscribe((count: CountMatch) => {
      if (!count) {
        return;
      }
      this.countMatchs = count;
    });
    this.verifyPermission();
  }

  verifyPermission() {
    this.userService.isAuthenticated().subscribe((autentichate: boolean) => {
      this.autentichate = autentichate;
      if (!this.autentichate) {
        return;
      }
      this.setUser();
      this.matchService.count();
    })
  }

  setUser() {
    this.user_id = this.cookieService.get('user_id');
    this.user = this.cookieService.get('user').split('@').shift();
    this.user_profile = this.cookieService.get('user_profile');
    this.user_profile = this.user_profile === 'undefined' ? undefined : this.user_profile;
  }

  navigate(route: string) {
    this.dropdownmenu = false;
    this.chooseNation = false;
    this.router.navigateByUrl(route);
  }

  downloadApp() {
    if (Utils.getMobileOperatingSystem() == 'iOS') {
      window.open('https://apps.apple.com/it/app/apple-store/id1451629503', '_blank');
      return;
    }
    window.open('https://play.google.com/store/apps/details?id=com.cinebooking.cinebooking', '_blank');
  }

  openSingin() {
    const singinPopup = this.modal.open(SingInPopupComponent, { centered: true });
  }

  toogleChooseNation() {
    this.chooseNation = !this.chooseNation;
    if (this.chooseNation) {
      this.dropdownmenu = false;
      this.menu = false;
    }
  }

  toogleDropdownMenu() {
    this.dropdownmenu = !this.dropdownmenu;
    if (this.dropdownmenu) {
      this.chooseNation = false;
      this.menu = false;
    }
  }

  toogleMenu() {
    this.menu = !this.menu;
    if (this.menu) {
      this.chooseNation = false;
      this.dropdownmenu = false;
    }
  }

  openSubMenu() {
    this.subMenu = true;
  }

  closeSubMenu() {
    this.subMenu = false;
  }

  changeNation(code: string) {
    if (code == 'IT') {
      this.chooseNation = false;
      this.resetNation();
      return;
    }
    this.chooseNation = false;
    this.router.navigateByUrl(`/coming-soon?cc=${code}`);
  }

  resetNation() {
    if(this.nation.code === 'IT') {
      return;
    }
    this.nation = { name: 'ITALIA', code: 'IT' };
    this.router.navigateByUrl(`/`);
  }
}

<div style="padding: 0;width: 100%;" *ngIf="user">
    <div class="form-design edit-account-form">
        <div class="account-form-box" style="border:0!important;box-shadow: none!important;">
            <form role="form" class="cine-booking-form" method="POST">
                <div class="form-group col-xs-12 col-sm-12 col-md-12" ><legend class="text-uppercase" style="margin-top:0">ABBONAMENTO</legend></div>

                <hr />
                <div *ngIf="!hasSubcription()">
                    <h5 style="font-size: 15px;margin-bottom: 0;">Nessun abbonamento attivo.</h5>
                    <p style="font-size:15px">Abbonati per visualizzare tutte le richieste di lavoro.</p>
                
                    <div class="form-row">
                        <div class="form-group col mb-10px">
                            <div class="signup-btn">
                                <input (click)="diventaPremium()" type="submit" id="updateBtn" value="DIVENTA PREMIUM"
                                    class="btn btn-primary btn-block hover-green-btn pink-btn w-100 text-uppercase font-medium">
                            </div>
                        </div>
                    </div>
                </div>
                <div  *ngIf="hasSubcription()">
                    <div *ngIf="!subscriptionCanceled()">
                        <h5 style="font-size: 15px;margin-bottom: 0;">Abbonamento attivo.</h5>
                        <p style="font-size:15px;margin-bottom: 0;">Tipologia abbonamento:
                            <span *ngIf="user.subscription.type == 'month'">PREMIUM Mensile</span>
                            <span *ngIf="user.subscription.type == 'year'">PREMIUM Annuale</span>
                        </p>
                        <p style="font-size:15px;margin-bottom: 0;">Rinnovo previsto il: {{user.subscription.end | date: 'dd/MM/yyyy'}}</p>
                        
                        <div class="payment-method" *ngIf="paymentMethod">
                            <img src="/assets/img/credit-card.png" width="28"/>
                            <div *ngIf="paymentMethod.card" style="margin-left: 1rem;">
                                <div class="text">Le ultime cifre sono <span style="text-transform: uppercase">{{paymentMethod.card.last4}}</span></div>
                                <div class="brand"><span style="text-transform: uppercase">{{paymentMethod.card.brand}}</span></div>
                            </div>
                        </div>
                        
                        <div class="form-row">
                            <div class="form-group col mb-10px">
                                <div class="signup-btn">
                                    <input (click)="cancelSubscription()" type="submit" id="updateBtn" value="Disdici abbonamento"
                                        class="btn btn-primary btn-block hover-green-btn pink-btn w-100 text-uppercase font-medium">
                                </div>
                            </div>
                        </div>

                        <div *ngIf="subscriptionItems && subscriptionItems.length !== 0" style="margin-top: 1rem;">
                            <h5 style="font-size: 15px;margin-bottom: .5rem;">Lista pagamenti</h5>

                            <table class="table" style="border:1px solid #ddd">
                                <thead>
                                    <tr>
                                        <td>Tipologia abbonamento</td>
                                        <td>Prezzo</td>
                                        <td>Data</td>
                                        <td></td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of subscriptionItems">
                                        <td>
                                            <span *ngIf="item.type == 'year'">Annuale</span>
                                            <span *ngIf="item.type == 'month'">Mensile</span>
                                        </td>
                                        <td>{{parsePrice(item.price)}} €</td>
                                        <td>{{item.created | date: 'dd/MM/yyyy'}}</td>
                                        <td>
                                            <button *ngIf="!item.invoiceRequested" (click)="requestInvoice(item)" type="button" style="background-color: #db2557!important;margin-bottom: 0;margin-top: -4px;"
                                            class="btn btn-primary btn-sm btn-block bg-color-green btn-flat" data-toggle="modal" >Richiedi fattura</button>
                                            <button *ngIf="item.invoiceRequested" type="button" style="background-color: #db2557!important;margin-bottom: 0;margin-top: -4px;"
                                            class="btn btn-primary btn-sm btn-block bg-color-green btn-flat" data-toggle="modal" >Fattura già richiesta</button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div *ngIf="subscriptionCanceled()">
                        <h5 style="font-size: 15px;margin-bottom: 0;">Abbonamento disattivato.</h5>
                        <p style="font-size:15px;margin-bottom: 0;">Data scadenza abbonamento: {{user.subscription.end | date: 'dd/MM/yyyy'}}</p>
                        <div class="form-row">
                            <div class="form-group col mb-10px">
                                <div class="signup-btn">
                                    <input (click)="reactivateSubscription()" type="submit" id="updateBtn" value="Riattiva abbonamento"
                                        class="btn btn-primary btn-block hover-green-btn pink-btn w-100 text-uppercase font-medium">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
<div class="success" *ngIf="onSuccess">
    <div class="container">
        <div class="row">
            <div class="alert alert-success col-md-7 col-md-push-2 col-sm-8 col-sm-push-2 col-xs-8 col-xs-push-4   alert-custom"
                role="alert">
                <svg class="svg-inline--fa fa-check-circle fa-w-16" style="color: #3bbdb6;" aria-hidden="true"
                    focusable="false" data-prefix="fa" data-icon="check-circle" role="img"
                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
                    <path fill="currentColor"
                        d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z">
                    </path>
                </svg><!-- <i class="fa fa-check-circle" style="color: #3bbdb6;"></i> -->
                <span class="ml-2">Ti è stata inviata un email per procedere con il reset della password</span>

                <button (click)="onSuccess = false" type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
        </div>
    </div>
</div>
<div class="container">
    <section class="page-header-padding bg-white account-page">
        <div class="row">
            <div class="col-md-12 col-lg-10 offset-lg-1">
                <div class="form-design">
                    <div class="card-header">Reset Password</div>
                    <div class="account-form-box lost-password">
                        <form role="form" class="cine-booking-form" id="lost-password" method="POST">
                            <div class="account-form-title color-pink">
                                <span class="rounded-circle"><svg class="svg-inline--fa fa-lock fa-w-14 color-white"
                                        aria-hidden="true" focusable="false" data-prefix="fas" data-icon="lock"
                                        role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"
                                        data-fa-i2svg="">
                                        <path fill="currentColor"
                                            d="M400 224h-24v-72C376 68.2 307.8 0 224 0S72 68.2 72 152v72H48c-26.5 0-48 21.5-48 48v192c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V272c0-26.5-21.5-48-48-48zm-104 0H152v-72c0-39.7 32.3-72 72-72s72 32.3 72 72v72z">
                                        </path>
                                    </svg><!-- <i class="fas fa-lock color-white"></i> --></span>
                                <h5>Password dimenticata?</h5>
                            </div>
                            <p> Hai perso la password? <br>INSERISCI QUI DI SEGUITO:</p>
                            <div class="form-row">
                                <div class="form-group col-xs-12 col-sm-12 col-md-12">
                                    <label>Indirizzo email </label>
                                    <input [(ngModel)]="user_email" type="email" name="email" id="email"
                                    class="form-control input-sm" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$"
                                    [required]="true" [ngModelOptions]="{standalone: true}" #email="ngModel">
                                </div>
                                <div *ngIf="onSubmit && email.invalid ">
                                    <small *ngIf="email.errors?.required" style="color: red;">Il campo non può essere
                                        vuoto.</small>
                                    <small *ngIf="email.errors?.pattern" style="color: red;">L'email non risulta
                                        valida.</small>
                                </div>
                            </div>

                            <div class="alert alert-danger alert-block" *ngIf="onFailed" style="top: 0!important;">
                                <button type="button" class="colse" data-dismiss="alert" (click)="onFailed = false">X</button>
                                <strong>{{error}}</strong>
                            </div>

                            <div class="form-row">
                                <div class="form-group col mb-10px">
                                    <div class="signup-btn">
                                        <input (click)="submit()" type="submit" value="Procedi" id="registerBtn"
                                            class="btn btn-primary btn-block hover-green-btn pink-btn w-100 text-uppercase font-medium">
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>


        </div>
    </section>
</div>
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Singin, Singup } from 'src/core/user.core';
import { UserService } from '../services/user.service';
import { CookieService } from 'ngx-cookie-service';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingService } from '../services/loading.service';
import { Title } from '@angular/platform-browser';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-sing-in-popup',
  templateUrl: './sing-in-popup.component.html',
  styleUrls: ['./sing-in-popup.component.scss']
})
export class SingInPopupComponent implements OnInit {
  singupForm = new Singup();
  singinForm = new Singin();

  onSingInError: boolean;
  onSingUpError: boolean;

  singInError: string;
  singUpError: string;
  @Input() redirect: boolean = true;

  @ViewChild('singin_mail') singin_mail;
  @ViewChild('singin_password') singin_password;

  @ViewChild('singup_mail') singup_mail;
  @ViewChild('singup_password') singup_password;


  constructor(
    private userService: UserService,
    private cookieService: CookieService,
    private loadingService: LoadingService,
    private router: Router,
    private route: ActivatedRoute,
    private title: Title,
    private activeModal: NgbActiveModal,
  ) {
    this.title.setTitle('My account – Cinebooking');
  }

  ngOnInit(): void {
  }

  singup() {
    console.info(this.singupForm);
    this.singupForm.onSubmit = true;

    if (this.singup_mail.invalid || this.singup_password.invalid) {
      return;
    }
    if (!this.singupForm.consentTermsConditionsUse) {
      return;
    }

    this.loadingService.start();
    this.userService.singup(this.singupForm).subscribe((user: any) => {
      this.cookieService.set('user', user.email, undefined, undefined, undefined, true, "None");
      this.cookieService.set('user_id', user.id, undefined, undefined, undefined, true, "None");
      this.cookieService.set('token', user.token, undefined, undefined, undefined, true, "None");
      this.loadingService.stop();

      this.userService.onAutentichate.emit();
      this.router.navigateByUrl('/dashboard');
    }, (error) => {
      this.loadingService.stop();
      this.onSingUpError = true;
      this.singUpError = error.error;
      console.info(error);
    })
  }

  singin() {
    this.singinForm.onSubmit = true;

    if (this.singin_mail.invalid || this.singin_password.invalid) {
      return;
    }
   
    this.loadingService.start();
    this.userService.singin(this.singinForm).subscribe((user: any) => {
      this.activeModal.close();
      this.cookieService.set('user_id', user.id, undefined, undefined, undefined, true, "None");
      this.cookieService.set('token', user.token, undefined, undefined, undefined, true, "None");
      this.cookieService.set('user_profile', user.profile, undefined, undefined, undefined, true, "None");
      this.loadingService.stop();
      this.userService.onAutentichate.emit();

      if(this.redirect) {
        this.router.navigateByUrl('/dashboard');
      }
    }, (error) => {
      this.loadingService.stop();
      this.onSingInError = true;
      this.singInError = error.error;
      console.info(error);
    })
  }

  close() {
    this.activeModal.close();
  }


}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CMSPages } from 'src/static/cms-pages';

@Component({
  selector: 'app-status-forms',
  templateUrl: './status-forms.component.html',
  styleUrls: ['./status-forms.component.scss']
})
export class StatusFormsComponent implements OnInit {
  slug: string;
  uuid: string;
  page_title: string;
  nice_name: string;
  isCerca: boolean;

  address: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.route.params
      .subscribe(params => {
        this.slug = params.slug;
        this.uuid = params.uuid;
        this.isCerca = this.slug.includes('cerca');
      });
  }

  ngOnInit(): void {
    const cmsPage = CMSPages.page(this.slug);
    this.address = `/inserimento-proposta/listing/${this.slug}/${this.uuid}`;
    this.nice_name = cmsPage.nice_name;
    this.page_title = cmsPage.page_title;
  }

}

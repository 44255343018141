import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { Share } from 'src/core/share.core';

@Component({
  selector: 'app-share-scheda',
  templateUrl: './share-scheda.component.html',
  styleUrls: ['./share-scheda.component.scss']
})
export class ShareSchedaComponent implements OnInit {
  @ViewChild('emailInput') emailInput;
  email: string;
  onSubmit: boolean;
  onError: boolean;
  error: string;

  @Output() onShare = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  share() {
    this.onSubmit = true;
    if (this.emailInput.invalid) {
      return;
    }
    this.onShare.emit(this.email);
  }

}


import { Component, OnInit } from '@angular/core';
import { CountMatch } from 'src/core/count-match.core';
import { LoadingService } from '../services/loading.service';
import { UserService } from '../services/user.service';
import { User } from 'src/core/user.core';
import { MatchService } from '../services/match.service';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { StripeService } from '../services/stripe.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RichiediFatturaModalComponent } from '../richiedi-fattura-modal/richiedi-fattura-modal.component';

@Component({
  selector: 'app-subcription',
  templateUrl: './subcription.component.html',
  styleUrls: ['./subcription.component.scss']
})
export class SubcriptionComponent implements OnInit {
  countMatchs: CountMatch = new CountMatch();
  user: User;
  paymentMethod;
  subscriptionItems;

  constructor(
    private loadingService: LoadingService,
    private matchService: MatchService,
    private userService: UserService,
    private stripeService: StripeService,
    private router: Router,
    private modal: NgbModal,
  ) { }

  ngOnInit(): void {
    this.loadingService.start();
    this.matchService.count();
    this.userService.getUser().subscribe((user: User) => {
      this.user = user;
      if (!this.hasSubcription()) {
        this.loadingService.stop();
        return;
      }
      this.getSubscriptionInformation();
    });
    this.matchService.countMatchs.subscribe((count: CountMatch) => {
      if (!count) {
        return;
      }
      this.countMatchs = count;
    });
  }

  getSubscriptionInformation() {
    this.stripeService.getSubscriptionInformation(this.userService.getUserId()).subscribe((subInformation: any) => {
      this.paymentMethod = subInformation.paymentMethod;
      this.subscriptionItems = subInformation.subscriptionItems;
      if (this.subscriptionItems && this.subscriptionItems.length > 0) {
        this.subscriptionItems = this.subscriptionItems.filter(i => i.type == 'year');
      }
      this.loadingService.stop();
    }, (err) => {
      this.loadingService.stop();
    })
  }

  hasSubcription() {
    if (!this.user.subscription || !this.user.subscription._id) {
      return false;
    }
    return true;
  }

  subscriptionExpires() {
    return new Date(this.user.subscription.end) < new Date();
  }

  subscriptionCanceled() {
    return this.user.subscription.status == 'canceled';
  }

  diventaPremium() {
    this.router.navigateByUrl('/diventa-premium');
  }

  reactivateSubscription() {
    if (!this.subscriptionExpires()) {
      alert(`Sara possibile riattivare l'abbonamento dopo la data di scandenza`);
      return;
    }
    this.router.navigateByUrl('/diventa-premium');
  }

  cancelSubscription() {
    this.router.navigateByUrl('/disdici-abbonamento');
  }

  parsePrice(price: number) {
    return price.toFixed(2).replace('.', ',');
  }

  requestInvoice(subscriptionItem) {
    const ref = this.modal.open(RichiediFatturaModalComponent);
    ref.componentInstance.subscriptionItem = subscriptionItem;
    ref.componentInstance.onRequestInvoice.subscribe(() => {
      subscriptionItem.invoiceRequested = true;
    })
  }

}

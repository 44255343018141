<div class="container">
    <header class="masthead">
        <div class="row h-100 d-flex align-items-end justify-content-center text-center">
            <div class="overlay-black-bg banner-content animated-content">
                <div class="col-lg-12">

                    <h1 class="d-md-none d-lg-block d-none d-lg-block d-xl-none d-none d-xl-block">
                        La soluzione per il lavoro artistico in un click<br>
                    </h1>
                    <h1 class="d-lg-none d-xl-block d-none d-xl-block"><span class="color-pink">Semplice Veloce
                            Concreto</span></h1>

                    <div class="home-slider-btn">
                        <a class="btn btn-primary btn-green" routerLink="/inserimento-ricerca">
                            CERCA
                            <svg class="svg-inline--fa fa-angle-right fa-w-8" aria-hidden="true" focusable="false"
                                data-prefix="fas" data-icon="angle-right" role="img" xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 256 512" data-fa-i2svg="">
                                <path fill="currentColor"
                                    d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z">
                                </path>
                            </svg><!-- <i class="fas fa-angle-right"></i> -->
                        </a>
                        <a class="btn btn-primary btn-nblue" routerLink="/inserimento-proposta">
                            PROPONI
                            <svg class="svg-inline--fa fa-angle-right fa-w-8" aria-hidden="true" focusable="false"
                                data-prefix="fas" data-icon="angle-right" role="img" xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 256 512" data-fa-i2svg="">
                                <path fill="currentColor"
                                    d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z">
                                </path>
                            </svg><!-- <i class="fas fa-angle-right"></i> -->
                        </a>
                    </div>
                    <h4 class="sub-title mb-0 d-lg-none d-xl-block">JOB CREW CAST· RENTAL· EQUIPMENTS· STAGE STUDIOS·
                        LOCATIONS</h4>
                </div>
            </div>
        </div>
    </header>
</div>
<div class="container">
    <section class="page-section services-section bg-white" id="services">
        <div class="row">
            <div class="col-lg-3 col-md-3 text-center">
                <div class="services-icon">
                    <div class="elementor-icon-box">
                        <span class="elementor-icon">
                            <svg class="svg-inline--fa fa-film fa-w-16" aria-hidden="true" focusable="false"
                                data-prefix="fas" data-icon="film" role="img" xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 512 512" data-fa-i2svg="">
                                <path fill="currentColor"
                                    d="M488 64h-8v20c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12V64H96v20c0 6.6-5.4 12-12 12H44c-6.6 0-12-5.4-12-12V64h-8C10.7 64 0 74.7 0 88v336c0 13.3 10.7 24 24 24h8v-20c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v20h320v-20c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v20h8c13.3 0 24-10.7 24-24V88c0-13.3-10.7-24-24-24zM96 372c0 6.6-5.4 12-12 12H44c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40zm0-96c0 6.6-5.4 12-12 12H44c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40zm0-96c0 6.6-5.4 12-12 12H44c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40zm272 208c0 6.6-5.4 12-12 12H156c-6.6 0-12-5.4-12-12v-96c0-6.6 5.4-12 12-12h200c6.6 0 12 5.4 12 12v96zm0-168c0 6.6-5.4 12-12 12H156c-6.6 0-12-5.4-12-12v-96c0-6.6 5.4-12 12-12h200c6.6 0 12 5.4 12 12v96zm112 152c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40zm0-96c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40zm0-96c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40z">
                                </path>
                            </svg><!-- <i class="fas fa-film" aria-hidden="true"></i> -->
                        </span>
                    </div>
                    <h3 class="h4 mb-2">CINEMA</h3>
                </div>
            </div>
            <div class="col-lg-3 col-md-3 text-center">
                <div class="services-icon">
                    <div class="elementor-icon-box">
                        <span class="elementor-icon">
                            <svg class="svg-inline--fa fa-video fa-w-18" aria-hidden="true" focusable="false"
                                data-prefix="fas" data-icon="video" role="img" xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 576 512" data-fa-i2svg="">
                                <path fill="currentColor"
                                    d="M336.2 64H47.8C21.4 64 0 85.4 0 111.8v288.4C0 426.6 21.4 448 47.8 448h288.4c26.4 0 47.8-21.4 47.8-47.8V111.8c0-26.4-21.4-47.8-47.8-47.8zm189.4 37.7L416 177.3v157.4l109.6 75.5c21.2 14.6 50.4-.3 50.4-25.8V127.5c0-25.4-29.1-40.4-50.4-25.8z">
                                </path>
                            </svg><!-- <i class="fas fa-video"></i> -->
                        </span>
                    </div>
                    <h3 class="h4 mb-2">Televisione</h3>
                </div>

            </div>
            <div class="col-lg-3 col-md-3 text-center">
                <div class="services-icon">
                    <div class="elementor-icon-box">
                        <span class="elementor-icon">
                            <svg class="svg-inline--fa fa-bullhorn fa-w-18" aria-hidden="true" focusable="false"
                                data-prefix="fa" data-icon="bullhorn" role="img" xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 576 512" data-fa-i2svg="">
                                <path fill="currentColor"
                                    d="M576 240c0-23.63-12.95-44.04-32-55.12V32.01C544 23.26 537.02 0 512 0c-7.12 0-14.19 2.38-19.98 7.02l-85.03 68.03C364.28 109.19 310.66 128 256 128H64c-35.35 0-64 28.65-64 64v96c0 35.35 28.65 64 64 64h33.7c-1.39 10.48-2.18 21.14-2.18 32 0 39.77 9.26 77.35 25.56 110.94 5.19 10.69 16.52 17.06 28.4 17.06h74.28c26.05 0 41.69-29.84 25.9-50.56-16.4-21.52-26.15-48.36-26.15-77.44 0-11.11 1.62-21.79 4.41-32H256c54.66 0 108.28 18.81 150.98 52.95l85.03 68.03a32.023 32.023 0 0 0 19.98 7.02c24.92 0 32-22.78 32-32V295.13C563.05 284.04 576 263.63 576 240zm-96 141.42l-33.05-26.44C392.95 311.78 325.12 288 256 288v-96c69.12 0 136.95-23.78 190.95-66.98L480 98.58v282.84z">
                                </path>
                            </svg><!-- <i class="fa fa-bullhorn" aria-hidden="true"></i> -->
                        </span>
                    </div>
                    <h3 class="h4 mb-2">Pubblicità</h3>
                </div>


            </div>
            <div class="col-lg-3 col-md-3 text-center">
                <div class="services-icon">
                    <div class="elementor-icon-box">
                        <span class="elementor-icon">
                            <svg class="svg-inline--fa fa-cocktail fa-w-18" aria-hidden="true" focusable="false"
                                data-prefix="fas" data-icon="cocktail" role="img" xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 576 512" data-fa-i2svg="">
                                <path fill="currentColor"
                                    d="M296 464h-56V338.78l168.74-168.73c15.52-15.52 4.53-42.05-17.42-42.05H24.68c-21.95 0-32.94 26.53-17.42 42.05L176 338.78V464h-56c-22.09 0-40 17.91-40 40 0 4.42 3.58 8 8 8h240c4.42 0 8-3.58 8-8 0-22.09-17.91-40-40-40zM432 0c-62.61 0-115.35 40.2-135.18 96h52.54c16.65-28.55 47.27-48 82.64-48 52.93 0 96 43.06 96 96s-43.07 96-96 96c-14.04 0-27.29-3.2-39.32-8.64l-35.26 35.26C379.23 279.92 404.59 288 432 288c79.53 0 144-64.47 144-144S511.53 0 432 0z">
                                </path>
                            </svg><!-- <i class="fas fa-cocktail"></i> -->
                        </span>
                    </div>
                    <h3 class="h4 mb-2">Eventi</h3>
                </div>

            </div>
        </div>

        <div class="row">
            <div class="col-lg-3 col-md-3 text-center">
                <div class="services-icon">
                    <div class="elementor-icon-box">
                        <span class="elementor-icon">
                            <svg class="svg-inline--fa fa-microphone fa-w-11" aria-hidden="true" focusable="false"
                                data-prefix="fas" data-icon="microphone" role="img" xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 352 512" data-fa-i2svg="">
                                <path fill="currentColor"
                                    d="M176 352c53.02 0 96-42.98 96-96V96c0-53.02-42.98-96-96-96S80 42.98 80 96v160c0 53.02 42.98 96 96 96zm160-160h-16c-8.84 0-16 7.16-16 16v48c0 74.8-64.49 134.82-140.79 127.38C96.71 376.89 48 317.11 48 250.3V208c0-8.84-7.16-16-16-16H16c-8.84 0-16 7.16-16 16v40.16c0 89.64 63.97 169.55 152 181.69V464H96c-8.84 0-16 7.16-16 16v16c0 8.84 7.16 16 16 16h160c8.84 0 16-7.16 16-16v-16c0-8.84-7.16-16-16-16h-56v-33.77C285.71 418.47 352 344.9 352 256v-48c0-8.84-7.16-16-16-16z">
                                </path>
                            </svg><!-- <i class="fas fa-microphone"></i> -->
                        </span>
                    </div>
                    <h3 class="h4 mb-2">Radio</h3>
                </div>
            </div>
            <div class="col-lg-3 col-md-3 text-center">
                <div class="services-icon">
                    <div class="elementor-icon-box">
                        <span class="elementor-icon p-20px">
                            <i class="fa-moda"></i>
                        </span>
                    </div>
                    <h3 class="h4 mb-2">Moda</h3>
                </div>

            </div>
            <div class="col-lg-3 col-md-3 text-center">
                <div class="services-icon">
                    <div class="elementor-icon-box">
                        <span class="elementor-icon">
                            <svg class="svg-inline--fa fa-music fa-w-16" aria-hidden="true" focusable="false"
                                data-prefix="fas" data-icon="music" role="img" xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 512 512" data-fa-i2svg="">
                                <path fill="currentColor"
                                    d="M470.38 1.51L150.41 96A32 32 0 0 0 128 126.51v261.41A139 139 0 0 0 96 384c-53 0-96 28.66-96 64s43 64 96 64 96-28.66 96-64V214.32l256-75v184.61a138.4 138.4 0 0 0-32-3.93c-53 0-96 28.66-96 64s43 64 96 64 96-28.65 96-64V32a32 32 0 0 0-41.62-30.49z">
                                </path>
                            </svg><!-- <i class="fas fa-music"></i> -->
                        </span>
                    </div>
                    <h3 class="h4 mb-2">Musica</h3>
                </div>


            </div>
            <div class="col-lg-3 col-md-3 text-center">
                <div class="services-icon">
                    <div class="elementor-icon-box">
                        <span class="elementor-icon p-20px">
                            <i class="fa-teatro"></i>
                        </span>
                    </div>
                    <h3 class="h4 mb-2">Teatro</h3>
                </div>

            </div>
        </div>
    </section>
</div>
import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CountMatch } from 'src/core/count-match.core';
import { environment } from 'src/environments/environment';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class MatchService {
  private resource = 'matchs/';
  countMatchs = new BehaviorSubject<CountMatch>(undefined);


  constructor(
    private http: HttpClient,
    private userService: UserService,
  ) { }

  count() {
    const user_id = this.userService.getUserId();
    if (!user_id) {
      return;
    }
    this.http.get(`${environment.api}${this.resource}count/${user_id}`).subscribe((count: CountMatch) => {
      this.countMatchs.next(count);
    })
  }

  candidate(uuid: string, match_uuid: string) {
    return this.http.post(`${environment.api}${this.resource}candidate`, { uuid, match_uuid });
  }
}

import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-domande-frequenti',
  templateUrl: './domande-frequenti.component.html',
  styleUrls: ['./domande-frequenti.component.scss']
})
export class DomandeFrequentiComponent implements OnInit {

  constructor(
    private title: Title
  ) { 
    this.title.setTitle('Domande frequenti – Cinebooking');
  }

  ngOnInit(): void {
  }

}

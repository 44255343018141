import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { LoadingService } from '../services/loading.service';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-lost-password',
  templateUrl: './lost-password.component.html',
  styleUrls: ['./lost-password.component.scss']
})
export class LostPasswordComponent implements OnInit {
  user_email: string;
  @ViewChild('email') email;

  onSubmit: boolean;
  onSuccess: boolean;
  onFailed: boolean;
  error: string;

  constructor(
    private title: Title,
    private loadingService: LoadingService,
    private userService: UserService
  ) {
    this.title.setTitle('My account – Cinebooking');
  }

  ngOnInit(): void {
  }

  submit() {
    this.onSubmit = true;

    if (this.email.invalid) {
      return;
    }

    this.loadingService.start();
    this.userService.resetPasswordToken(this.user_email).subscribe(() => {
      this.loadingService.stop();
      this.onSuccess = true;
      this.onSubmit = false;
    }, (error) => {
      console.error(error);
      this.error = error.error;
      this.onFailed = true;
      this.loadingService.stop();
    })
  }
}

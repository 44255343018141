<div class="container">
    <footer class="cine-footer bg-white border-top-1-light">
        <div class="row">
            <div class="col-lg-4">
                <div class="footer-widget widget-1">
                    <div class="">
                        <ul class="fs-14">
                            <strong class="section-title-spacing">Cinebooking</strong><br>
                            <li><a routerLink="/chi-siamo"> Chi Siamo</a> </li>
                            <!-- <li><a routerLink="/suggerimenti"> Hai suggerimenti?</a> </li> -->
                            <!-- <li><a routerLink="/stampa"> Dicono di noi</a> </li> -->
                            <li><a routerLink="/codice-etico"> Codice etico</a> </li>
                            <li><a routerLink="/domande-frequenti"> Domande frequenti</a> </li>
                            <li><a routerLink="/news"> Blog News</a> </li>
                            <li><a routerLink="/"> Torna alla home</a> </li>
                            <br><strong class="section-title-spacing">Termini e Privacy</strong><br>
                            <li><a routerLink="/cookie"> Cookie</a> </li>
                            <li><a routerLink="/termini-e-condizioni"> Termini e condizioni</a>
                            </li>
                            <li><a routerLink="/protezione-dati"> Protezione dati</a> </li>
                            <li><a routerLink="/note-legali"> Note legali</a> </li>
                        </ul>

                        <img src="/assets/img/LOGO-CINEBOOKING-B-scritte-blu-2.png" alt=""
                            style="width: 160px; margin-top:30px;">

                    </div>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="footer-widget widget-2">
                    <a class="color-green" routerLink="/come-funziona/per-cercare">
                        <h2> <span class="underline">Come fare per <span class="text-uppercase bold">CERCARE? <svg
                                        class="svg-inline--fa fa-external-link-alt fa-w-16 colour-green"
                                        aria-hidden="true" focusable="false" data-prefix="fas"
                                        data-icon="external-link-alt" role="img" xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 512 512" data-fa-i2svg="">
                                        <path fill="currentColor"
                                            d="M432,320H400a16,16,0,0,0-16,16V448H64V128H208a16,16,0,0,0,16-16V80a16,16,0,0,0-16-16H48A48,48,0,0,0,0,112V464a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V336A16,16,0,0,0,432,320ZM488,0h-128c-21.37,0-32.05,25.91-17,41l35.73,35.73L135,320.37a24,24,0,0,0,0,34L157.67,377a24,24,0,0,0,34,0L435.28,133.32,471,169c15,15,41,4.5,41-17V24A24,24,0,0,0,488,0Z">
                                        </path>
                                    </svg><!-- <i class="fas fa-external-link-alt colour-green"></i> --></span> </span>
                        </h2>
                        <p>
                            È necessario solo registrarsi e non occorre creare alcun profilo, salvo eccezioni per chi
                            CERCA e PROPONE nel contempo. Seleziona il bottone CERCA, scegli il campo relativo a cosa
                            stai cercando, se crew professionale tecnico/cast artistico o beni materiali a noleggio e
                            completa la scheda. Inviato l’Alert di ricerca riceverai le candidature di lavoro o proposte
                            “rental” in pochi minuti.
                        </p>
                    </a>

                    <a class="color-nblue" routerLink="/come-funziona/per-proporsi">
                        <h2> <span class="underline">Come fare per <span class="text-uppercase bold">PROPORSI? <svg
                                        class="svg-inline--fa fa-external-link-alt fa-w-16 colour-blue"
                                        aria-hidden="true" focusable="false" data-prefix="fas"
                                        data-icon="external-link-alt" role="img" xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 512 512" data-fa-i2svg="">
                                        <path fill="currentColor"
                                            d="M432,320H400a16,16,0,0,0-16,16V448H64V128H208a16,16,0,0,0,16-16V80a16,16,0,0,0-16-16H48A48,48,0,0,0,0,112V464a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V336A16,16,0,0,0,432,320ZM488,0h-128c-21.37,0-32.05,25.91-17,41l35.73,35.73L135,320.37a24,24,0,0,0,0,34L157.67,377a24,24,0,0,0,34,0L435.28,133.32,471,169c15,15,41,4.5,41-17V24A24,24,0,0,0,488,0Z">
                                        </path>
                                    </svg><!-- <i class="fas fa-external-link-alt colour-blue"></i> --></span> </span>
                        </h2>
                        <p>
                            Registrati su "account" seleziona il bottone PROPONI, scegli il campo relativo a ciò che
                            vuoi proporre, se crew professionale tecnica/cast artistico o un bene a noleggio e completa la
                            scheda. Attendi gli Alert. Le tue conferme di disponibilità non sono vincolanti e saranno
                            proposte a chi esegue la ricerca in ordine di risposta, rispondendo subito avrai più
                            probabilità.
                        </p>
                    </a>

                    <div class="footer-btn">
                    </div>

                    <p>
                        Progetto sostenuto e cofinanziato dal Ministero italiano dello Sviluppo Economico
                    </p>

                    <img class="img-fluid" src="/assets/img/MinestoSviluppoEconomico.jpg"
                        alt="">

                </div>
            </div>
            <div class="col-lg-4">
                <div class="footer-widget widget-3 text-center">
                    <p>Scarica gratis la nostra App</p>
                    <div class="download-app-btn">
                        <a class="google-badge"
                            href="https://play.google.com/store/apps/details?id=com.cinebooking.cinebooking"
                            target="_blank">
                            <img src="/assets/img/googleplay_badge.png?v2"
                                alt="Disponibile su Google Play">
                        </a>
                        <a class="apple-badge" href="https://itunes.apple.com/it/app/apple-store/id1451629503"
                            target="_blank">
                            <img src="/assets/img/applestore_badge.png?v2"
                                alt="Disponibile su Apple iTunes">
                        </a>
                    </div>
                    <div style="display: flex;flex-direction: column;">
                    <img class="img-fluid" src="/assets/img/iphone.png"
                        alt="app-screen" style="width: 145px;margin: 20px auto;">

                        <img class="img-fluid" src="/assets/img/match.png"
                        alt="app-screen" style="width: 145px;margin: 20px auto;">
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-12">
                <div class="footer-bottom">
                    <div class="row">

                        <div class="col-lg-6 col-md-12 copyright">
                            <p>© {{year}} Cinebooking Italia S.r.l.</p>
                        </div>

                        <div class="col-lg-6 col-md-12 social-links">
                            <ul class="footer-social-nav text-right">
                                <li id="" class="footer-social-item">
                                    <a class="btn btn-social-icon btn-facebook btn-rounded" target="_blank"
                                        href="https://www.facebook.com/cinebooking/">
                                        <svg class="svg-inline--fa fa-facebook-f fa-w-10" aria-hidden="true"
                                            focusable="false" data-prefix="fab" data-icon="facebook-f" role="img"
                                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" data-fa-i2svg="">
                                            <path fill="currentColor"
                                                d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z">
                                            </path>
                                        </svg><!-- <i class="fab fa-facebook-f"></i> -->
                                    </a>
                                </li>
                                <li id="" class="footer-social-item">
                                    <a class="btn btn-social-icon btn-twitter btn-rounded" target="_blank"
                                        href="https://twitter.com/cinebooking?lang=it">
                                        <svg class="svg-inline--fa fa-twitter fa-w-16" aria-hidden="true"
                                            focusable="false" data-prefix="fab" data-icon="twitter" role="img"
                                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
                                            <path fill="currentColor"
                                                d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z">
                                            </path>
                                        </svg><!-- <i class="fab fa-twitter"></i> -->
                                    </a>
                                </li>
                                <li id="" class="footer-social-item">
                                    <a class="btn btn-social-icon btn-instagram btn-rounded" target="_blank"
                                        href="https://www.instagram.com/cinebooking/?hl=it">
                                        <svg class="svg-inline--fa fa-instagram fa-w-14" aria-hidden="true"
                                            focusable="false" data-prefix="fab" data-icon="instagram" role="img"
                                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg="">
                                            <path fill="currentColor"
                                                d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z">
                                            </path>
                                        </svg><!-- <i class="fab fa-instagram"></i> -->
                                    </a>
                                </li>
                                <li id="" class="footer-social-item tiktok">
                                    <a class="btn btn-social-icon btn-tiktok btn-rounded" target="_blank"
                                        href="https://www.tiktok.com/@cinebooking">
                                        <img src="/assets/img/tik-tok.png" style="margin-right: 0!important;width: 16px;"/>
                                    </a>
                                </li>
                                <li id="" class="footer-social-item youtube">
                                    <a class="btn btn-social-icon btn-youtube btn-rounded" target="_blank"
                                        href="https://www.youtube.com/channel/UCsCbapv6YyA3vF8e5QPOFcA">
                                        <svg class="svg-inline--fa fa-youtube fa-w-18" aria-hidden="true"
                                            focusable="false" data-prefix="fab" data-icon="youtube" role="img"
                                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" data-fa-i2svg="">
                                            <path fill="currentColor"
                                                d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z">
                                            </path>
                                        </svg><!-- <i class="fab fa-youtube"></i> -->
                                    </a>
                                </li>
                                <li id="" class="footer-social-item linkedin">
                                    <a class="btn btn-social-icon btn-linkedin btn-rounded" target="_blank"
                                        href="https://www.linkedin.com/company/11115720/">
                                        <svg class="svg-inline--fa fa-linkedin-in fa-w-14" aria-hidden="true"
                                            focusable="false" data-prefix="fab" data-icon="linkedin-in" role="img"
                                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg="">
                                            <path fill="currentColor"
                                                d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z">
                                            </path>
                                        </svg><!-- <i class="fab fa-linkedin-in"></i> -->
                                    </a>
                                </li>
                            </ul>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </footer>
</div>
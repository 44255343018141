<div class="container">
    <section class="about-cerca page-header-section page-header-padding bg-white">
        <div class="row">
            <div class="col-lg-12">
                <div class="page-header text-center">
                    <p>Inserisci gratis la tua proposta</p>
                    <h2 class="text-uppercase">{{page_title}}</h2>
                    <p class="font-13px">Non sarà pubblica online</p>
                </div>
            </div>
        </div>
    </section>
</div>
<div class="container">
    <section class="job-alert-login-section bg-white  plr-40px">
        <div class="row">
            <div class="col-lg-12">
                <div class="job-alert-login-box">
                    <p>
                    </p>
                    <p class="success-message"> Scheda di proposta {{nice_name}} inserita correttamente in stato di
                        "NO MATCH". Attendi
                        le notifiche via email o in APP dei match ottenuti.<a
                            [routerLink]="address">
                            Clicca qui per visionare la scheda.</a></p>
                </div>
            </div>
        </div>
    </section>
</div>
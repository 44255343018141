<div class="modal-header">
    <h4 class="modal-title">CONFERMA RIMOZIONE ACCOUNT</h4>
    <button tabindex="-1" (click)="dismiss()" type="button" class="close" data-dismiss="modal"
        aria-hidden="true">x</button>

</div>
<div class="modal-body">
    Confermi di voler rimuovere l'account?<br>
    L'operazione è irreversibile.
    Tutti i dati associati all'account saranno rimossi.
    Oltre a dati personali saranno rimosse anche tutte le schede di proposta/ricerca inserite fino a questo momento.
    Se hai un abbonamento PREMIUM attivo, perderai il residuo periodo in abbonamento (non ci saranno rimborsi). 
</div>
<div class="modal-footer">
    <button tabindex="-1" (click)="dismiss()" type="button" class="btn btn-default btn-outline btn-gray-outline"
        data-dismiss="modal" title="annulla rimozione account">Annulla</button>
    <a tabindex="-1" (click)="deleteAccount()" class="btn btn-primary" id="dsselete-account"
        title="Conferma rimozione account">Elimina account</a>
</div>
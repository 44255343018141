import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Invoice } from 'src/core/invoice.core';
import { LoadingService } from '../services/loading.service';
import { CookieService } from 'ngx-cookie-service';
import { InvoiceService } from '../services/invoice.service';

@Component({
  selector: 'app-richiedi-fattura-modal',
  templateUrl: './richiedi-fattura-modal.component.html',
  styleUrls: ['./richiedi-fattura-modal.component.scss']
})
export class RichiediFatturaModalComponent implements OnInit {
  @Output() onRequestInvoice = new EventEmitter();
  invoice = new Invoice();
  subscriptionItem;

  onSubmit: boolean;
  onSuccess: boolean;
  onFailed: boolean;
  error: string;

  @ViewChild('businessName') businessName;
  @ViewChild('vatNumber') vatNumber;
  @ViewChild('pec') pec;
  @ViewChild('sdi') sdi;
  @ViewChild('address') address;
  @ViewChild('streetNumber') streetNumber;
  @ViewChild('city') city;
  @ViewChild('postalCode') postalCode;

  constructor(
    private activeModal: NgbActiveModal,
    private loadingService: LoadingService,
    private cookieService: CookieService,
    private invoiceService: InvoiceService
  ) { }

  ngOnInit(): void {
    this.invoice.subsctiptionItemId = this.subscriptionItem.id;
  }

  dismiss() {
    this.activeModal.dismiss();
  }

  submit() {
    this.onSubmit = true;

    if(this.businessName.invalid || this.vatNumber.invalid || this.pec.invalid || this.sdi.invalid || this.address.invalid || this.city.invalid || this.streetNumber.invalid || this.postalCode.invalid) {
      return;
    }

    this.invoice.user = this.cookieService.get('user_id');
    this.loadingService.start();
    this.invoiceService.insert(this.invoice).subscribe(() => {
      this.onRequestInvoice.emit();
      this.activeModal.close();
      this.loadingService.stop();
    }, (error) => {
      console.error(error);
      this.onFailed = true;
      this.error = error.error;
      this.loadingService.stop();
    })
  }
}

<div class="container">
    <section class="page-header-padding bg-white account-page">
        <div class="row">
            <div class="col-lg-3 col-xl-3"></div>
            <div class="col-md-12 col-lg-6 col-xl-6">
                <div class="form-design">
                    <div class="account-form-box">
                        <form class="cine-booking-form" method="POST" id="register-form">
                            <input type="hidden" name="_token" value="E80t3VLLhMOXUpJUnFUx2zeTy26bweYtCSTURzut">

                            <div class="alert alert-danger alert-block" *ngIf="onSingUpError" style="top: 0!important;">
                                <button type="button" class="colse" data-dismiss="alert" (click)="onSingUpError = false">X</button>
                                <strong>{{singUpError}}</strong>
                            </div>

                            <div class="alert alert-success alert-block" *ngIf="onSingUpMsg" style="top: 0!important;">
                                {{onSingUpMsg}}
                            </div>

                            <div class="account-form-title">
                                <h5><svg class="svg-inline--fa fa-user fa-w-14" aria-hidden="true" focusable="false"
                                        data-prefix="fa" data-icon="user" role="img" xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 448 512" data-fa-i2svg="">
                                        <path fill="currentColor"
                                            d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4z">
                                        </path>
                                    </svg><!-- <i class="fa fa-user"></i> -->Crea un account</h5>
                            </div>
                            <div class="form-row">
                                <div class="form-group col-xs-12 col-sm-12 col-md-12">
                                    <input [(ngModel)]="singupForm.user_name" type="text" name="user_name" id="user_name" class="form-control input-sm"
                                        placeholder="Nome" [required]="true" #singup_user_name="ngModel" autocomplete="given-name">
                                    <div *ngIf="singupForm.onSubmit && singup_user_name.invalid ">
                                        <small *ngIf="singup_user_name.errors?.required" style="color: red;">Il campo non può essere vuoto.</small>
                                    </div>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="form-group col-xs-12 col-sm-12 col-md-12">
                                    <input [(ngModel)]="singupForm.user_surname" type="text" name="user_surname" id="user_surname" class="form-control input-sm"
                                        placeholder="Cognome" [required]="true" #singup_user_surname="ngModel" autocomplete="family-name">
                                    <div *ngIf="singupForm.onSubmit && singup_user_surname.invalid ">
                                        <small *ngIf="singup_user_surname.errors?.required" style="color: red;">Il campo non può essere vuoto.</small>
                                    </div>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="form-group col-xs-12 col-sm-12 col-md-12">
                                    <input [(ngModel)]="singupForm.user_email" type="email" name="email" id="email" class="form-control input-sm"
                                        placeholder="Email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$" [required]="true" #singup_mail="ngModel">
                                    <div *ngIf="singupForm.onSubmit && singup_mail.invalid ">
                                        <small *ngIf="singup_mail.errors?.required" style="color: red;">Il campo non può essere vuoto.</small>
                                        <small *ngIf="singup_mail.errors?.pattern" style="color: red;">L'email non risulta valida.</small>
                                    </div>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="form-group col-xs-12 col-sm-12 col-md-12">
                                    <input [(ngModel)]="singupForm.confirm_email" type="email" name="confirm_email" id="confirm_email" class="form-control input-sm"
                                        placeholder="Conferma email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$" [required]="true" #singup_confirm_email="ngModel">
                                    <div *ngIf="singupForm.onSubmit && singup_confirm_email.invalid ">
                                        <small *ngIf="singup_confirm_email.errors?.required" style="color: red;">Il campo non può essere vuoto.</small>
                                        <small *ngIf="singup_confirm_email.errors?.pattern" style="color: red;">L'email non risulta valida.</small>
                                    </div>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="form-group col-xs-12 col-sm-12 col-md-12">
                                    <input [(ngModel)]="singupForm.user_pass" type="password" name="password" id="password" class="form-control input-sm"
                                        placeholder="Password" [required]="true" #singup_password="ngModel">
                                    <div *ngIf="singupForm.onSubmit && singup_password.invalid ">
                                        <small *ngIf="singup_password.errors?.required" style="color: red;">Il campo non può essere vuoto.</small>
                                    </div>
                                </div>
                            </div>

                            <div class="form-row">
                                <div class="form-group col-xs-12 col-sm-12">
                                    <div class="form-check md-checkbox">
                                        <input class="form-check-input" type="checkbox" name="exampleCheckbox"
                                            id="exampleRadios1" value="option1" [(ngModel)]="singupForm.consentTermsConditionsUse" >
                                        <label class="form-check-label" for="exampleRadios1">
                                            Consenso Termini e condizioni d’uso
                                        </label>
                                    </div>
                                </div>
                                <div style="padding-right: 5px;padding-left: 5px;margin-bottom:1rem" *ngIf="singupForm.onSubmit && !singupForm.consentTermsConditionsUse ">
                                    <small style="color: red;">Accettare le condizioni per proseguire.</small>
                                </div>
                            </div>

                            <div class="form-row">
                                <div class="form-group col-xs-12 col-sm-12">
                                    <div class="form-check md-checkbox">
                                        <input class="form-check-input" type="checkbox" name="exampleCheckbox"
                                            id="exampleRadios2" value="option2" [(ngModel)]="singupForm.consentNewsletter">
                                        <label class="form-check-label" for="exampleRadios2">
                                            Consenso invio newsletter
                                        </label>
                                    </div>
                                </div>
                            </div>


                            <p>I tuoi dati saranno utilizzati per offrirti una gratificante esperienza in questo sito,
                                per gestire l'accesso al tuo account, e per gli scopi descritti nella nostra <a
                                    class="link-gray" href="https://cinebooking.com/protezione-dati/" target="_blank"
                                    style="text-decoration: underline;">informativa sulla privacy</a>. Dopo l’<span
                                    class="color-pink">iscrizione</span> clicca "Proponi" o “Cerca” e riceverai subito
                                proposte o candidature.</p>


                            <div class="form-row">
                                <div class="form-group col ">
                                    <div class="signup-btn">
                                        <input (click)="singup()" type="submit" id="registerBtn" value="Iscriviti Gratis"
                                            class="btn btn-primary btn-block pink-btn w-100 bigfont text-uppercase font-medium">
                                        <svg class="svg-inline--fa fa-angle-right fa-w-8" aria-hidden="true"
                                            focusable="false" data-prefix="fas" data-icon="angle-right" role="img"
                                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512" data-fa-i2svg="">
                                            <path fill="currentColor"
                                                d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z">
                                            </path>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <div class="mb-48px md-down-mb-0px" class="account-sec-cta">
                                <a href="/my-account/sing-in?returnUrl=dashboard">Sono già registrato</a>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-xl-3"></div>
        </div>
    </section>
</div>
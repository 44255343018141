import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CountMatch } from 'src/core/count-match.core';
import { ListingSearchs, Search, SectionSearch } from 'src/core/listing_searchs.core';
import { DeleteModalComponent } from '../delete-modal/delete-modal.component';
import { MatchingSearchsComponent } from '../matching-searchs/matching-searchs.component';
import { LoadingService } from '../services/loading.service';
import { MatchService } from '../services/match.service';
import { ResearchService } from '../services/research.service';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-my-search',
  templateUrl: './my-search.component.html',
  styleUrls: ['./my-search.component.scss']
})
export class MySearchComponent implements OnInit {
  sections: SectionSearch[] = [];
  number_matchs: number = 0;
  currentSection: SectionSearch;
  queryParamSection: string;
  match_uuidParam: string;
  countMatchs: CountMatch = new CountMatch();

  navbarOnMobile: boolean;

  constructor(
    private userService: UserService,
    private researchService: ResearchService,
    private loadingService: LoadingService,
    private matchService: MatchService,
    private modal: NgbModal,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.route.queryParams.subscribe((params) => {
      this.queryParamSection = params['section'];
      this.match_uuidParam = params['match_uuid'];
    })
  }


  ngOnInit(): void {
    this.loadingService.start();
    this.listing();
    this.matchService.count();
    this.matchService.countMatchs.subscribe((count: CountMatch) => {
      if (!count) {
        return;
      }
      this.countMatchs = count;
    });
  }

  listing() {
    const user_id = this.userService.getUserId();
    this.researchService.listing(user_id).subscribe((listing: ListingSearchs) => {
      this.sections = listing.sections;
      this.number_matchs = listing.number_matchs;

      if (this.queryParamSection) {
        this.currentSection = this.sections.find(s => s.section == this.queryParamSection);
      }
      if (!this.currentSection) {
        this.currentSection = listing.sections[0];
      }
      if (this.currentSection) {
        this.setQueryParams(this.currentSection.section);
      }
      if(this.match_uuidParam) {
        const search = this.currentSection.searchs.find(s => s.uuid == this.match_uuidParam);
        if(search) {
          this.view(search);
        }
      }
      this.loadingService.stop();
    });
  }


  isCurrentSection(name: string) {
    return this.currentSection.section == name;
  }

  changeSection(name: string) {
    this.currentSection = this.sections.find(s => s.section == name);
    this.navbarOnMobile = false;
    this.setQueryParams(name);
  }

  setQueryParams(name: string) {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        section: name
      },
      queryParamsHandling: 'merge',
    });
  }


  view(search: Search) {
    const matching = this.modal.open(MatchingSearchsComponent, { centered: true });
    matching.componentInstance.slug = this.currentSection.slug;
    matching.componentInstance.matchs = search.matchs;
    matching.componentInstance.uuid = search.uuid;
    matching.componentInstance.onDeleteMatch.subscribe(() => {
      search.number_matchs -= 1;
      this.currentSection.number_matchs -= 1;
      this.number_matchs -= 1;
    })
  }

  delete(search: Search) {
    const popup = this.modal.open(DeleteModalComponent, { centered: true });
    popup.componentInstance.onDelete.subscribe(() => {
      this.loadingService.start();
      this.researchService.delete(search.uuid).subscribe(() => {
        popup.close();
        this.loadingService.stop();
        this.listing();
      }, (error) => {
        this.loadingService.stop();
        console.error(error);
      })
    });
  }

  hasMatchs(search: Search) {
    if (!search.matchs || search.matchs.length == 0) {
      return false;
    }
    return true;
  }

  isExpires(search: Search) {
    const date = new Date(search.expires);
    const now = new Date();
    return date < now;
  }

  getStatus(search: Search) {
    switch (search.status) {
      case 'draft': {
        return 'Bozza'
      }
      case 'published': {
        return 'In attesa di approvazione'
      }
      case 'suspended': {
        return 'In attesa di approvazione'
      }
      case 'active': {
        return 'Attiva'
      }
      case 'inactive': {
        return 'Rifiutata'
      }
    }
  }

}

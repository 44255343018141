import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Project } from 'src/core/project.core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {
  private resource = 'projects/';
  constructor(
    private http: HttpClient
  ) { }

  insert(project: Project) {
    return this.http.post(`${environment.api}${this.resource}insert`, project);
  }
}

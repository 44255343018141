<div class="container">

    <section id="bg-image" data-type="background" data-speed="5" style="background: url(red_7.jpg) no-repeat;"
        class="page-header-padding bg-white f-cinebook-section parallax-bg">
        <div class="row">
            <div class="col-lg-8 offset-lg-2 col-md-10 offset-md-1">
                <div class="containt-box chi-siamo-bold">
                    <h1>CHI SIAMO</h1>

                    <p><span style="color:#000000">Cinebooking</span> nasce con la registrazione del brand internazionale nel 2015, 
                        con un rilascio on-line nel 2018 e come azienda costituita in Cinebooking Italia S.r.l. nel 2019 
                        dall’intuizione di <span style="color:#000000">Gianfranco De Rosa</span> (Founder) produttore esecutivo cinematografico e producer 
                        pubblicitario impegnato da oltre trent’anni in tre continenti con esperienze nelle major Gruppo Cecchi Gori Film, 
                        Dino De Laurentiis Company, Universal pictures, Warner Bros per citarne alcune. Nel 2001 fonda il Gruppo EFC Cinema e 
                        Pubblicità con sede produttiva presso gli storici Cinecittà Studios, producendo da esecutivo più di 90 film Italiani e 
                        stranieri e oltre 150 spot pubblicitari. Divenendo un punto di riferimento delle produzioni estere in Italia, 
                        alcuni dei suoi Film Daylight, U-571, Hannibal, L’Esorcista la Genesi, Fortapasc, The Inspector Lynley, Il Viaggio Leggendario. </p>

                    <p><span style="color:#000000">L’idea di creare questa piattaforma/motore di ricerca</span>, nasce
                        dall’esigenza di avere uno strumento di facile consultazione e al passo coi tempi, destinato a
                        tutti gli utenti che lavorano nelle industrie dell’entertainment. Cinebooking è in grado di
                        agevolare <span style="color:#000000">l’incontro tra domanda e offerta</span> di lavoro artistico, professionale, 
                        tecnico e forniture dell’industrie di riferimento. Si pone l’obiettivo di sopperire alla mancanza di un motore di ricerca 
                        con funzionalità 4.0 in grado di offrire velocemente e gratuitamente con un rapido match, tutto ciò che è necessario per 
                        la realizzazione di produzioni nell’ambito delle industrie del Cinema, Televisione, Pubblicità, Eventi, Radio, Moda, Musica e Teatro. 
                        Dopo averne condiviso le funzionalità, <span style="color:#000000">Marco Belardi</span> tra i primi produttori cinematografici 
                        e televisivi in Europa, ne ha subito compreso le potenzialità decidendo di supportare la piattaforma e garantendone l’utilizzo 
                        per tutte le sue produzioni firmate Greenboo come la serie “Nuovo Cinema Paradiso” di Giuseppe Tornatore oltre ai suoi successi 
                        prodotti per Lotus production “Perfetti Sconosciuti” e “Immaturi” di Paolo Genovese, “La Pazza Gioia” di Paolo Virzì, 
                        “A Casa Tutti Bene” e “Gli anni più belli” di Gabriele Muccino e tanti altri film di successo internazionale.</p>
                        
                    <p><span style="color:#000000">Carlo Verdone e Marco Risi</span> altri importanti sostenitori della piattaforma, 
                        in occasione della presentazione di Cinebooking alla Festa del Cinema di Roma nel 2018, hanno annunciato nel nostro video 
                        di presentazione di voler utilizzare il motore di ricerca per le prossime loro produzioni.
                    </p>

                </div>
            </div>
        </div>
    </section>
</div>
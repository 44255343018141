import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-welcome-popup',
  templateUrl: './welcome-popup.component.html',
  styleUrls: ['./welcome-popup.component.scss']
})
export class WelcomePopupComponent implements OnInit {

  notAskAgain: boolean = false;

  constructor(
    private activeModal: NgbActiveModal,
    private cookieService: CookieService
  ) { }

  ngOnInit(): void {
  }

  close() {
    this.activeModal.close();
  }

  onChangeNotAskAgain() {
    console.info(this.notAskAgain);
    this.cookieService.set('hide-welcome-popup', this.notAskAgain.toString(), undefined, undefined, undefined, true, "None");
  }

}

<div class="container">

    <section id="bg-image" data-type="background" data-speed="5" 
        class="page-header-padding bg-white f-cinebook-section parallax-bg">
        <div class="row">
            <div class="col-lg-8 offset-lg-2 col-md-10 offset-md-1">
                <div class="containt-box chi-siamo-bold">
                    <h1>CODICE ETICO</h1>

                    <p>Avere chiari valori e principi etici contribuisce a&nbsp;rafforzare l’identità e lo stile
                        professionale di Cinebooking.</p>

                    <h2>CORRETTEZZA E TRASPARENZA</h2>

                    <p>Mantenere un comportamento irreprensibile, agendo secondo diligenza e buona fede e rispettando
                        gli impegni assunti con le funzionalità offerte a tutti gli utenti.</p>

                    <h2>ONESTÀ</h2>

                    <p>Astenersi dal compimento di atti illegali, illeciti, non conformi al comune senso di rettitudine
                        e al comune senso dell’onore e della dignità.</p>

                    <h2>OSSERVANZA DELLE LEGGI</h2>

                    <p>Rispettare tutte le vigenti normative primarie e secondarie, tra cui le disposizioni in materia
                        di legge sul lavoro, della memoria storica del Paese e del patrimonio culturale europeo, delle
                        diversità linguistiche e culturali, nel rispetto delle libertà e dei diritti garantiti dalla
                        Costituzione. Evitare ogni distinzione di età, sesso di orientamento sessuale, di razza, di
                        lingua, di religione, di nazionalità, di opinioni politiche e sindacali, di condizioni personali
                        e sociali, fatto &nbsp;eccezione per la scelta del sesso nella parte scelte artistiche per ovvi
                        motivi.</p>

                    <h2>VALORE DELLE RISORSE UMANE</h2>

                    <p>Tutelare il valore delle risorse umane, anche attraverso un continuo accrescimento delle
                        competenze tecniche e professionali, favorendo lo scambio di informazioni ed esperienze
                        attraverso azioni di comunicazione volte alla condivisione della conoscenza e dei valori
                        aziendali. Favorire l’integrità fisica e morale delle persone attraverso ambienti di lavoro
                        sicuri e salubri e condizioni di lavoro rispettose della dignità individuale.</p>

                    <h2>RISERVATEZZA</h2>

                    <p>Tutte le informazioni apprese nell’ambito delle attività svolte per Cinebooking devono
                        considerarsi riservate e non possono essere divulgate a terzi, né utilizzate per ottenere
                        vantaggi personali, diretti e indiretti.</p>

                    <h2>RESPONSABILITÀ VERSO LA COLLETTIVITÀ</h2>

                    <p>Operare con vigile attenzione e rispetto autentico dei valori di completezza, di imparzialità e
                        obiettività posti a fondamentale garanzia di un’ampia e corretta circolazione delle informazioni
                        e delle idee. Tutelare il valore della concorrenza leale astenendosi da comportamenti
                        ingannevoli, collusivi e di abuso di posizione dominante.</p>

                </div>
            </div>
        </div>
    </section>
</div>
<div class="modal-body" id="loginModal" style="padding: 0;">
    <div class="col-md-12 col-lg-12 col-xl-12" style="padding: 0;">
        <div id="login-id">
            <div class="form-design">
                <div class="account-form-box">
                    <form role="form" class="cine-booking-form" method="POST" id="login-form">
                        <input type="hidden" name="_token" value="E80t3VLLhMOXUpJUnFUx2zeTy26bweYtCSTURzut">

                        <div class="alert alert-danger alert-block" *ngIf="onError">
                            <button type="button" class="colse" data-dismiss="alert"
                                (click)="onError = false">X</button>
                            <strong>{{error}}</strong>
                        </div>

                        <div class="account-form-title">
                            <h5>Condividi scheda</h5>
                            <p>Inserire l'indirizzo email a cui inviare i dettagli di questa proposta</p>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-xs-12 col-sm-12 col-md-12">
                                <input [(ngModel)]="email" type="email" name="email" id="email"
                                    class="form-control input-sm" placeholder="Email"
                                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$" [required]="true"
                                    #emailInput="ngModel">
                                <div *ngIf="onSubmit && emailInput.invalid ">
                                    <small *ngIf="emailInput.errors?.required" style="color: red;">Il campo non può
                                        essere vuoto.</small>
                                    <small *ngIf="emailInput.errors?.pattern" style="color: red;">L'email non risulta
                                        valida.</small>
                                </div>
                            </div>
                        </div>

                        <div class="form-row">
                            <div class="form-group col">
                                <div class="login-btn">
                                    <input (click)="share()" type="submit" id="loginBtn" value="Invia scheda"
                                        class="btn btn-primary btn-block  bg-color-nblue w-100 bigfont text-uppercase font-medium">
                                    <svg class="svg-inline--fa fa-angle-right fa-w-8" aria-hidden="true"
                                        focusable="false" data-prefix="fas" data-icon="angle-right" role="img"
                                        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512" data-fa-i2svg="">
                                        <path fill="currentColor"
                                            d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z">
                                        </path>
                                    </svg><!-- <i class="fas fa-angle-right"></i> -->
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
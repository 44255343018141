import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { CookieService } from 'ngx-cookie-service';
import { Feedback } from 'src/core/feedback.core';
import { FeedbackService } from '../services/feedback.service';
import { LoadingService } from '../services/loading.service';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent implements OnInit {
  feedback: Feedback = new Feedback();
  onSubmit: boolean;
  onSuccess: boolean;
  onFailed: boolean;
  error: string;

  @ViewChild('name') name;
  @ViewChild('surname') surname;
  @ViewChild('phone') phone;
  @ViewChild('reason') reason;
  @ViewChild('email') email;
  @ViewChild('message') message;

  constructor(
    private feedbackService: FeedbackService,
    private cookieService: CookieService,
    private loadingService: LoadingService,
    private title: Title
  ) {
    this.title.setTitle('Feedback – Cinebooking');
  }

  ngOnInit(): void {
  }

  submit() {
    this.onSubmit = true;

    if (this.name.invalid || this.email.invalid || this.surname.invalid || this.phone.invalid || this.reason.invalid || this.message.invalid) {
      return;
    }

    this.feedback.user = this.cookieService.get('user_id');
    this.loadingService.start();
    this.feedbackService.insert(this.feedback).subscribe(() => {
      this.loadingService.stop();
      this.onSuccess = true;
      this.feedback = new Feedback();
      this.onSubmit = false;
    }, (error) => {
      console.error(error);
      this.onFailed = true;
      this.loadingService.stop();
    })
  }

}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Assistance } from 'src/core/assistance.core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AssistanceService {
  private resource = 'assistances/';
  constructor(
    private http: HttpClient
  ) { }

  insert(assistance: Assistance) {
    return this.http.post(`${environment.api}${this.resource}insert`, assistance);
  }
}

<div class="container">

    <section id="bg-image" data-type="background" data-speed="5" style="background: url(green_4.jpg) no-repeat;"
        class="page-header-padding bg-white f-cinebook-section parallax-bg">
        <div class="row">
            <div class="col-lg-8 offset-lg-2 col-md-10 offset-md-1">
                <div class="containt-box chi-siamo-bold">
                    <h1>NEWS</h1>

                    <p><strong>Cinebooking alla Festa del Cinema di Roma 2018</strong>&nbsp;presso lo Spazio Roma Lazio
                        Film Commission dove, alla presenza di oltre 100 partecipanti,&nbsp;lo staff ha dialogato con
                        l’utenza, dispensando consigli insieme a prestigiosi ospiti: I registi Carlo Verdone e Marco
                        Risi ed i produttori cinematografici Marco Belardi, Nando Mormone, Giuseppe Saccà.</p>

                    <p style="text-align:center"><a href="https://youtu.be/le5LO6QFokc" target="_blank"><img alt=""
                                src="/assets/img/youtube-play-icon.png"
                                style="height:70px; width:100px"></a></p>

                    <p><img alt=""
                            src="/assets/img/n1.jpg"
                            style="height:467px; width:700px"></p>

                    <p><img alt=""
                            src="/assets/img/n2.jpg"
                            style="height:467px; width:700px"></p>

                    <p><img alt=""
                            src="/assets/img/n3.jpg"
                            style="height:467px; width:700px"></p>

                    <p><img alt=""
                            src="/assets/img/n4.jpg"
                            style="height:467px; width:700px"></p>

                    <h1>NEWS</h1>

                    <p><strong>Cinebooking al Microsalon Italia (AIC) 2018</strong>&nbsp;ha registrato al nostro “stand”
                        600 persone iscritte alla piattaforma su 2500 registrati per l’ingresso all’evento che ogni anno
                        prevalentemente è in Cinecittà Studios.<br>
                        Nella foto lo staff con il Fondatore il produttore esecutivo Gianfranco De Rosa, la Manager
                        Marioli Borges Padron e in ultima foto l’intervista al produttore Marco Belardi amministratore
                        delegato della Lotus production.<br>
                        Belardi interessato a utilizzare Cinebooking per i suoi prossimi progetti, ci ha fatto visita
                        allo “stand” fornendo la sua testimonianza in un intervista.</p>

                    <p style="text-align:center"><a href="https://www.youtube.com/watch?v=tGqTgP8y8CU"
                            target="_blank"><img alt=""
                                src="/assets/img/youtube-play-icon.png"
                                style="height:70px; width:100px"></a></p>

                    <p><img alt=""
                            src="/assets/img/n5.jpg"
                            style="height:525px; width:700px"></p>

                    <p><img alt=""
                            src="/assets/img/n6.jpg"
                            style="height:525px; width:700px"></p>

                    <p><img alt=""
                            src="/assets/img/n7.jpg"
                            style="height:525px; width:700px"></p>

                    <p><img alt=""
                            src="/assets/img/n8.jpg"
                            style="height:525px; width:700px"></p>

                </div>
            </div>
        </div>
    </section>
</div>
<div *ngIf="form">
    <div class="proponi-listing">
        <section class="container">
            <div class="back-button {{color('', '-bg')}} section-preview" *ngIf="form.status === 'draft'">
                <div class="row">
                    <div class="col-lg-6 col-6">
                        <div>
                            <h6 class="proposta-number">{{isCerca ? 'Ricerca' : 'Proposta'}} N. {{form._id}}
                            </h6>
                        </div>
                    </div>
                    <div class="col-lg-6 col-6">
                        <div class="col-dropdown">
                            <div class="dropdown custom-dropdown">
                                <button class="btn btn-secondary dropdown-toggle menu-down-arrow" type="button"
                                    id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true"
                                    aria-expanded="false">
                                </button>
                                <div class="dropdown-menu custom-menu" aria-labelledby="dropdownMenuButton">
                                    <ul class="dropdown-menu-ul">
                                        <li *ngIf="!isCerca">
                                            <a class="dropdown-item" (click)="goToProposal()">Le mie
                                                proposte</a>
                                        </li>
                                        <li *ngIf="isCerca">
                                            <a class="dropdown-item" (click)="goToResearch(false)">Le mie
                                                ricerce</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="back-button {{color('', '-bg')}} listing section-listing" style="height: 190px !important;"
                *ngIf="form.status === 'published' || form.status === 'active'">
                <div class="row">
                    <div class="col-lg-6 col-6">
                        <div>
                            <h6 class="uid-no listing-uid proposta-number" *ngIf="!isCerca">
                                <a (click)="back()" class="btm btn-primary btn-lg btn-back-custom"
                                    style="cursor: pointer;"><svg class="svg-inline--fa fa-chevron-left fa-w-10"
                                        aria-hidden="true" focusable="false" data-prefix="fa" data-icon="chevron-left"
                                        role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"
                                        data-fa-i2svg="">
                                        <path fill="currentColor"
                                            d="M34.52 239.03L228.87 44.69c9.37-9.37 24.57-9.37 33.94 0l22.67 22.67c9.36 9.36 9.37 24.52.04 33.9L131.49 256l154.02 154.75c9.34 9.38 9.32 24.54-.04 33.9l-22.67 22.67c-9.37 9.37-24.57 9.37-33.94 0L34.52 272.97c-9.37-9.37-9.37-24.57 0-33.94z">
                                        </path>
                                    </svg><!-- <i class="fa fa-chevron-left"></i> --></a>
                                <span class="listing-number"> Proposta N. {{form._id}}</span>
                            </h6>
                            <h6 class="listing-uid proposta-number" *ngIf="isCerca">
                                <a (click)="back()" class="btm btn-primary btn-lg btn-back-custom btn-back-custom-cerca"
                                    style="cursor: pointer;"><svg class="svg-inline--fa fa-chevron-left fa-w-10"
                                        aria-hidden="true" focusable="false" data-prefix="fa" data-icon="chevron-left"
                                        role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"
                                        data-fa-i2svg="">
                                        <path fill="currentColor"
                                            d="M34.52 239.03L228.87 44.69c9.37-9.37 24.57-9.37 33.94 0l22.67 22.67c9.36 9.36 9.37 24.52.04 33.9L131.49 256l154.02 154.75c9.34 9.38 9.32 24.54-.04 33.9l-22.67 22.67c-9.37 9.37-24.57 9.37-33.94 0L34.52 272.97c-9.37-9.37-9.37-24.57 0-33.94z">
                                        </path>
                                    </svg></a> <span class="listing-number">Ricerca N. {{form._id}}
                                </span>
                            </h6>
                        </div>
                    </div>
                    <div class="col-lg-6 col-6">
                        <div class="col-dropdown" style="padding-top: 6.5rem !important;">
                            <div class="dropdown custom-dropdown" *ngIf="!token">
                                <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton"
                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                </button>
                                <div class="dropdown-menu custom-menu" aria-labelledby="dropdownMenuButton"
                                    *ngIf="!isCerca">
                                    <ul class="dropdown-menu-ul">
                                        <li *ngIf="!match_uuid">
                                            <a class="dropdown-item" (click)="goToProposal()">Le mie
                                                proposte</a>
                                        </li>
                                        <li *ngIf="match_uuid">
                                            <a class="dropdown-item" (click)="goToResearch()">Le mie
                                                ricerche</a>
                                        </li>
                                        <li *ngIf="!match_uuid">
                                            <a class="dropdown-item"
                                                routerLink="/inserimento-proposta/edit/{{slug}}/{{uuid}}">Modifica
                                                scheda</a>
                                        </li>
                                    </ul>
                                </div>
                                <div class="dropdown-menu custom-menu" aria-labelledby="dropdownMenuButton"
                                    *ngIf="isCerca">
                                    <ul class="dropdown-menu-ul">
                                        <li *ngIf="!match_uuid">
                                            <a class="dropdown-item" (click)="goToResearch()">Le mie
                                                ricerche</a>
                                        </li>
                                        <li *ngIf="match_uuid">
                                            <a class="dropdown-item" (click)="goToProposal()">Le mie
                                                proposte</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="heading-line">
                <div class="row">
                    <div class="col-lg-6">
                        <h3 class="heading-left">{{title.value}}</h3>
                    </div>
                    <div class="col-lg-6">
                        <p class="text-side-right">
                            <button *ngIf="!isCerca" (click)="openShare()" type="button" data-toggle="modal" data-target="#exampleModal-DLCapZRpaCuZtYCJ" 
                            class="btn btn-primary btn-sm btn-block bg-color-nblue btn-flat" style="border-radius: 1px;min-width: 120px;">CONDIVIDI</button>
                            <span *ngIf="isCerca">Presentazione</span>
                        </p>
                    </div>
                </div>
            </div>
            <div class="listing-postion">

                <div class="container proposal-2-button" *ngIf="isScheda && isCerca && match_uuid">
                    <div class="row">
                        <div class="col-lg-6 text-center">
                            <a (click)="goToProposalConCondidature()"
                                class="btn btn-secondary proposal-center-btn-1">TORNA ALLE PROPOSTE</a>
                        </div>
                        <div class="col-lg-6 text-center">
                            <button (click)="candidate()" type="button" id="send-application" [disabled]="alreadyCandidate"
                                class="btn custom-btn-warning proposal-center-btn-2"> {{alreadyCandidate ? 'CANDIDATA' : 'INVIA CANDIDATURA'}} </button>
                        </div>
                    </div>
                    <br>
                    <div id="job-alert-text" class="text-center proposal-text">
                        <h6>AVVISO: INVIANDO CANDIDATURA,</h6>
                        <p>sarai contattato/a solo in caso di scelta.</p>
                        <p>Se richiesto in scheda, recati sul posto.
                        </p>
                    </div>
                </div>

                <div class="container" *ngIf="isScheda && !isCerca && match_uuid">
                    <div class="row">
                        <div class="col-sm-12 text-center">
                            <a (click)="goToResearchConCondidature()"
                                class="buttons btn btn-success bg-green-important strong pntmd-back-to-x">TORNA ALLE
                                CANDIDATURE
                            </a>
                            <div class="clearfix hidden-sm hidden-md hidden-lg">
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-6">
                        <div class="list-section1">

                            <div class="row" *ngFor="let field of photosFields(); let i = index">
                                <div class="col-lg-12">
                                    <div class="inner-section">
                                        <h6><svg class="svg-inline--fa fa-image fa-w-16" aria-hidden="true"
                                                focusable="false" data-prefix="fa" data-icon="image" role="img"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"
                                                data-fa-i2svg="">
                                                <path fill="currentColor"
                                                    d="M464 448H48c-26.51 0-48-21.49-48-48V112c0-26.51 21.49-48 48-48h416c26.51 0 48 21.49 48 48v288c0 26.51-21.49 48-48 48zM112 120c-30.928 0-56 25.072-56 56s25.072 56 56 56 56-25.072 56-56-25.072-56-56-56zM64 384h384V272l-87.515-87.515c-4.686-4.686-12.284-4.686-16.971 0L208 320l-55.515-55.515c-4.686-4.686-12.284-4.686-16.971 0L64 336v48z">
                                                </path>
                                            </svg><!-- <i class="fa fa-image"></i> --> {{field.label}}</h6>
                                        <div class="job-manager-uploaded-files">
                                            <div class="phfiles" style="font-size:13px; color:#ccc">
                                                <div class="job-manager-uploaded-file c27-uploaded-image" (click)="openGallery(i)">
                                                    <span class="job-manager-uploaded-file-preview"
                                                        *ngFor="let value of field.value">
                                                        <img src="{{value}}" class="img-style-1">
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row" *ngFor="let field of textAreaFields()">
                                <div class="col-lg-12" *ngIf="field.stringValue">
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <div class="inner-section inner-sec">
                                                <h6><svg class="svg-inline--fa fa-align-justify fa-w-14"
                                                        aria-hidden="true" focusable="false" data-prefix="fa"
                                                        data-icon="align-justify" role="img"
                                                        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"
                                                        data-fa-i2svg="">
                                                        <path fill="currentColor"
                                                            d="M432 416H16a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16zm0-128H16a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16zm0-128H16a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16zm0-128H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16z">
                                                        </path>
                                                    </svg>
                                                    {{field.label}}</h6>
                                                <p *ngIf="isLinkHttpUrl(field.stringValue)">
                                                    <a href="{{field.stringValue}}" target="_blank">{{field.stringValue}}</a>
                                                </p>
                                                <p *ngIf="!isLinkHttpUrl(field.stringValue)">{{field.stringValue}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="inner-section">
                                    <h6><svg class="svg-inline--fa fa-list-ul fa-w-16" aria-hidden="true"
                                            focusable="false" data-prefix="fa" data-icon="list-ul" role="img"
                                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
                                            <path fill="currentColor"
                                                d="M48 48a48 48 0 1 0 48 48 48 48 0 0 0-48-48zm0 160a48 48 0 1 0 48 48 48 48 0 0 0-48-48zm0 160a48 48 0 1 0 48 48 48 48 0 0 0-48-48zm448 16H176a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h320a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16zm0-320H176a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h320a16 16 0 0 0 16-16V80a16 16 0 0 0-16-16zm0 160H176a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h320a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16z">
                                            </path>
                                        </svg><!-- <i class="fa fa-list-ul"></i> --> Dati forniti</h6>
                                    <div class="list-sec">
                                        <div class="pf-body">
                                            <ul class="extra-details detail-display">
                                                <li *ngFor="let field of basicFields()">
                                                    <div>
                                                        <div class="item-attr">{{field.label}}</div>
                                                        <div class="item-property">{{field.stringValue ? field.stringValue : field.value}}</div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12" *ngFor="let field of attachmentFields()">
                                <div class="inner-section">
                                    <div class="video-section">
                                        <div class="element files-block files-count-1">
                                            <div class="pf-head">
                                                <div class="title-style-1 title-style-1">
                                                    <i class="mi attach_file"></i>
                                                    <h6><svg class="svg-inline--fa fa-paperclip fa-w-14"
                                                            aria-hidden="true" focusable="false" data-prefix="fas"
                                                            data-icon="paperclip" role="img"
                                                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"
                                                            data-fa-i2svg="">
                                                            <path fill="currentColor"
                                                                d="M43.246 466.142c-58.43-60.289-57.341-157.511 1.386-217.581L254.392 34c44.316-45.332 116.351-45.336 160.671 0 43.89 44.894 43.943 117.329 0 162.276L232.214 383.128c-29.855 30.537-78.633 30.111-107.982-.998-28.275-29.97-27.368-77.473 1.452-106.953l143.743-146.835c6.182-6.314 16.312-6.422 22.626-.241l22.861 22.379c6.315 6.182 6.422 16.312.241 22.626L171.427 319.927c-4.932 5.045-5.236 13.428-.648 18.292 4.372 4.634 11.245 4.711 15.688.165l182.849-186.851c19.613-20.062 19.613-52.725-.011-72.798-19.189-19.627-49.957-19.637-69.154 0L90.39 293.295c-34.763 35.56-35.299 93.12-1.191 128.313 34.01 35.093 88.985 35.137 123.058.286l172.06-175.999c6.177-6.319 16.307-6.433 22.626-.256l22.877 22.364c6.319 6.177 6.434 16.307.256 22.626l-172.06 175.998c-59.576 60.938-155.943 60.216-214.77-.485z">
                                                            </path>
                                                        </svg><!-- <i class="fas fa-paperclip"></i> -->
                                                        {{field.label}}</h6>
                                                </div>
                                            </div>
                                            <div class="pf-body">
                                                <ul class="file-list">
                                                    <div *ngFor="let value of field.value" 
                                                        target="_blank">
                                                        <li class="file" style="display:flex;justify-content:space-between">
                                                            <div>
                                                            <span class="file-icon"><svg
                                                                    class="svg-inline--fa fa-file-video fa-w-12"
                                                                    aria-hidden="true" focusable="false"
                                                                    data-prefix="far" data-icon="file-video" role="img"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    viewBox="0 0 384 512" data-fa-i2svg="">
                                                                    <path fill="currentColor"
                                                                        d="M369.941 97.941l-83.882-83.882A48 48 0 0 0 252.118 0H48C21.49 0 0 21.49 0 48v416c0 26.51 21.49 48 48 48h288c26.51 0 48-21.49 48-48V131.882a48 48 0 0 0-14.059-33.941zM332.118 128H256V51.882L332.118 128zM48 464V48h160v104c0 13.255 10.745 24 24 24h104v288H48zm228.687-211.303L224 305.374V268c0-11.046-8.954-20-20-20H100c-11.046 0-20 8.954-20 20v104c0 11.046 8.954 20 20 20h104c11.046 0 20-8.954 20-20v-37.374l52.687 52.674C286.704 397.318 304 390.28 304 375.986V264.011c0-14.311-17.309-21.319-27.313-11.314z">
                                                                    </path>
                                                                </svg>
                                                                <!-- <i class="far fa-file-video"></i> --></span>
                                                            <a href="{{value}}" class="file-name">{{filename(value)}}</a>
                                                        </div>
                                                            <span class="file-link" style="display: flex;flex-direction:column;margin-left:0!important">
                                                                <a href="{{value}}" class="file-name" style="margin-bottom: 0!important;">
                                                                View<svg
                                                                    class="svg-inline--fa fa-external-link-alt fa-w-16 open-icon"
                                                                    aria-hidden="true" focusable="false"
                                                                    data-prefix="fas" data-icon="external-link-alt"
                                                                    role="img" xmlns="http://www.w3.org/2000/svg"
                                                                    viewBox="0 0 512 512" data-fa-i2svg="">
                                                                    <path fill="currentColor"
                                                                        d="M432,320H400a16,16,0,0,0-16,16V448H64V128H208a16,16,0,0,0,16-16V80a16,16,0,0,0-16-16H48A48,48,0,0,0,0,112V464a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V336A16,16,0,0,0,432,320ZM488,0h-128c-21.37,0-32.05,25.91-17,41l35.73,35.73L135,320.37a24,24,0,0,0,0,34L157.67,377a24,24,0,0,0,34,0L435.28,133.32,471,169c15,15,41,4.5,41-17V24A24,24,0,0,0,488,0Z">
                                                                    </path>
                                                                </svg>
                                                            </a>
                                                            <div class="delete-file" (click)="deleteAttachment(field, value)" *ngIf="form.status === 'draft' || isOwner()">
                                                                Elimina  
                                                            </div>
                                                                </span>
                                                        </li>
                                                    </div>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="container proposal-2-button" *ngIf="isScheda && isCerca && match_uuid">
                    <div class="row">
                        <div class="col-lg-6 text-center">
                            <a (click)="goToProposalConCondidature()"
                                class="btn btn-secondary proposal-center-btn-1">TORNA ALLE PROPOSTE</a>
                        </div>
                        <div class="col-lg-6 text-center">
                            <button (click)="candidate()" type="button" id="send-application" [disabled]="alreadyCandidate"
                                class="btn custom-btn-warning proposal-center-btn-2"> {{alreadyCandidate ? 'CANDIDATA' : 'INVIA CANDIDATURA'}} </button>
                        </div>
                    </div>
                    <br>
                    <div id="job-alert-text" class="text-center proposal-text">
                        <h6>AVVISO: INVIANDO CANDIDATURA,</h6>
                        <p>sarai contattato/a solo in caso di scelta.</p>
                        <p>Se richiesto in scheda, recati sul posto.
                        </p>
                    </div>
                </div>

                <div class="container" *ngIf="isScheda && !isCerca && match_uuid">
                    <div class="row">
                        <div class="col-sm-12 text-center">
                            <a (click)="goToResearchConCondidature()"
                                data-idscheda="1332"
                                class="buttons btn btn-success bg-green-important strong pntmd-back-to-x">TORNA ALLE
                                CANDIDATURE
                            </a>
                            <div class="clearfix hidden-sm hidden-md hidden-lg">
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </section>
    </div>
    <div class="container" *ngIf="form.status === 'draft'">
        <div class="card {{color('card-custom-', '')}}">
            <div class="card-body preview-card-body">
                <form>
                    <h5 class="card-title center-block color-white fs-16">Preview Scheda</h5>
                    <div class="row">
                        <div class="col-lg-12 col-sm-6  white-bg-pre-btn">
                            <button (click)="publish()" type="submit"
                                class="btn btn-light btn-lg btn-confirm {{color('btn-confirm-', '')}}">
                                <span>INVIA SCHEDA</span>
                            </button>
                        </div>
                        <div class="col-lg-12 col-sm-6 pink-bg-pre-btn">
                            <a *ngIf="!isCerca" routerLink="/inserimento-proposta/edit/{{slug}}/{{uuid}}"
                                class="btn btn-danger btn-lg btn-modfica">
                                <span>MODIFICA</span>
                            </a>
                            <a *ngIf="isCerca" routerLink="/inserimento-ricerca/edit/{{slug}}/{{uuid}}"
                            class="btn btn-danger btn-lg btn-modfica">
                            <span>MODIFICA</span>
                        </a>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>


<style>
    .card-custom-proponi {
        background-color: #272f66;
        border-radius: 0;
        padding-left: 1px;
        top: 91px;
        position: absolute;
        border-color: #272f66;
    }

    .card-custom-cerca {
        background-color: #4ea53f;
        border-radius: 0;
        padding-left: 1px;
        top: 91px;
        position: absolute;
        border-color: #4ea53f;
    }

    .btn-confirm {
        border: 1px solid #db2557;
    }

    .custom-btn-warning {
        width: 235px;
    }

    .proposal-center-btn-1 {
        width: 235px;
        color: #fff;
    }

    .custom-btn-warning:hover {
        background-color: #db2557 !important;
        border-color: #db2557!important;
        color: #fff;
    }

    .custom-btn-warning:disabled {
        background-color: #db2557 !important;
        border-color: #db2557!important;
        color: #fff;
    }

    @media screen and (min-width: 768.99px) and (max-width: 992px) {
        .card-custom-blue {
            padding-left: 3rem;
            background-color: transparent !important;
            border: none !important;
        }

        .card-custom-cerca {
            padding-left: 3rem;
            background-color: transparent !important;
            border: none !important;
        }

        .btn-modfica {
            margin-top: 0 !important;
            border: 1px solid #db2557 !important;
        }

        .container .proponi-bg .setion-preview {
            background: #272f66;
            height: 100px !important;
            margin-top: 14rem;
        }

        .back-button h6 {
            padding-top: 2rem !important;
        }
    }

    @media screen and (min-width: 575.99px) and (max-width: 768px) {
        .btn-modfica {
            margin-top: 0 !important;
            border: 1px solid #db2557 !important;
        }

        .card-custom-blue {
            background-color: transparent !important;
            border: none !important;
        }

        .card-custom-cerca {
            background-color: transparent !important;
            border: none !important;
        }

        .container .proponi-bg .setion-preview {
            background: #272f66;
            height: 100px !important;
            margin-top: 14rem;
        }

        .back-button h6 {
            padding-top: 2rem;
        }

        .section-preview .proposta-number {
            padding-top: 17.5rem;
            left: 1.9rem;
        }

        .proponi-listing .section-preview .col-dropdown {
            padding-top: 16.5rem;
            padding-right: 0px;
        }

        .section-listing .listing-uid {
            padding-top: 7.5rem;
        }
    }

    @media screen and (min-width: 360.99px) and (max-width: 576px) {
        .card-custom-blue {
            background-color: transparent !important;
            border: none !important;
        }

        .container .proponi-bg .setion-preview {
            background: #272f66;
            height: 100px !important;
            margin-top: 18rem;
        }

        .back-button h6 {
            padding-top: 2rem;
        }

        .proponi-listing .section-preview .proposta-number {
            padding-top: 16rem;
            left: 1.3rem;
        }

        .proponi-listing .section-preview .col-dropdown {
            padding-top: 16.5rem;
            padding-right: 0px;
        }
    }

    .btn-modfica {
        border: 1px solid #fff !important;
    }

    .btn-confirm {
        width: 249px;
        border-radius: 2px;
        color: #db2557;
        font-size: 24px;
    }

    .btn-modfica {
        width: 249px;
        border-radius: 2px;
        color: #ffffff;
        font-size: 24px;
        margin-top: 5px;
        border-color: #ffffff;
        background-color: #db2557 !important;
    }

    .btn-confirm:focus {
        background-color: #dae0e5;
        border-color: #dae0e5;
        box-shadow: none !important;
    }

    .btn-modfica:focus {
        color: #ffffff;
        background-color: #c82333;
        border-color: #bd2130;
        box-shadow: none !important;
    }

    .btn-confirm-proponi:hover {
        background-color: #272f66;
        color: #ffffff;
    }

    .btn-modfica-proponi:hover {
        background-color: #272f66;
        color: #ffffff;
        border-color: #ffffff;
    }

    .btn-confirm-cerca:hover {
        background-color: #4ea53f;
        color: #ffffff;
    }

    .btn-modfica-cerca:hover {
        background-color: #4ea53f;
        color: #ffffff;
        border-color: #ffffff;
    }


    .w-25 {
        width: 21.5% !important;
    }

    .pink-bg-pre-btn {
        max-width: none;
    }

    .btn-confirm {
        width: 249px;
        border-radius: 2px;
        color: #db2557;
        font-size: 24px;
    }
</style>
<div class="container">

    <section id="bg-image" data-type="background" data-speed="5" style="background: url(cyan_3.jpg) no-repeat;"
        class="page-header-padding bg-white f-cinebook-section parallax-bg">
        <div class="row">
            <div class="col-lg-8 offset-lg-2 col-md-10 offset-md-1">
                <div class="containt-box chi-siamo-bold">
                    <h1>NOTE LEGALI</h1>

                    <h2>COPYRIGHT</h2>

                    <p>Il marchio Cinebooking ® nonché tutti i marchi, siano essi figurativi o meno, e tutti gli altri
                        segni, nomi commerciali, marchi di servizio, marchi denominativi, denominazioni commerciali,
                        illustrazioni, immagini e loghi che appaiono sul Sito sono e rimangono di proprietà esclusiva di
                        Cinebooking e/o dei suoi licenziatari e sono protetti dalle leggi vigenti sui marchi e dai
                        relativi trattati internazionali. In questa sezione sono indicate le politiche dell’Agenzia del
                        Lavoro, ai sensi del D.lgs n. 196/2003 “Codice in materia di protezione dei dati personali”, in
                        merito al trattamento dei dati personali raccolti durante la navigazione sul sito web ufficiale
                        dell’Ente e dei portali da esso gestiti.</p>

                    <h2>TITOLARE DEL TRATTAMENTO DEI DATI</h2>

                    <p>Il titolare del trattamento dei dati personali è Cinebooking Italia S.r.l.</p>

                    <h2>FINALITÀ DEL TRATTAMENTO.</h2>

                    <p>Tali dati personali, compresi quelli sensibili e giudiziari, sono trattati per assolvere le
                        funzioni istituzionali della piattaforma. Le finalità del trattamento sono strettamente connesse
                        e strumentali alle attività di avvio, gestione e conclusione del procedimento che riguarda
                        l’utente. In tale ambito il conferimento dei dati è obbligatorio poiché previsto dalle leggi,
                        dai regolamenti o dalla normativa comunitaria, che disciplinano la prestazione e gli adempimenti
                        connessi; la mancata fornitura dei dati richiesti può comportare impossibilità o ritardi nella
                        definizione dei procedimenti, oltre che, in taluni casi individuati dalla normativa di
                        riferimento, anche l’applicazione di sanzioni. I dati già in possesso dell’Agenzia sono trattati
                        soltanto se indispensabili allo svolgimento di dette attività. Quando non sussiste un obbligo
                        alla comunicazione delle informazioni all’Agenzia da parte degli interessati, gli stessi sono
                        opportunamente informati che il conferimento dei dati è facoltativo e l’omessa comunicazione non
                        determina alcuna conseguenza (es: indicazione indirizzo e-mail) o, al massimo, impedisce la
                        concessione del beneficio richiesto.</p>

                    <h2>MODALITÀ DI TRATTAMENTO.</h2>

                    <p>Il trattamento avviene, anche mediante l’ausilio di strumenti elettronici, ad opera di dipendenti
                        dell’Agenzia opportunamente incaricati e istruiti, con logiche strettamente correlate alle
                        finalità per le quali i dati sono raccolti e, comunque, in modo da garantirne la sicurezza e la
                        riservatezza nel rispetto delle previsioni del D. Lgs. n. 196/03. Eccezionalmente possono
                        conoscere i dati degli utenti altri soggetti, che forniscono servizi o svolgono attività
                        strumentali per conto dell’Agenzia e operano in qualità di Responsabili designati dall’Agenzia.
                    </p>

                    <h2>SOGGETTI AI QUALI I DATI POSSONO ESSERE COMUNICATI</h2>

                    <p>I dati personali, laddove sia indispensabile per la definizione del procedimento, potranno essere
                        comunicati: ad altri soggetti pubblici o privati, quando ciò sia previsto dalla legge o dai
                        regolamenti e nei limiti dagli stessi fissati o sia necessario per il perseguimento dei fini
                        istituzionali; ad Istituti di credito o Uffici Postali indicati dagli interessati. Si tratta di
                        autonomi Titolari del trattamento, che possono operare nei limiti strettamente necessari per la
                        sola finalità per cui si è proceduto alla comunicazione.</p>

                    <h2>DIFFUSIONE DEI DATI</h2>

                    <p>I dati personali possono essere diffusi solo se previsto da una norma di legge o di regolamento;
                        i dati sanitari non possono essere diffusi.</p>

                    <h2>DIRITTI DELL’INTERESSATO</h2>

                    <p>Si informano gli interessati che è nelle loro facoltà esercitare i diritti previsti all’<a
                            href="http://www.agenzialavoro.tn.it/art7">art. 7 del D. Lgs. n. 196/2003</a>, in
                        particolare il diritto di ottenere la conferma dell’esistenza o meno di dati personali che li
                        riguardano e la loro comunicazione in forma intelligibile, di chiedere la cancellazione, la
                        trasformazione in forma anonima o il blocco dei dati trattati in violazione di legge, nonché di
                        opporsi in ogni caso, per motivi legittimi, al loro trattamento.</p>

                    <h2>TIPI DI DATI TRATTATI</h2>

                    <p>Dati di navigazione I sistemi informatici e le procedure software preposte al funzionamento di
                        questo sito web acquisiscono, nel corso del loro normale esercizio, alcuni dati personali la cui
                        trasmissione è implicita nell’uso dei protocolli di comunicazione di Internet. Si tratta di
                        informazioni che non sono raccolte per essere associate a interessati identificati, ma che per
                        loro stessa natura potrebbero, attraverso elaborazioni ed associazioni con dati detenuti da
                        terzi, permettere di identificare gli utenti. In questa categoria di dati rientrano gli
                        indirizzi IP o i nomi a dominio dei computer utilizzati dagli utenti che si connettono al sito,
                        l’orario della richiesta, ecc.) ed altri parametri relativi al sistema operativo e all’ambiente
                        informatico dell’utente. Questi dati vengono utilizzati al solo fine di ricavare informazioni
                        statistiche anonime sull’uso del sito e per controllarne il corretto funzionamento e vengono
                        conservati per il tempo strettamente necessario. I dati potrebbero essere utilizzati per
                        l’accertamento di responsabilità in caso di ipotetici reati informatici ai danni del sito. Dati
                        forniti volontariamente dall´utente L’invio facoltativo, esplicito e volontario di posta
                        elettronica agli indirizzi indicati su questo sito comporta la successiva acquisizione
                        dell’indirizzo del mittente, necessario per rispondere alle richieste, nonché degli eventuali
                        altri dati personali inseriti nella missiva. Specifiche informative di sintesi verranno
                        progressivamente riportate o visualizzate nelle pagine del sito predisposte per particolari
                        servizi a richiesta.</p>

                </div>
            </div>
        </div>
    </section>
</div>
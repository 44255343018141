import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProposalService {
  private resource = 'proposals/';

  constructor(
    private http: HttpClient
  ) { }

  insert(proposal) {
    return this.http.post(`${environment.api}${this.resource}insert`, proposal);
  }

  edit(proposal, uuid: string) {
    return this.http.post(`${environment.api}${this.resource}edit/${uuid}`, proposal);
  }

  publish(uuid: string) {
    return this.http.post(`${environment.api}${this.resource}publish`, { uuid });
  }

  setViews(uuid: string, match_uuid: string) {
    return this.http.post(`${environment.api}${this.resource}setViews`, { uuid, match_uuid });
  }

  isCandidate(uuid: string, match_uuid: string) {
    return this.http.post<boolean>(`${environment.api}${this.resource}isCandidate`, { uuid, match_uuid });
  }

  delete(uuid: string) {
    return this.http.post(`${environment.api}${this.resource}delete`, { uuid });
  }

  deleteMatch(uuid: string, match_uuid: string) {
    return this.http.post(`${environment.api}${this.resource}deleteMatch`, { uuid, match_uuid });
  }

  read(uuid: string) {
    return this.http.get(`${environment.api}${this.resource}read/${uuid}`);
  }

  listing(user_id: string) {
    return this.http.get(`${environment.api}${this.resource}listing/${user_id}`);
  }

  deleteAttachemnt(params) {
    return this.http.post(`${environment.api}${this.resource}deleteAttachment`, params);
  }

  moreListing(user_id: string, name: string, skip: number) {
    return this.http.get(`${environment.api}${this.resource}moreListing/${user_id}/${name}/${skip}`);
  }

  shareScheda(params) {
    return this.http.post(`${environment.api}${this.resource}shareScheda`, params);
  }
}

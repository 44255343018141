<div id="pntmd-modal-form-ricerca">
    <div class="modal-content" style="margin-top: 0!important;">
        <div class="modal-header">
            <h4 class="modal-title">SEI SICURO?</h4>
            <button tabindex="-1" (click)="dismiss()" type="button" class="close" data-dismiss="modal"
                aria-hidden="true">x</button>
        </div>
        <div class="modal-body pntmd-modal-form-ricerca-body">
            <div class="pntmd-modal-form-ricerca-content">
                {{prefix}} <span id="pntmd-form-ricerca-cosa">{{qui_proponi}}.</span><br>
                {{suffix}}
            </div>
        </div>
        <div class="modal-footer pntmd-modal-form-ricerca-footer">
            <button class="btn btn-success pntmd-modal-form-ricerca-button-close" type="button" data-dismiss="modal"
                (click)="dismiss()">Sono nel posto giusto.</button><br>
            <a class="btn btn-info" id="pntmd-modal-form-ricerca-button-proposte" (click)="goToProposte()">Verifico le
                altre sezioni.</a>
        </div>
    </div>
</div>
<div class="container">
    <section class="about-cerca page-header-section page-header-padding bg-white">

        <div class="row">
            <div class="col-lg-12">
                <div class="about-info">
                    <h3 style="text-align: center;display: flex;align-items: center;justify-content: center;">
                        <span style="color:#db2557">
                            <span class="header-flag" style="display: flex;align-items: center;cursor: pointer;">
                                <img width="40" src="/assets/flags/{{nation.code}}.svg" />
                                <span>{{nation.name}}</span>
                            </span>
                        </span>
                    </h3>
                    <h3 style="text-align:center;color:#db2557;margin-top: .5rem;">STAND-BY</h3>

                    <div style="text-align:center">
                        <img src="/assets/img/coming-soon.png" width="400" />
                    </div>

                    <p>
                        <span style="color:#e69138">
                            CINEBOOKING DOES NOT CURRENTLY EXIST IN THIS COUNTRY AND WE WOULD LIKE TO EXPAND OUR ONLINE
                            SERVICE HERE:
                        </span>
                    </p>

                    <p>
                        <span style="color:#1155cc">
                            IF YOU ARE A FILM/TELEVISION PRODUCTION COMPANY, OR AN ORGANIZATION FOR CASTING, EVENTS,
                            EXTRAS, LINE PRODUCER, OR OTHER SECTORS, YOU CAN CONTACT US AT <a href="mailto:info@cinebooking.com">info@cinebooking.com</a> WITH
                            YOUR COUNTRY AS THE SUBJECT. IF YOU WOULD LIKE TO PARTNER WITH US, WE WILL IMMEDIATELY
                            REPLY. THANK YOU
                        </span>
                    </p>

                    <p style="margin: 0;color:#1155cc;font-size: 16px;">
                        Gianfranco De Rosa
                    </p>
                    <p style="color:#1155cc">
                        Cinebooking Founder
                    </p>
                    <p style="color:#1155cc">
                        CINEBOOKING ® IS A REGISTERED WORLD BRAND AND SOFTWARE. 
                    </p>

                </div>
            </div>
        </div>
    </section>
</div>
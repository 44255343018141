import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Contact } from 'src/core/contact.core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ContactService {
  private resource = 'contacts/';
  constructor(
    private http: HttpClient
  ) { }

  insert(contact: Contact) {
    return this.http.post(`${environment.api}${this.resource}insert`, contact);
  }
}

<div class="success" *ngIf="onSuccess">
    <div class="container">
        <div class="row">
            <div class="alert alert-success col-md-7 col-md-push-2 col-sm-8 col-sm-push-2 col-xs-8 col-xs-push-4   alert-custom"
                role="alert">
                <svg class="svg-inline--fa fa-check-circle fa-w-16" style="color: #3bbdb6;" aria-hidden="true"
                    focusable="false" data-prefix="fa" data-icon="check-circle" role="img"
                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
                    <path fill="currentColor"
                        d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z">
                    </path>
                </svg><!-- <i class="fa fa-check-circle" style="color: #3bbdb6;"></i> -->
                <span class="ml-2"> Invia suggerimento con successo</span>

                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
        </div>
    </div>
</div>
<div class="container">
    <section id="bg-image" data-type="background" data-speed="5" style="background: url(drkblue_02.jpg) no-repeat;"
        class="page-header-padding bg-white f-cinebook-section">
        <div class="row">
            <div class="col-lg-8 offset-lg-2 col-md-10 offset-md-1">
                <div class="containt-box">
                    <div
                        class="elementor-element elementor-element-89a5d78 elementor-widget elementor-widget-text-editor">
                        <div class="elementor-widget-container">
                            <div class="elementor-clearfix elementor-text-editor">
                                <h1 style="text-align:center">INVIA I TUOI SUGGERIMENTI</h1>

                                <p>I tuoi suggerimenti per migliorare la piattaforma sono per noi preziosi, indicaci
                                    qualche criticità nel funzionamento o semplicemente un idea integrativa.<br>
                                    Riempi il modulo fornendoci più informazioni possibili. Saremo grati della tua
                                    collaborazione.</p>
                            </div>
                        </div>
                    </div>

                    <div class="form-design">
                        <form role="form" id="sendSuggestion" class="cine-booking-form">
                            <div class="form-row">
                                <div class="form-group col-xs-12 col-sm-12 col-md-12">
                                    <label>Nominativo (richiesto)</label>
                                    <input [(ngModel)]="suggestion.name" type="text" name="name" id="name"
                                        class="form-control input-sm" [required]="true"
                                        [ngModelOptions]="{standalone: true}" #name="ngModel">
                                </div>
                                <div *ngIf="onSubmit && name.invalid ">
                                    <small *ngIf="name.errors?.required" style="color: red;">Il campo non può essere
                                        vuoto.</small>
                                </div>
                            </div>

                            <div class="form-row">
                                <div class="form-group col-xs-12 col-sm-12 col-md-12">
                                    <label>Email (richiesto)</label>
                                    <input [(ngModel)]="suggestion.email" type="email" name="email" id="email"
                                        class="form-control input-sm" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$"
                                        [required]="true" [ngModelOptions]="{standalone: true}" #email="ngModel">
                                </div>
                                <div *ngIf="onSubmit && email.invalid ">
                                    <small *ngIf="email.errors?.required" style="color: red;">Il campo non può essere
                                        vuoto.</small>
                                    <small *ngIf="email.errors?.pattern" style="color: red;">L'email non risulta
                                        valida.</small>
                                </div>
                            </div>

                            <div class="form-row">
                                <div class="form-group col-xs-12 col-sm-12 col-md-12">
                                    <label>Suggerimenti</label>
                                    <textarea [(ngModel)]="suggestion.suggestion" class="form-control input-sm"
                                        name="tips" rows="5" id="" [ngModelOptions]="{standalone: true}"></textarea>
                                </div>
                            </div>

                            <div class="form-row">
                                <div class="form-group col-xs-12">
                                    <input (click)="submit()" type="submit" id="submit" value="INVIA"
                                        class="btn btn-primary btn-block pink-btn">
                                </div>
                            </div>


                        </form>

                    </div>

                </div>
            </div>
        </div>


    </section>
</div>
import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ResetPassword } from 'src/core/reset-password.core';
import { LoadingService } from '../services/loading.service';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  resetPassword = new ResetPassword();

  @ViewChild('field_password') field_password;
  @ViewChild('field_repeat_password') field_repeat_password;

  onSubmit: boolean;
  onSuccess: boolean;
  onFailed: boolean;
  error: string;

  constructor(
    private title: Title,
    private loadingService: LoadingService,
    private userService: UserService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.title.setTitle('My account – Cinebooking');
    this.route.params.subscribe(params => {
      this.resetPassword.token = params.token;
    });
  }

  ngOnInit(): void {
    this.loadingService.start();
    if (this.resetPassword.token) {
      this.loadingService.stop();
      return;
    }
    this.router.navigateByUrl('/my-account');
    this.loadingService.stop();
  }

  notSamePassword() {
    return this.field_password.value !== this.field_repeat_password.value;
  }

  submit() {
    this.onSubmit = true;

    if (this.field_password.invalid || this.field_repeat_password.invalid) {
      return;
    }

    if (this.field_password.value !== this.field_repeat_password.value) {
      return;
    }

    this.loadingService.start();
    this.userService.resetPassword(this.resetPassword).subscribe(() => {
      this.loadingService.stop();
      this.onSuccess = true;
      this.onSubmit = false;
    }, (error) => {
      console.error(error);
      this.error = error.error;
      this.onFailed = true;
      this.loadingService.stop();
    })
  }

  goAccount() {
    this.router.navigateByUrl('/my-account/sing-in');
  }
}

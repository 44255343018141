import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { UserService } from '../services/user.service';
import { WelcomePopupComponent } from '../welcome-popup/welcome-popup.component';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private modal: NgbModal,
    private cookieService: CookieService,
    private userService: UserService,
  ) {
    this.route.queryParams.subscribe((params) => {
      this.userService.mobile_token = params['mbt'];
      this.userService.platform = params['p'];
    })
  }

  ngOnInit(): void {
    const hideWelcome = this.cookieService.get('hide-welcome-popup');
    if (hideWelcome == 'true') {
      return;
    }
    this.userService.isAuthenticated().subscribe((authenticate: boolean) => {
      if (authenticate) {
        return;
      }
      const ref = this.modal.open(WelcomePopupComponent, { backdrop: true, centered: true });
    });
  }

}

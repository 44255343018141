import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Suggestion } from 'src/core/suggestion.core';
import { SuggestionService } from '../services/suggestion.service';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-suggestion-modal',
  templateUrl: './suggestion-modal.component.html',
  styleUrls: ['./suggestion-modal.component.scss']
})
export class SuggestionModalComponent implements OnInit {
  @Input() field: string;
  @Input() label: string;
  suggestion: Suggestion = new Suggestion();

  onSubmit: boolean;
  onSuccess: boolean;
  onFailed: boolean;
  error: string;
  inProgressInsert: boolean;

  constructor(
    private activeModal: NgbActiveModal,
    private suggestionService: SuggestionService,
    private userService: UserService,
  ) { }

  ngOnInit(): void {
    this.suggestion.field = this.field;
    this.suggestion.label = this.label;
  }

  dismiss() {
    this.activeModal.dismiss();
  }

  submit() {
    this.onSubmit = true;
    this.inProgressInsert = true;
    this.suggestion.user = this.userService.getUserId();
    this.suggestionService.insert(this.suggestion).subscribe(() => {
      this.inProgressInsert = false;
      this.onSuccess = true;
    }, (error) => {
      this.onFailed = true;
      this.inProgressInsert = false;
    })
  }
}

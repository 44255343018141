import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-blog-news',
  templateUrl: './blog-news.component.html',
  styleUrls: ['./blog-news.component.scss']
})
export class BlogNewsComponent implements OnInit {

  constructor(
    private title: Title
  ) { 
    this.title.setTitle('News  – Cinebooking');
  }

  ngOnInit(): void {
  }

}

<div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel"> Ricerca <span
            class="badge badge-success rounded-1">{{matchs.length}}</span></h5>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="dismiss()" tabindex="-1">
        <span aria-hidden="true">×</span>
    </button>
</div>
<div class="modal-body my-modal-body" *ngFor="let match of matchs; let i = index">
    <div class="row pntmd_row_listing">
        <a (click)="delete(match, i)" class="col-lg-1 col-sm-1 col-xs-1 modal-body-width-auto p-2"><svg
                class="svg-inline--fa fa-times-circle fa-w-16 text-danger" id="" aria-hidden="true" focusable="false"
                data-prefix="fas" data-icon="times-circle" role="img" xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512" data-fa-i2svg="">
                <path fill="currentColor"
                    d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z">
                </path>
            </svg><!-- <i class="fas fa-times-circle text-danger" id=""></i> --></a>
        <div class="col-lg-7 col-sm-7 col-xs-7 modal-body-width-auto p-2">
            <strong>{{match.name}}</strong><br>{{match.date | date: 'dd/MM/yyyy'}}
            <div style="position:relative"></div>
        </div>
        <div class="col-lg-3 col-sm-3 col-xs-3 modal-body-width-auto mobile-view-size text-right" *ngIf="!isExpires(match)">
            <a (click)="open(match)"
                class="btn btn-outline-secondary pntmd-btn-scheda modal-button-bg modal-button-bg-first">SCHEDA</a>
            <span class="badge custom-badge-warning text-center" *ngIf="!match.is_visited_search && !match.job_sended">Visualizzare</span>
            <span class="badge badge-info text-center"
                *ngIf="match.is_visited_search && !match.job_sended">Visionata</span>
            <span class="badge badge-success text-center" *ngIf="match.job_sended">Candidata</span>
        </div>
        <div class="col-lg-3 col-sm-3 col-xs-3 modal-body-width-auto mobile-view-size text-right" *ngIf="isExpires(match)">
            <a disabled class="btn btn-outline-secondary pntmd-btn-scheda modal-button-bg modal-button-bg-first">SCHEDA</a>
            <span class="badge badge-dark text-center">Scaduta</span>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary modal-button-bg modal-button-bg-close" (click)="dismiss()"
        data-dismiss="modal">Chiudi</button>
</div>
<div class="success" *ngIf="onSuccess">
    <div class="container">
        <div class="row">
            <div class="alert alert-success col-md-7 col-md-push-2 col-sm-8 col-sm-push-2 col-xs-8 col-xs-push-4   alert-custom"
                role="alert">
                <svg class="svg-inline--fa fa-check-circle fa-w-16" style="color: #3bbdb6;" aria-hidden="true"
                    focusable="false" data-prefix="fa" data-icon="check-circle" role="img"
                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
                    <path fill="currentColor"
                        d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z">
                    </path>
                </svg><!-- <i class="fa fa-check-circle" style="color: #3bbdb6;"></i> -->
                <span class="ml-2"> Progetto segnalato con successo</span>

                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
        </div>
    </div>
</div>
<div class="container">
    <section id="bg-image" data-type="background" data-speed="5" style="background: url(drkblue_02.jpg) no-repeat;"
        class="page-header-padding bg-white f-cinebook-section">
        <div class="row">
            <div class="col-lg-8 offset-lg-2 col-md-10 offset-md-1">
                <div class="containt-box">
                    <h1>Segnala un progetto</h1>

                    <p>Segnala un progetto che dovrai realizzare in una provincia che prevedi una scarsa offerta di
                        risorse umane e forniture di cui avrai necessità. Ci attiveremo in anticipo con una specifica
                        attività di marketing affinchè la nostra piattaforma possa offrire tutte le soluzioni in
                        risposta al JOB e FIND Alert che andrai a inviare. E’ preferibile una segnalazione almeno un
                        mese prima della data che inizierai le tue ricerche così da consentire il tempo necessario per
                        il lavoro da eseguire. Cinebooking è un partner attivo per il tuo lavoro.</p>



                    <div class="form-design">
                        <form role="form" id="submitProject" class="cine-booking-form lable-bold">
                            <div class="form-row">
                                <div class="form-group col-xs-12 col-sm-12 col-md-12">
                                    <label>Nominativo (richiesto)</label>
                                    <input [(ngModel)]="project.name" type="text" name="name" id="name"
                                    class="form-control input-sm" [required]="true"
                                    [ngModelOptions]="{standalone: true}" #name="ngModel">
                                </div>
                                <div *ngIf="onSubmit && name.invalid ">
                                    <small *ngIf="name.errors?.required" style="color: red;">Il campo non può essere
                                        vuoto.</small>
                                </div>
                            </div>

                            <div class="form-row">
                                <div class="form-group col-xs-12 col-sm-12 col-md-12">
                                    <label>Email (richiesto)</label>
                                    <input [(ngModel)]="project.email" type="email" name="email" id="email"
                                        class="form-control input-sm" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$"
                                        [required]="true" [ngModelOptions]="{standalone: true}" #email="ngModel">
                                </div>
                                <div *ngIf="onSubmit && email.invalid ">
                                    <small *ngIf="email.errors?.required" style="color: red;">Il campo non può essere
                                        vuoto.</small>
                                    <small *ngIf="email.errors?.pattern" style="color: red;">L'email non risulta
                                        valida.</small>
                                </div>
                            </div>

                            <div class="form-row">
                                <div class="form-group col-xs-12 col-sm-12 col-md-12">
                                    <label>Progetto e Periodo indicativo inizio</label>
                                    <input [(ngModel)]="project.project_and_period_start" type="text" name="project_and_period_start" id="project_and_period_start"
                                    class="form-control input-sm" [required]="true"
                                    [ngModelOptions]="{standalone: true}" #project_and_period_start="ngModel">
                                </div>
                                <div *ngIf="onSubmit && project_and_period_start.invalid ">
                                    <small *ngIf="project_and_period_start.errors?.required" style="color: red;">Il progetto non può essere vuoto.</small>
                                </div>
                            </div>

                            <div class="form-row">
                                <div class="form-group col-xs-12 col-sm-12 col-md-12">
                                    <label>Luogo di lavoro</label>
                                    <input [(ngModel)]="project.work_place" type="text" name="work_place" id="work_place"
                                    class="form-control input-sm" [required]="true"
                                    [ngModelOptions]="{standalone: true}" #work_place="ngModel">
                                </div>
                                <div *ngIf="onSubmit && work_place.invalid ">
                                    <small *ngIf="work_place.errors?.required" style="color: red;">Il luogo di lavoro non può essere vuoto.</small>
                                </div>
                            </div>

                            <div class="form-row">
                                <div class="form-group col-xs-12 col-sm-12 col-md-12">
                                    <label>Segnala le qualifiche e forniture necessarie</label>
                                    <textarea [(ngModel)]="project.qualifications_and_supplies" type="text" name="qualifications_and_supplies" id="qualifications_and_supplies"
                                    class="form-control input-sm" [required]="true" placeholder="Massimo 500 caratteri"
                                    [ngModelOptions]="{standalone: true}" #qualifications_and_supplies="ngModel" maxlength="500"></textarea>
                                </div>
                            </div>

                            <div class="form-row">
                                <div class="form-group col-xs-12">
                                    <input type="submit" id="submit" value="INVIA" (click)="submit()"
                                        class="btn btn-primary btn-block pink-btn">
                                </div>

                            </div>

                        </form>

                    </div>

                </div>
            </div>
        </div>
    </section>
</div>
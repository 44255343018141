import { AfterViewChecked, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { LoadingService } from './services/loading.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewChecked {
  title = 'cinebooking-app';
  inLoading: boolean;

  constructor(
    private loadingService: LoadingService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.loadingService.onChangeLoading.subscribe((inLoading: boolean) => {
      this.inLoading = inLoading;
    });
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }
}

<div class="showbox" *ngIf="inLoading">
    <div class="container container-loading">
        <div class="loader">
            <svg class="circular" viewBox="25 25 50 50">
                <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="2" stroke-miterlimit="10">
                </circle>
            </svg>
        </div>
    </div>
</div>
<app-header></app-header>
<router-outlet></router-outlet>
<app-chat-box></app-chat-box>
<app-footer></app-footer>
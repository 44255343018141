import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Suggestion } from 'src/core/suggestion.core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SuggestionService {
  private resource = 'suggestions/';
  constructor(
    private http: HttpClient
  ) { }

  insert(suggestion: Suggestion) {
    return this.http.post(`${environment.api}${this.resource}insert`, suggestion);
  }
}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'src/guards/auth.guard';
import { BlogNewsComponent } from './blog-news/blog-news.component';
import { BuilderFormsComponent } from './builder-forms/builder-forms.component';
import { CentroAssistenzaComponent } from './centro-assistenza/centro-assistenza.component';
import { ChiSiamoComponent } from './chi-siamo/chi-siamo.component';
import { CodiceEticoComponent } from './codice-etico/codice-etico.component';
import { ComeFunzionaComponent } from './come-funziona/come-funziona.component';
import { ContattaciComponent } from './contattaci/contattaci.component';
import { ConfirmRegistrationComponent } from './confirm-registration/confirm-registration.component';
import { CookieComponent } from './cookie/cookie.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DomandeFrequentiComponent } from './domande-frequenti/domande-frequenti.component';
import { EditAccountComponent } from './edit-account/edit-account.component';
import { EntraNelClubComponent } from './entra-nel-club/entra-nel-club.component';
import { FeedbackComponent } from './feedback/feedback.component';
import { HomePageComponent } from './home-page/home-page.component';
import { InserimentoPropostaComponent } from './inserimento-proposta/inserimento-proposta.component';
import { InserimentoRicercaComponent } from './inserimento-ricerca/inserimento-ricerca.component';
import { LogoutComponent } from './logout/logout.component';
import { LostPasswordComponent } from './lost-password/lost-password.component';
import { MyAccountComponent } from './my-account/my-account.component';
import { MyListingsComponent } from './my-listings/my-listings.component';
import { MySearchComponent } from './my-search/my-search.component';
import { NoteLegaliComponent } from './note-legali/note-legali.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { PreviewFormsComponent } from './preview-forms/preview-forms.component';
import { ProtezioneDatiComponent } from './protezione-dati/protezione-dati.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { SegnalaUnProgettoComponent } from './segnala-un-progetto/segnala-un-progetto.component';
import { SingInComponent } from './sing-in/sing-in.component';
import { StampaComponent } from './stampa/stampa.component';
import { StatusFormsComponent } from './status-forms/status-forms.component';
import { SuggerimentiComponent } from './suggerimenti/suggerimenti.component';
import { TerminiECondizioniComponent } from './termini-e-condizioni/termini-e-condizioni.component';
import { ComingSoonComponent } from './coming-soon/coming-soon.component';
import { DiventaPremiumComponent } from './diventa-premium/diventa-premium.component';
import { AbbonamentoCompletatoComponent } from './abbonamento-completato/abbonamento-completato.component';
import { SubcriptionComponent } from './subcription/subcription.component';
import { DisdiciAbbonamentoComponent } from './disdici-abbonamento/disdici-abbonamento.component';

const routes: Routes = [
    { path: '', component: HomePageComponent },
    { path: 'inserimento-proposta', component: InserimentoPropostaComponent },
    { path: 'inserimento-proposta/:slug', component: BuilderFormsComponent },
    { path: 'inserimento-proposta/edit/:slug/:uuid', component: BuilderFormsComponent, canActivate: [AuthGuard] },
    { path: 'inserimento-proposta/preview/:slug/:uuid', component: PreviewFormsComponent, canActivate: [AuthGuard] },
    { path: 'inserimento-proposta/listing/:slug/:uuid', component: PreviewFormsComponent, canActivate: [AuthGuard] },
    { path: 'inserimento-proposta/status/:slug/:uuid', component: StatusFormsComponent, canActivate: [AuthGuard] },
    { path: 'inserimento-proposta/scheda/:slug/:uuid/:match_uuid', component: PreviewFormsComponent, canActivate: [AuthGuard] },
    { path: 'inserimento-ricerca', component: InserimentoRicercaComponent },
    { path: 'inserimento-ricerca/:slug', component: BuilderFormsComponent },
    { path: 'inserimento-ricerca/edit/:slug/:uuid', component: BuilderFormsComponent, canActivate: [AuthGuard] },
    { path: 'inserimento-ricerca/preview/:slug/:uuid', component: PreviewFormsComponent, canActivate: [AuthGuard] },
    { path: 'inserimento-ricerca/searches/:slug/:uuid', component: PreviewFormsComponent, canActivate: [AuthGuard] },
    { path: 'inserimento-ricerca/scheda/:slug/:uuid/:match_uuid', component: PreviewFormsComponent, canActivate: [AuthGuard] },
    { path: 'inserimento-ricerca/status/:slug/:uuid', component: StatusFormsComponent, canActivate: [AuthGuard] },
    { path: 'searches/:slug/:uuid', component: PreviewFormsComponent, canActivate: [AuthGuard] },
    { path: 'chi-siamo', component: ChiSiamoComponent },
    { path: 'entra-nel-club', component: EntraNelClubComponent },
    { path: 'domande-frequenti', component: DomandeFrequentiComponent },
    { path: 'suggerimenti', component: SuggerimentiComponent },
    { path: 'contattaci', component: ContattaciComponent },
    { path: 'feedback', component: FeedbackComponent },
    { path: 'stampa', component: StampaComponent },
    { path: 'codice-etico', component: CodiceEticoComponent },
    { path: 'news', component: BlogNewsComponent },
    { path: 'centro-assistenza', component: CentroAssistenzaComponent },
    { path: 'segnala-un-progetto', component: SegnalaUnProgettoComponent },
    { path: 'cookie', component: CookieComponent },
    { path: 'termini-e-condizioni', component: TerminiECondizioniComponent },
    { path: 'protezione-dati', component: ProtezioneDatiComponent },
    { path: 'note-legali', component: NoteLegaliComponent },
    { path: 'come-funziona', component: ComeFunzionaComponent },
    { path: 'come-funziona/:popup', component: ComeFunzionaComponent },
    { path: 'coming-soon', component: ComingSoonComponent },
    { path: 'diventa-premium', component: DiventaPremiumComponent },
    { path: 'abbonamento-completato', component: AbbonamentoCompletatoComponent },
    { path: 'disdici-abbonamento', component: DisdiciAbbonamentoComponent, canActivate: [AuthGuard] },
    { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
    { path: 'my-account', component: MyAccountComponent },
    { path: 'my-account/sing-in', component: SingInComponent },
    { path: 'my-account/confirm-registration/:registration_token', component: ConfirmRegistrationComponent },
    { path: 'my-account/logout', component: LogoutComponent },
    { path: 'my-account/lost-password', component: LostPasswordComponent },
    { path: 'my-account/reset-password/:token', component: ResetPasswordComponent },
    { path: 'my-account/edit-account', component: EditAccountComponent, canActivate: [AuthGuard] },
    { path: 'my-account/subscription', component: SubcriptionComponent, canActivate: [AuthGuard] },
    { path: 'my-account/my-listings', component: MyListingsComponent, canActivate: [AuthGuard] },
    { path: 'my-account/my-search', component: MySearchComponent, canActivate: [AuthGuard] },
    { path: '**', pathMatch: 'full', component: PageNotFoundComponent },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }

import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-job-sended',
  templateUrl: './job-sended.component.html',
  styleUrls: ['./job-sended.component.scss']
})
export class JobSendedComponent implements OnInit {
  waNumber: string;
  uuidproposal:string;
  uuidresearch:string;
  slug: string;

  constructor(
    private activeModal: NgbActiveModal
  ) { }

  ngOnInit(): void {
  }

  dismiss() {
    this.activeModal.dismiss();
  }

  sendWa() {
    const slug = this.slug.replace('-cerca', '');
    const text = `Guarda la mia candidutura su cinebooking: ${location.origin}/inserimento-proposta/scheda/${slug}/${this.uuidresearch}/${this.uuidproposal}`;
    const uri = `https://wa.me/${this.waNumber}?text=${text}`;
    window.open(uri, '_blank');
  }
}

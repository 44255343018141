import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { changePassword } from 'src/core/changePassword.core';
import { CountMatch } from 'src/core/count-match.core';
import { User } from 'src/core/user.core';
import { DeleteAccountComponent } from '../delete-account/delete-account.component';
import { LoadingService } from '../services/loading.service';
import { MatchService } from '../services/match.service';
import { UserService } from '../services/user.service';
import { UploadService } from '../upload.service';

@Component({
  selector: 'app-edit-account',
  templateUrl: './edit-account.component.html',
  styleUrls: ['./edit-account.component.scss']
})
export class EditAccountComponent implements OnInit {
  countMatchs: CountMatch = new CountMatch();

  user: User;
  changePasswordModel: changePassword = new changePassword();

  updateUserOnSubmit: boolean;
  updateUserOnSuccess: boolean;
  updateUserOnFailed: boolean;
  updateUserError: string;

  changePasswordOnSubmit: boolean;
  changePasswordOnSuccess: boolean;
  changePasswordOnFailed: boolean;
  changePasswordError: string;

  hide_warning: boolean;

  @ViewChild('user_name') user_name;
  @ViewChild('user_surname') user_surname;
  @ViewChild('user_email') user_email;

  @ViewChild('previous_password') previous_password;
  @ViewChild('new_password') new_password;
  @ViewChild('new_password_repeat') new_password_repeat;

  constructor(
    private userService: UserService,
    private loadingService: LoadingService,
    private uploadService: UploadService,
    private cookieService: CookieService,
    private matchService: MatchService,
    private router: Router,
    private modal: NgbModal
  ) { }

  ngOnInit(): void {
    this.loadingService.start();
    this.matchService.count();
    this.hide_warning = this.cookieService.get('hide_warning_account') == 'true';
    this.userService.getUser().subscribe((user: User) => {
      this.user = user;
      this.loadingService.stop();
      console.info(user);
    });
    this.matchService.countMatchs.subscribe((count: CountMatch) => {
      if(!count) {
        return;
      }
      this.countMatchs = count;
    });
  }

  onChangeProfile(event) {
    const files = Array.from(event.target.files) as File[];
    this.uploadService.upload(files).subscribe((addresses: string[]) => {
      this.user.user_profile = addresses[0];
    });
  }

  submit() {
    this.updateUserOnSubmit = true;
    if (this.user_name.invalid || this.user_surname.invalid || this.user_email.invalid) {
      return;
    }
    this.loadingService.start();
    this.userService.updateUser(this.user).subscribe(() => {
      this.loadingService.stop();
      this.updateUserOnSuccess = true;
      this.updateUserOnSubmit = false;
      this.cookieService.set('user_profile', this.user.user_profile);
      this.userService.onUpdateUser.emit();
    }, (error) => {
      console.error(error);
      this.updateUserOnFailed = true;
      this.loadingService.stop();
    });
  }

  changePassword() {
    this.changePasswordOnSubmit = true;
    this.changePasswordOnFailed = false;

    if (this.previous_password.invalid || this.new_password.invalid || this.new_password_repeat.invalid) {
      return;
    }
    if (this.new_password.invalid !== this.new_password_repeat.invalid) {
      return;
    }
    this.loadingService.start();
    this.changePasswordModel._id = this.userService.getUserId();
    this.userService.changePassword(this.changePasswordModel).subscribe(() => {
      this.loadingService.stop();
      this.changePasswordOnSuccess = true;
      this.changePasswordOnSubmit = false;
      this.changePasswordModel = new changePassword();
    }, (error) => {
      console.error(error);
      this.changePasswordOnFailed = true;
      this.changePasswordError = error.error;
      this.loadingService.stop();
    });
  }

  deleteAccount() {
    const account = this.modal.open(DeleteAccountComponent, { centered: true });
    account.componentInstance.onDeleteAccount.subscribe(() => {
      this.loadingService.start();
      this.userService.deleteAccount().subscribe(() => {
        this.loadingService.stop();
        account.close();
        alert('Account rimosso con successo');
        this.router.navigateByUrl('/my-account/logout');
      }, (error) => {
        console.error(error);
        this.loadingService.stop();
      })
    });
  }

  setHideWarning() {
    this.hide_warning = true;
    this.cookieService.set('hide_warning_account', 'true');
  }

}

import { Component, OnInit } from '@angular/core';
import { UserService } from '../services/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SingInPopupComponent } from '../sing-in-popup/sing-in-popup.component';
import { LoadingService } from '../services/loading.service';
import { User } from 'src/core/user.core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-diventa-premium',
  templateUrl: './diventa-premium.component.html',
  styleUrls: ['./diventa-premium.component.scss']
})
export class DiventaPremiumComponent implements OnInit {
  authenticated: boolean;
  user: User;
  user_id: string;
  user_email: string;

  overLimit: boolean = false;
  subscribeYet: boolean;


  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private loadingService: LoadingService,
    private modal: NgbModal,
  ) {
    this.route.queryParams.subscribe((params) => {
      this.overLimit = params['limit'] == 'true';
    })
  }

  ngOnInit(): void {
    this.loadingService.start();
    this.userService.isAuthenticated().subscribe((authenticated: boolean) => {
      this.authenticated = authenticated;
      if (!this.authenticated) {
        this.loadingService.stop();
        return;
      }
      this.getUser();
    });
    this.userService.onAutentichate.subscribe(() => {
      this.getUser();
    })
  }

  getUser() {
    this.loadingService.start();
    this.userService.getUser().subscribe((user: User) => {
      this.user = user;
      this.user_id = user._id.toString();
      this.user_email = user.user_email;
      this.loadingService.stop();
    })
  }

  mensile() {
    if (!this.authenticated) {
      this.openSingin();
      return;
    }
    if (this.hasSubscription()) {
      this.subscribeYet = true;
      return;
    }
    location.replace(`${environment.paymentLinkMensile}?prefilled_email=${this.user_email}&client_reference_id=${this.user_id}`);
  }

  annuale() {
    if (!this.authenticated) {
      this.openSingin();
      return;
    }
    if (this.hasSubscription()) {
      this.subscribeYet = true;
      return;
    }
    location.replace(`${environment.paymentLinkAnnuale}?prefilled_email=${this.user_email}&client_reference_id=${this.user_id}`);
  }

  hasSubscription() {
    if (!this.user || !this.user.subscription || !this.user.subscription._id) {
      return false;
    }
    if (new Date(this.user.subscription.end) < new Date()) {
      return false;
    }
    return true;
  }

  openSingin() {
    const singinPopup = this.modal.open(SingInPopupComponent, { centered: true });
  }

}

<div class="container">
    <section class="about-cerca page-header-section page-header-padding bg-white">

        <div class="row">
            <div class="col-lg-12">
                <div class="about-info">
                    <h2 style="text-align:center">COMPLIMENTI SEI UN NOSTRO PARTNER</h2>
                    <br />
                    

                    <div class="pntmd-form-heading-text-1 pntmd-hidden-mobile">
                        <p style="font-size: 16px;margin-bottom: 1rem;">Ciao, sono Gianfranco De Rosa, Executive Producer di Film e Spot
                        pubblicitari, founder di Cinebooking e autore del libro autobiografico “Ho due palle per raccomandazione”. Ti
                        ringrazio per essere entrato nel nostro gruppo e per un eventuale collaborazione lavorativa su tanti potenziali
                        progetti che il mercato offrirà.</p>
                    
                        <p style="font-size: 16px;">Cinebooking è una piattaforma metamotore di ricerca nata nel 2018 e lanciata in occasione della Festa del Cinema di
                        Roma dove insieme a me erano presenti Carlo Verdone (Regista) e Marco Belardi (Produttore). In quell’occasione
                        abbiamo informato l’utenza presente del potenziale aiuto a trovare lavoro offerto da Cinebooking.</p>
                    
                        <p style="font-size: 16px;">In questo video puoi visionare l’evento:<a href="https://www.youtube.com/watch?v=le5LO6QFokc&t=28s" target="_blank">https://www.youtube.com/watch?v=le5LO6QFokc&t=28s</a></p>
                    
                        <p style="font-size: 16px;">Cinebooking ti invierà quasi la totalità delle opportunità di lavoro presenti sul mercato dell’industria
                        dell’entertainment del Cinema, Televisione, Pubblicità, Eventi, Radio, Moda, Musica, Teatro.
                        Certamente, è indispensabile seguire bene le istruzioni presenti nelle schede secondo le modalità di candidatura
                        richieste.</p>
                    
                        <p style="font-size: 16px;">Il nostro staff è felice di aiutarti ad avere successo e una straordinaria carriera.
                        Ti faccio i miei migliori auguri per il tuo lavoro in Cinebooking.</p>
                    
                        <p style="font-size: 16px;margin-bottom: 0;">Con stima.</p>
                        <p style="font-size: 16px;margin-bottom: 0;">Gianfranco De Rosa</p>
                        <p style="font-size: 16px;margin-bottom: 0;">Produttore e CEO</p>
                        <p style="font-size: 16px;margin-bottom: 0;">Cinebooking Italia Srl.</p>
                    
                    </div>
                    <br />
                    <br />

                </div>
            </div>
        </div>
    </section>
</div>
import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Suggestion } from 'src/core/suggestion.core';
import { LoadingService } from '../services/loading.service';
import { SuggestionService } from '../services/suggestion.service';

@Component({
  selector: 'app-suggerimenti',
  templateUrl: './suggerimenti.component.html',
  styleUrls: ['./suggerimenti.component.scss']
})
export class SuggerimentiComponent implements OnInit {
  suggestion: Suggestion = new Suggestion();
  onSubmit: boolean;
  onSuccess: boolean;
  onFailed: boolean;
  error: string;

  @ViewChild('name') name;
  @ViewChild('email') email;

  constructor(
    private suggestionService: SuggestionService,
    private loadingService: LoadingService,
    private title: Title
  ) { 
    this.title.setTitle('Suggerimenti – Cinebooking');
  }

  ngOnInit(): void {
  }

  submit() {
    this.onSubmit = true;

    if(this.name.invalid || this.email.invalid) {
      return;
    }

    this.loadingService.start();
    this.suggestionService.insert(this.suggestion).subscribe(() => {
      this.loadingService.stop();
      this.onSuccess = true;
      this.suggestion = new Suggestion();
      this.onSubmit = false;
    }, (error) => {
      console.error(error);
      this.onFailed = true;
      this.loadingService.stop();
    })
  }

}

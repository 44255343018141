import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoadingService } from '../services/loading.service';
import { UserService } from '../services/user.service';
import { SingInPopupComponent } from '../sing-in-popup/sing-in-popup.component';

@Component({
  selector: 'app-confirm-registration',
  templateUrl: './confirm-registration.component.html',
  styleUrls: ['./confirm-registration.component.scss']
})
export class ConfirmRegistrationComponent implements OnInit {
  registration_token: string;
  error: boolean;
  errorMsg: string;

  success: boolean;

  constructor(
    private route: ActivatedRoute,
    private modal: NgbModal,
    private loadingService: LoadingService,
    private userService: UserService,
  ) {
    this.route.params.subscribe(params => {
      this.registration_token = params.registration_token;
    });
  }

  ngOnInit(): void {
    this.loadingService.start();
    if (!this.registration_token) {
      this.error = true;
      this.errorMsg = `Si è verificato un errore durante la conferma dell'account`;
      this.loadingService.stop();
      return;
    }
    this.userService.confirmAccount(this.registration_token).subscribe(() => {
      this.success = true;
      this.loadingService.stop();
    }, (error) => {
      this.error = true;
      this.errorMsg = error.error;
      console.info(error);
      this.loadingService.stop();
    })
  }

  openSingin() {
    const singinPopup = this.modal.open(SingInPopupComponent, { centered: true });
  }

}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Feedback } from 'src/core/feedback.core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FeedbackService {
  private resource = 'feedbacks/';
  constructor(
    private http: HttpClient
  ) { }

  insert(feedback: Feedback) {
    return this.http.post(`${environment.api}${this.resource}insert`, feedback);
  }
}

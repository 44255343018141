<div class="container">

<nav class="navbar fixed-top" id="mainNav">
    <a class="navbar-brand js-scroll-trigger" routerLink="/">
        <img alt="logo" src="/assets/img/cinebooking_logo.png">
    </a>
    <div class="nav-right">
        <div style="position: relative;">
            <span class="header-flag" style="display: flex;align-items: center;cursor: pointer;"
                (click)="toogleChooseNation()">
                <img src="/assets/flags/{{nation.code}}.svg" />
                <span style="margin-left: .5rem">{{nation.name}} - {{nation.code}}</span>
            </span>
    
            <ul class="dropdown-menu dropdown-menu-flags" *ngIf="chooseNation">
                <li>
                    <div class="dropdown-item" *ngFor="let nation of nations">
                        <span class="header-flag" style="display: flex;align-items: center;cursor: pointer;"
                            (click)="changeNation(nation.code)">
                        <img src="/assets/flags/{{nation.code}}.svg" />
                        <span style="margin-left: .5rem">{{nation.name}}</span>
                    </span>
                </div>
                </li>
            </ul>
        </div>

        <button style="position: relative;" class="navbar-toggler navbar-toggler-right" (click)="toogleMenu()" id="menu-toggle-button" type="button">
            <div *ngIf="!autentichate">
            <a class="nav-button ml-auto">
                <span id="nav-icon3">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </span>
            </a>
            <div id="navbarResponsive" *ngIf="menu">
                <ul class="navbar-nav ml-auto my-lg-0">
                    <h3 class="menu-title">MENU</h3>
                    <li class="nav-item"><a class="nav-link js-scroll-trigger"
                        (click)="openSingin()">ACCEDI
                        <svg class="svg-inline--fa fa-angle-right fa-w-8" aria-hidden="true" focusable="false"
                            data-prefix="fas" data-icon="angle-right" role="img" xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 256 512" data-fa-i2svg="">
                            <path fill="currentColor"
                                d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z">
                            </path>
                        </svg></a></li>
                    <li class="nav-item"><a class="nav-link js-scroll-trigger separator"
                        routerLink="/my-account">ISCRIVITI
                        <svg class="svg-inline--fa fa-angle-right fa-w-8" aria-hidden="true" focusable="false"
                            data-prefix="fas" data-icon="angle-right" role="img" xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 256 512" data-fa-i2svg="">
                            <path fill="currentColor"
                                d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z">
                            </path>
                        </svg></a></li>
                    <li class="nav-item"><a class="nav-link js-scroll-trigger"
                        routerLink="/inserimento-ricerca">CERCA</a></li>
                    <li class="nav-item"><a class="nav-link js-scroll-trigger separator"
                        routerLink="/inserimento-proposta">PROPONI</a></li>
                    <!-- <li class="nav-item"><a class="nav-link js-scroll-trigger"
                        routerLink="/come-funziona/che-cosa-e">CHE COS’È</a></li> -->
                    <li class="nav-item"><a class="nav-link js-scroll-trigger"
                        routerLink="/contattaci">CONTATTACI</a></li>
                    <li class="nav-item"><a class="nav-link js-scroll-trigger"
                        routerLink="/come-funziona">COME FUNZIONA</a></li>
                </ul>
            </div>
            </div>
        </button>
     

            <div class="dropdown" *ngIf="autentichate">

                <a (click)="toogleDropdownMenu()" class="avtar-header dropdown-toggle d-block d-sm-block d-md-block" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="cursor: pointer;">
                                           <!-- <img *ngIf="!user_profile" alt="" src="/assets/img/avatar.jpg"> 
                                           <img *ngIf="user_profile" alt="" src="{{user_profile}}">  -->
                                           {{user}}
                                           <a class="nav-button ml-auto">
                                            <span id="nav-icon3">
                                                <span></span>
                                                <span></span>
                                                <span></span>
                                                <span></span>
                                            </span>
                                        </a>

                </a>

                <div class="dropdown-menu" style="display: block!important;" aria-labelledby="dropdownMenuButton" *ngIf="dropdownmenu">
                    <div class="dropdown-header">
                        <p>Utente ID: {{user_id}}</p>
                        <div class="star color-yellow" style="display: none;">
                            <svg class="svg-inline--fa fa-star fa-w-18 checked" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="star" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" data-fa-i2svg=""><path fill="currentColor" d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z"></path></svg><!-- <i class="fas fa-star checked"></i> -->
                            <svg class="svg-inline--fa fa-star-half-alt fa-w-17" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="star-half-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 536 512" data-fa-i2svg=""><path fill="currentColor" d="M508.55 171.51L362.18 150.2 296.77 17.81C290.89 5.98 279.42 0 267.95 0c-11.4 0-22.79 5.9-28.69 17.81l-65.43 132.38-146.38 21.29c-26.25 3.8-36.77 36.09-17.74 54.59l105.89 103-25.06 145.48C86.98 495.33 103.57 512 122.15 512c4.93 0 10-1.17 14.87-3.75l130.95-68.68 130.94 68.7c4.86 2.55 9.92 3.71 14.83 3.71 18.6 0 35.22-16.61 31.66-37.4l-25.03-145.49 105.91-102.98c19.04-18.5 8.52-50.8-17.73-54.6zm-121.74 123.2l-18.12 17.62 4.28 24.88 19.52 113.45-102.13-53.59-22.38-11.74.03-317.19 51.03 103.29 11.18 22.63 25.01 3.64 114.23 16.63-82.65 80.38z"></path></svg><!-- <i class="fas fa-star-half-alt"></i> -->
                            <svg class="svg-inline--fa fa-star fa-w-18" aria-hidden="true" focusable="false" data-prefix="far" data-icon="star" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" data-fa-i2svg=""><path fill="currentColor" d="M528.1 171.5L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6zM388.6 312.3l23.7 138.4L288 385.4l-124.3 65.3 23.7-138.4-100.6-98 139-20.2 62.2-126 62.2 126 139 20.2-100.6 98z"></path></svg><!-- <i class="far fa-star"></i> -->
                            <svg class="svg-inline--fa fa-star fa-w-18" aria-hidden="true" focusable="false" data-prefix="far" data-icon="star" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" data-fa-i2svg=""><path fill="currentColor" d="M528.1 171.5L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6zM388.6 312.3l23.7 138.4L288 385.4l-124.3 65.3 23.7-138.4-100.6-98 139-20.2 62.2-126 62.2 126 139 20.2-100.6 98z"></path></svg><!-- <i class="far fa-star"></i> -->
                            <svg class="svg-inline--fa fa-star fa-w-18" aria-hidden="true" focusable="false" data-prefix="far" data-icon="star" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" data-fa-i2svg=""><path fill="currentColor" d="M528.1 171.5L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6zM388.6 312.3l23.7 138.4L288 385.4l-124.3 65.3 23.7-138.4-100.6-98 139-20.2 62.2-126 62.2 126 139 20.2-100.6 98z"></path></svg><!-- <i class="far fa-star"></i> -->
                            <span class="color-white">(01)</span>
                        </div>
                    </div>
                    <div class="d-menu">
                        <ul>
                            <li><a class="dropdown-item" (click)="navigate('/inserimento-ricerca')"> CERCA</a></li>
                            <li><a class="dropdown-item" (click)="navigate('/inserimento-proposta')"> PROPONI</a></li>
                            <!-- <li><a class="dropdown-item" (click)="navigate('/come-funziona/che-cosa-e')"> CHE COS'È</a></li> -->
                            <!-- <li><a class="dropdown-item" (click)="navigate('/entra-nel-club')"> ENTRA NEL CLUB</a></li> -->
                            <li><a class="dropdown-item" (click)="navigate('/contattaci')">CONTATTACI</a></li>
                            <li><a class="dropdown-item" (click)="navigate('/come-funziona')"> COME FUNZIONA</a></li>
                            <li>
                                <a class="dropdown-item" (click)="navigate('/my-account/my-listings')">
                                    PROPOSTE
                                    <div *ngIf="countMatchs.proposal_matchs !== 0" class="badge badge-secondary"> <svg
                                            class="svg-inline--fa fa-envelope fa-w-16" aria-hidden="true" focusable="false" data-prefix="fa"
                                            data-icon="envelope" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"
                                            data-fa-i2svg="">
                                            <path fill="currentColor"
                                                d="M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z">
                                            </path>
                                        </svg>
                                        <span style="margin-left: 5px;">{{countMatchs.proposal_matchs}}</span>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a class="dropdown-item" (click)="navigate('/my-account/my-search')">
                                    RICERCHE
                                    <div *ngIf="countMatchs.search_matchs !== 0" class="badge badge-success"> <svg
                                            class="svg-inline--fa fa-envelope fa-w-16" aria-hidden="true" focusable="false" data-prefix="fa"
                                            data-icon="envelope" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"
                                            data-fa-i2svg="">
                                            <path fill="currentColor"
                                                d="M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z">
                                            </path>
                                        </svg>
                                        <span style="margin-left: 5px;">{{countMatchs.search_matchs}}</span>
                                    </div>
                                </a>
                            </li>
                            <li><a class="dropdown-item" (click)="navigate('/diventa-premium')"> PREMIUM</a></li>
                            <li><a class="dropdown-item" (click)="navigate('/my-account/logout')"> Logout</a></li>
                            <li (mouseenter)="openSubMenu()"  (mouseleave)="closeSubMenu()">
                                <a class="dropdown-item">Altre info</a>
                                <ul *ngIf="subMenu">
                                    <li><a class="dropdown-item" (click)="navigate('/my-account/edit-account')">Account</a></li>
                                    <li><a class="dropdown-item" >Scarica l'app</a></li>
                                    <li><a class="dropdown-item" (click)="navigate('/feedback')">Segnalazione</a></li>
                                </ul>
                            </li>

                          
                        </ul>
                    </div>

                </div>
            </div>
    </div>


</nav>
</div>
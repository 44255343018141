<div class="success" *ngIf="onSuccess">
    <div class="container">
        <div class="row">
            <div class="alert alert-success col-md-7 col-md-push-2 col-sm-8 col-sm-push-2 col-xs-8 col-xs-push-4   alert-custom"
                role="alert">
                <svg class="svg-inline--fa fa-check-circle fa-w-16" style="color: #3bbdb6;" aria-hidden="true"
                    focusable="false" data-prefix="fa" data-icon="check-circle" role="img"
                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
                    <path fill="currentColor"
                        d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z">
                    </path>
                </svg><!-- <i class="fa fa-check-circle" style="color: #3bbdb6;"></i> -->
                <span class="ml-2"> Invia richiesta di assistenza con successo</span>

                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
        </div>
    </div>
</div>
<div class="container">
    <section id="bg-image" data-type="background" data-speed="5" style="background: url(green_2.jpg) no-repeat;"
        class="page-header-padding bg-white f-cinebook-section parallax-bg">
        <div class="row">
            <div class="col-lg-8 offset-lg-2 col-md-10 offset-md-1">
                <div class="containt-box">
                    <h1>Centro assistenza</h1>

                    <p>Per qualsiasi tipo di problema riscontrato, non esitare a contattarci descrivendo
                        dettagliatamente le problematiche che ti hanno reso difficile l’utilizzo della piattaforma.</p>

                    <p>Vai alla sezione <strong><a routerLink="/suggerimenti"><span
                                    style="color:#db2557">suggerimenti</span></a> </strong>se vuoi invece aiutarci a
                        migliorare condividendo con noi i tuoi preziosi consigli.</p>

                    <div class="form-design">
                        <form role="form" id="helpCenters" class="cine-booking-form">
                            <div class="form-row">
                                <div class="form-group col-xs-12 col-sm-12 col-md-12">
                                    <label>Nominativo (richiesto)</label>
                                    <input [(ngModel)]="assistance.name" type="text" name="name" id="name"
                                        class="form-control input-sm" [required]="true"
                                        [ngModelOptions]="{standalone: true}" #name="ngModel"
                                        [ngClass]="{'text-red': onSubmit &&  name.invalid}">
                                    <div *ngIf="onSubmit && name.invalid ">
                                        <small *ngIf="name.errors?.required" style="color: red;">Il campo non può essere
                                            vuoto.</small>
                                    </div>
                                </div>

                            </div>

                            <div class="form-row">
                                <div class="form-group col-xs-12 col-sm-12 col-md-12">
                                    <label>Email (richiesto)</label>
                                    <input [(ngModel)]="assistance.email" type="email" name="email" id="email"
                                        class="form-control input-sm" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$"
                                        [required]="true" [ngModelOptions]="{standalone: true}" #email="ngModel"
                                        [ngClass]="{'text-red': onSubmit && email.invalid}">
                                    <div *ngIf="onSubmit && email.invalid ">
                                        <small *ngIf="email.errors?.required" style="color: red;">Il campo non può
                                            essere
                                            vuoto.</small>
                                        <small *ngIf="email.errors?.pattern" style="color: red;">L'email non risulta
                                            valida.</small>
                                    </div>
                                </div>

                            </div>

                            <div class="form-row">
                                <div class="form-group col-xs-12 col-sm-12 col-md-12">
                                    <label>Telefono</label>
                                    <input [(ngModel)]="assistance.phone" type="tel" name="phone" id="phone"
                                        class="form-control input-sm" [required]="true"
                                        [ngModelOptions]="{standalone: true}" #phone="ngModel"
                                        [ngClass]="{'text-red': onSubmit &&  phone.invalid}">
                                    <div *ngIf="onSubmit && phone.invalid ">
                                        <small *ngIf="phone.errors?.required" style="color: red;">Il campo non può
                                            essere
                                            vuoto.</small>
                                    </div>
                                </div>

                            </div>

                            <div class="form-row">
                                <div class="form-group col-xs-12 col-sm-12 col-md-12">
                                    <label>Lascia la tua richiesta di assistenza</label>
                                    <textarea [(ngModel)]="assistance.message" name="message" id="message"
                                    class="form-control input-sm" [required]="true"
                                    [ngModelOptions]="{standalone: true}" #message="ngModel"
                                    [ngClass]="{'text-red': onSubmit &&  message.invalid}"></textarea>
                                    <div *ngIf="onSubmit && message.invalid ">
                                        <small *ngIf="message.errors?.required" style="color: red;">Il campo non può
                                            essere vuoto.</small>
                                    </div>
                                </div>
                            </div>

                            <div class="form-row">
                                <div class="form-group col-xs-12">
                                    <input type="submit" id="submit" value="Invia richiesta" (click)="submit()"
                                        class="btn btn-primary btn-block pink-btn text-uppercase">
                                </div>
                            </div>

                        </form>

                    </div>

                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
            </div>
        </div>
    </section>
</div>
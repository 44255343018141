<div class="container">
    <section class="page-header-padding bg-white account-page edit-account">
        <div class="row">
            <div class="col-md-12 col-lg-2 col-xl-2">
                <div class="account-tab account-tab-hide">
                    <ul class="list-group">
                        <li class="list-group-item">
                            <a routerLink="/my-account/edit-account">
                                <svg class="svg-inline--fa fa-user fa-w-14 d-block d-lg-none account-icon" aria-hidden="true" focusable="false" data-prefix="far" data-icon="user" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg=""><path fill="currentColor" d="M313.6 304c-28.7 0-42.5 16-89.6 16-47.1 0-60.8-16-89.6-16C60.2 304 0 364.2 0 438.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-25.6c0-74.2-60.2-134.4-134.4-134.4zM400 464H48v-25.6c0-47.6 38.8-86.4 86.4-86.4 14.6 0 38.3 16 89.6 16 51.7 0 74.9-16 89.6-16 47.6 0 86.4 38.8 86.4 86.4V464zM224 288c79.5 0 144-64.5 144-144S303.5 0 224 0 80 64.5 80 144s64.5 144 144 144zm0-240c52.9 0 96 43.1 96 96s-43.1 96-96 96-96-43.1-96-96 43.1-96 96-96z"></path></svg><!-- <i class="far fa-user d-block d-lg-none account-icon"></i> -->
                                <span class="d-none d-lg-block">Account</span>
                            </a>
                        </li>

                        <li class="list-group-item"><a routerLink="/my-account/my-listings">Proposte </a></li>
                        <li class="list-group-item"><a routerLink="/my-account/my-search">Ricerche   </a></li>
                        <li class="list-group-item"><a routerLink="/my-account/logout">Logout</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-md-12 col-lg-10 col-xl-10">

                <div class="form-design">
                    <div class="account-form-box">


                        <div class="account-form-title color-pink">
                                <span class="rounded-circle">
                                    <svg class="svg-inline--fa fa-home fa-w-18 color-white" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="home" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" data-fa-i2svg=""><path fill="currentColor" d="M280.37 148.26L96 300.11V464a16 16 0 0 0 16 16l112.06-.29a16 16 0 0 0 15.92-16V368a16 16 0 0 1 16-16h64a16 16 0 0 1 16 16v95.64a16 16 0 0 0 16 16.05L464 480a16 16 0 0 0 16-16V300L295.67 148.26a12.19 12.19 0 0 0-15.3 0zM571.6 251.47L488 182.56V44.05a12 12 0 0 0-12-12h-56a12 12 0 0 0-12 12v72.61L318.47 43a48 48 0 0 0-61 0L4.34 251.47a12 12 0 0 0-1.6 16.9l25.5 31A12 12 0 0 0 45.15 301l235.22-193.74a12.19 12.19 0 0 1 15.3 0L530.9 301a12 12 0 0 0 16.9-1.6l25.5-31a12 12 0 0 0-1.7-16.93z"></path></svg><!-- <i class="fas fa-home color-white"></i> -->
                                </span>
                            <h5>Dashboard</h5>
                        </div>

                        <p class="fs-14">
                            Hello <span class="color-black font-weight-bold">{{user}}</span><br>
                            Prosegui adesso con la tua <span class="color-nblue font-weight-medium">PROPOSTA</span> al tasto blu o esegui una <span class="color-green font-weight-bold"> RICERCA </span> al tasto verde.
                        </p>

                        <div class="text-center dashboard-btn">
                            <a class="btn btn-primary btn-nblue text-uppercase" routerLink="/inserimento-proposta">
                                Clicca qui per Proporti o Proporre
                                <svg class="svg-inline--fa fa-angle-right fa-w-8" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512" data-fa-i2svg=""><path fill="currentColor" d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z"></path></svg><!-- <i class="fas fa-angle-right"></i> -->
                            </a>
                            <a class="btn btn-primary btn-green ml-2 text-uppercase" routerLink="/inserimento-ricerca">
                                Clicca qui per eseguire una ricerca
                                <svg class="svg-inline--fa fa-angle-right fa-w-8" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512" data-fa-i2svg=""><path fill="currentColor" d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z"></path></svg><!-- <i class="fas fa-angle-right"></i> -->
                            </a>
                        </div>
                        <p class="fs-14">
                            Dalla dashboard del tuo account puoi visualizzare le tue
                            <a class="link-nblue font-weight-bold" routerLink="/my-account/my-listings">proposte</a>, visualizzare i criteri delle tue
                            <a class="link-nblue font-weight-bold" routerLink="/my-account/my-search">ricerche</a> e modificare i dettagli del tuo
                            <a class="link-nblue font-weight-bold" routerLink="/my-account/edit-account">account</a>.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>
import { Component, OnInit, ViewChild } from '@angular/core';
import { Singin, Singup } from 'src/core/user.core';
import { UserService } from '../services/user.service';
import { CookieService } from 'ngx-cookie-service';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingService } from '../services/loading.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss']
})
export class MyAccountComponent implements OnInit {
  singupForm = new Singup();
  singinForm = new Singin();

  onSingInError: boolean;
  onSingUpError: boolean;
  onSingUpMsg: string;

  singInError: string;
  singUpError: string;

  @ViewChild('singin_mail') singin_mail;
  @ViewChild('singin_password') singin_password;

  @ViewChild('singup_mail') singup_mail;
  @ViewChild('singup_password') singup_password;
  @ViewChild('singup_user_name') singup_user_name;
  @ViewChild('singup_user_surname') singup_user_surname;
  @ViewChild('singup_confirm_email') singup_confirm_email;

  constructor(
    private userService: UserService,
    private cookieService: CookieService,
    private loadingService: LoadingService,
    private router: Router,
    private route: ActivatedRoute,
    private title: Title
  ) {
    this.title.setTitle('My account – Cinebooking');
  }

  ngOnInit(): void {
  }

  singup() {
    console.info(this.singupForm);
    this.singupForm.onSubmit = true;

    if (this.singup_mail.invalid || this.singup_password.invalid || this.singup_user_name.invalid || this.singup_user_surname.invalid) {
      return;
    }
    if (!this.singupForm.consentTermsConditionsUse) {
      return;
    }
    if(this.singupForm.user_email !== this.singupForm.confirm_email) {
      this.onSingUpError = true;
      this.singUpError = 'I due indirizzi email non coincidono';
      return;
    }

    this.loadingService.start();
    this.userService.singup(this.singupForm).subscribe((user: any) => {
      this.onSingUpMsg = `Ti è stata inviata un'email all'indirizzo inserito, conferma la registrazione cliccando il link contenuto all'interno dell'email.`;
      this.loadingService.stop();
    }, (error) => {
      this.loadingService.stop();
      this.onSingUpError = true;
      this.singUpError = error.error;
      console.info(error);
    })
  }

  singin() {
    this.singinForm.onSubmit = true;

    if (this.singin_mail.invalid || this.singin_password.invalid) {
      return;
    }

    this.loadingService.start();
    this.userService.singin(this.singinForm).subscribe((user: any) => {
      this.cookieService.set('user_id', user.id, undefined, undefined, undefined, true, "None");
      this.cookieService.set('token', user.token, undefined, undefined, undefined, true, "None");
      this.cookieService.set('user_profile', user.profile, undefined, undefined, undefined, true, "None");
      this.loadingService.stop();
      this.userService.onAutentichate.emit();
      this.router.navigateByUrl('/dashboard');
    }, (error) => {
      this.loadingService.stop();
      this.onSingInError = true;
      this.singInError = error.error;
      console.info(error);
    })
  }

}

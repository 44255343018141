<div class="modal-content" style="margin: 0">
    <div id="pntmd-modal-form-ricerca">
        <div class="modal-content" style="margin-top: 0!important;background: #fff;">
            <div class="modal-header">
                <h4 class="modal-title" style="color: black;margin: 0;width: 100%;">COME FUNZIONA</h4>
            </div>
            <div class="modal-body pntmd-modal-form-ricerca-body">
                <div class="pntmd-modal-form-ricerca-content" style="color: black;">Su Cinebooking non troverai annunci
                    con offerte di lavoro ne' profili di candidati, riceverai le offerte di lavoro solo dopo aver
                    compilato e candidato la tua scheda con i dati necessari, foto e info al tasto <b
                        style="color:  #1a246a;">PROPONI</b>.<br><br>
                    Lo stesso se stai cercando un collaboratore e/o fornitura in questo caso al tasto <b
                        style="color: #4ea53f;">CERCA.</b>
                    <br><br>
                    Le schede non saranno pubbliche on-line, ma visibili solo al recruiter a cui ti sei candidato per il lavoro.
                    <br><br>
                    Il servizio è gratuito solo per alcuni match lavoro, per poterli visionare tutti è necessario un abbonamento premium.
                    <span id="pntmd-form-ricerca-fare"></span>
                </div>
            </div>
            <div class="modal-footer pntmd-modal-form-ricerca-footer">
                <button type="button" data-dismiss="modal" class="btn btn-success pntmd-modal-form-ricerca-button-close"
                    (click)="close()">Ho capito,
                    prosegui</button>
                <div style="width: 100%;display: flex;align-items: center;justify-content: center;">
                    <input type="checkbox" [(ngModel)]="notAskAgain" (change)="onChangeNotAskAgain()"
                        id="not-ask-again">
                    <label for="not-ask-again" style="color: #000;margin-left: 10px;margin-bottom: 0px;">Non
                        ricordarmelo più</label>
                </div>

            </div>
        </div>
    </div>
</div>
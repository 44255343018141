<div class="container">
    <section class="page-header-padding bg-white account-page">
        <div class="row" style="display: flex;align-items: center;justify-content: center;">

            <div class="alert alert-danger alert-block" *ngIf="error" style="top: 0!important;text-align: center;">
                <div>{{errorMsg}}</div>
            </div>

            <div class="alert alert-success alert-block" *ngIf="success" style="top: 0!important;text-align: center;">
                <div>Account confermato con successo</div>
                <a class="fake-link" style="color: #007bff;cursor: pointer;" (click)="openSingin()">Clicca qui per accedere al tuo account</a>
            </div>
        </div>
    </section>
</div>
<div class="container">

    <section id="bg-image" data-type="background" data-speed="5" style="background: url(green_2.jpg) no-repeat;"
        class="page-header-padding bg-white f-cinebook-section parallax-bg">
        <div class="row">
            <div class="col-lg-8 offset-lg-2 col-md-10 offset-md-1">
                <div class="containt-box chi-siamo-bold">
                    <h1>TERMINI E CONDIZIONI</h1>

                    <h2>1. CONTENUTO DEL SERVIZIO</h2>

                    <p>Cinebooking Italia S.r.l. azienda di servizi informatici e telematici con sede legale in P.zza Francesco Borgongini
                        Duca 7/B – 00165 Roma. Con la propria piattaforma informatica, mette a disposizione degli utenti che intendano
                        avvalersene un servizio (da ora in poi il “Servizio”) web based che consente di pubblicare e consultare annunci e
                        inserzioni di soggetti privati e aziende che intendano alienare o acquistare beni o prestare e ricevere servizi.
                        Rilasciando i tuoi dati potrai essere contattato dal recruiter per cui ti sei candidato a mezzo email o telefono,
                        cosi come potrai anche essere contattato da Cinebooking a mezzo email, telefono o whats app per circostanze
                        riguardanti il tuo account. Il Servizio consente altresì agli inserzionisti ed agli utenti interessati a quanto
                        pubblicato di entrare in contatto tra di loro. La piattaforma offre una parziale modalità gratuita di tali servizi
                        ed offre inoltre servizi in abbonamento mensile € 4,90 (iva compresa) e annuale € 39,00 (iva compresa).
                        L’abbonamento prevede un automatico rinnovo alla scadenza che può essere disdetto in qualsiasi momento prima della
                        scadenza. Cinebooking non prevede alcuna cessione a terzi dei dati inseriti in piattaforma.
                    </p>

                    <h2>2. TITOLARITÀ DELLA PIATTAFORMA</h2>

                    <p>Cinebooking Italia S.r.l. è l’unica titolare della piattaforma web per il tramite della quale viene
                        gestito il Servizio nonchè di tutti i relativi diritti inerenti e conseguenti allo sfruttamento
                        della piattaforma medesima.</p>

                    <h2>3. APPLICABILITÀ DELLE CONDIZIONI</h2>

                    <p>Le presenti Condizioni Generali di Servizio si applicano sia agli utenti che utilizzino il
                        Servizio in consultazione degli annunci pubblicati sia agli utenti inserzionisti (d‘ora in poi
                        collettivamente “utente/i”).</p>

                    <h2>4. TERMINI PER L’USO DEL SERVIZIO</h2>
                    
                    <p>L’utilizzo del Servizio è consentito solo ad utenti maggiorenni secondo la legge italiana (maggiori di anni 18).
                        L’eventuale utilizzo del Servizio da parte di utenti minorenni presuppone e sottintende l’autorizzazione e la
                        supervisione dei genitori o di chi ne esercita la potestà o la tutela, che assumeranno quindi ogni responsabilità
                        dell’operato del minore nei confronti di Cinebooking Italia S.r.l. e dei terzi ad ogni titolo coinvolti.</p>
                    
                    <p>L’utilizzo del Servizio è parzialmente gratuito e consente la libera consultazione degli annunci e la pubblicazione
                        di inserzioni e la creazione di utenze finalizzate all’utilizzo del servizio medesimo. Talune funzionalità
                        specifiche del Servizio, l’inserzione in specifiche categorie merceologiche e le inserzioni ulteriori rispetto alle
                        soglie fissate per talune categorie potranno essere messe a disposizione solamente a pagamento così come da
                        specifiche condizioni (Premium)di contratto relative ai servizi medesimi.
                    </p>
                    
                    <p>Le relazioni intrattenute tra gli utenti del Servizio, incluso l’acquisto, lo scambio di informazioni, anche per il
                        tramite del form di risposta all’annuncio o chat dedicata, la consegna o il pagamento di beni o servizi, avvengono
                        esclusivamente tra utenti senza che Cinebooking Italia S.r.l. sia parte della relazione. L’utenza si impegna,
                        altresì, a non fare utilizzo improprio dei contatti presenti a qualunque titolo sulla piattaforma di Cinebooking
                        Italia S.r.l. A titolo esemplificativo ma non esaustivo è vietato l’invio di pubblicità, materiale promozionale, o
                        qualsiasi altra forma di sollecitazione non autorizzata o non richiesta tramite e-mail o con qualsiasi altro metodo
                        di contatto.
                    </p>

                    <h2>5. RESPONSABILITÀ DELL’UTENTE</h2>

                    <p>L’utente è totalmente ed esclusivamente responsabile dell’uso del Servizio (da intendersi
                        espressamente con riguardo alle funzioni di pubblicazione, di consultazione, di gestione delle
                        inserzioni e di contatto tra utenti) ed è pertanto l’unico garante e responsabile dei beni e dei
                        servizi offerti per il tramite del Servizio nonchè della correttezza, completezza e liceità
                        delle inserzioni e del proprio comportamento nell’ambito del contatto tra utenti. L’utente
                        garantisce la disponibilità e/o la titolarità del bene/servizio oggetto delle inserzioni
                        medesime. L’utente garantisce altresì che i propri annunci non violano alcun diritto d’autore né
                        diritto di proprietà industriale né altro diritto di terzi. In caso di contestazione da parte di
                        terzi riguardo a qualsiasi annuncio o condotta ad esso legata, l’utente se ne assume la piena
                        responsabilità e si impegna a tenere manlevata e indenne Cinebooking Italia S.r.l. da qualsiasi danno,
                        perdita o spesa. L’utente si impegna ad utilizzare il form di risposta all’annuncio al solo
                        scopo di prendere contatto e scambiare informazioni con gli altri utenti relativamente agli
                        annunci, utilizzando un linguaggio consono, nel rispetto della legge, dell’etica e della
                        netiquitte. L’utente, inoltre, si assume ogni responsabilità per eventuali danni che possano
                        derivare al suo sistema informatico dall’uso del Servizio.</p>

                    <h2>6. LIMITAZIONE DI RESPONSABILITÀ</h2>

                    <p><span style="color:#555555">Cinebooking Italia S.r.l. non presta alcuna garanzia circa il contenuto, la
                            completezza e la correttezza delle inserzioni pubblicate nè con riguardo ai dati pubblicati,
                            né relativamente alle informazioni successivamente fornite dall’utente, nè con riferimento
                            al numero o alla qualità dei risultati ottenuti tramite il Servizio. In ogni caso
                            Cinebooking Italia S.r.l. si riserva, in qualsiasi momento, il diritto di valutare, approvare,
                            eliminare o impedire l’inserzione ovvero il diritto di inibire la consultazione o il
                            contatto per il tramite del form di risposta all’annuncio nel caso in cui, a proprio
                            insindacabile giudizio, l’uso del Servizio da parte dell’utente si riferisca a particolari
                            sezioni merceologiche o possa considerarsi lesivo di diritti o delle prerogative di
                            Cinebooking Italia S.r.l. o di terzi. Resta inteso che anche in caso di valutazione ed approvazione
                            delle inserzioni (siano esse automatiche o manuali) Cinebooking Italia S.r.l. non presta alcuna
                            garanzia circa il contenuto, la completezza e la correttezza delle stesse.&nbsp;Cinebooking
                            Italia è altresì estranea alle trattative eventualmente nascenti dall’uso del Servizio e
                            pertanto non garantisce nè la bontà nè l’esito delle stesse,&nbsp;di conseguenza nessuna
                            richiesta di restituzione, compensazione, riparazione e/o risarcimento a qualunque titolo
                            potrà essere indirizzata nei confronti di Cinebooking Italia S.r.l.</span></p>

                    <p><span style="color:#555555">Il Servizio è offerto per il tramite del sito&nbsp;</span><a
                            href="http://www.1008site.com"><span
                                style="color:#555555">www.cinebooking.com</span></a><span style="color:#555555">&nbsp;e
                            delle applicazioni mobile che possono contenere banner/link ad altri siti Internet o
                            applicazioni che non sono sotto il controllo di Cinebooking Italia S.r.l. la pubblicazione dei
                            predetti banner/link non comporta l’approvazione o l’avallo da parte di Cinebooking Italia S.r.l.
                            dei relativi siti e dei loro contenuti, né implica alcuna forma di garanzia da parte di
                            quest’ultima che pertanto non si assume alcuna responsabilità. L’utente riconosce, quindi,
                            che Cinebooking Italia S.r.l. non è responsabile, a titolo meramente esemplificativo, della
                            veridicità, correttezza, completezza, del rispetto dei diritti di proprietà intellettuale
                            e/o industriale, né risponde della loro eventuale contrarietà all’ordine pubblico, al buon
                            costume e/o alla morale.</span></p>

                    <h2>7. LIMITAZIONI NELL’EROGAZIONE DEL SERVIZIO</h2>

                    <p>Cinebooking Italia S.r.l. si riserva il diritto di modificare, sospendere o interrompere, in tutto o in
                        parte, il Servizio in qualsiasi momento anche senza preavviso&nbsp;e senza che la stessa sia
                        tenuta ad indicare le ragioni sottese alle predette azioni.&nbsp;Cinebooking Italia S.r.l. ha
                        implementato un sistema antispam unitamente al form di risposta all’annuncio, che respinge le
                        richieste di contatto attraverso il form in caso di violazione delle norme applicabili, delle
                        regole e/o delle presenti condizioni (quali a mero titolo esemplificativo, presenza di insulti,
                        epiteti razzisti, attività di spam o phishing, ecc.). Il sistema antispam in ogni caso non
                        garantisce né l’autenticità del prodotto/servizio in vendita né la veridicità del contenuto del
                        contatto. Cinebooking Italia S.r.l. non potrà quindi in nessun caso essere ritenuta parte o
                        responsabile dello scambio di comunicazioni tra gli utenti e delle trattative da esse nascenti.
                        Cinebooking Italia S.r.l. non potrà parimenti essere ritenuta responsabile dei danni conseguenti alla
                        mancata prestazione del Servizio oggetto del presente contratto a causa dell’errato o mancato
                        funzionamento dei mezzi elettronici di comunicazione per cause estranee alla sfera del proprio
                        prevedibile controllo.</p>

                    <p>A titolo esemplificativo, ma non esaustivo, il malfunzionamento dei server ed altri dispositivi
                        elettronici anche non facenti parte integrante della rete Internet, malfunzionamento dei
                        software installati, virus informatici, nonchè da azioni di hacker o altri utenti aventi accesso
                        alla rete.</p>

                    <h2>8. PUBBLICAZIONE SERIALE DI ANNUNCI&nbsp;E/O PER CONTO TERZI</h2>

                    <p>E’ espressamente vietato, salvo esplicita autorizzazione da parte di Cinebooking Italia S.r.l.:<br>
                        – l’utilizzo di sistemi automatici di caricamento annunci, salvo quelli espressamente
                        autorizzati da Cinebooking Italia S.r.l.;<br>
                        – la pubblicazione seriale e/o la gestione di annunci per conto terzi con ogni mezzo o
                        modalità;<br>
                        – rivendere a terzi i servizi di Cinebooking Italia S.r.l.;</p>

                    <p>Al raggiungimento di soglie predeterminate di inserimento degli annunci gratuiti, variabili in
                        base alla categoria di inserimento e alle direttive Cinebooking Italia S.r.l., l’utente non potrà
                        inserire ulteriori annunci e verrà avvisato mediante una comunicazione di Cinebooking Italia S.r.l.
                    </p>

                    <h2>9. LIMITAZIONI AL CONTENUTO DELLE PUBBLICAZIONI</h2>

                    <p>L’utente si impegna a non falsificare la propria identità ed a rispettare tutte le disposizioni
                        di legge vigenti nonché le&nbsp;regole&nbsp;di Cinebooking Italia S.r.l., che a tal fine l’utente
                        accetta e riconosce di dover visionare prima dell’inserimento di ogni annuncio o di prendere
                        contatto con un altro utente per il tramite del form di risposta.</p>

                    <p>A tal proposito l’utente prende atto e accetta che ogni annuncio inserito riporterà
                        obbligatoriamente in chiaro&nbsp;alcune delle informazioni di cui sopra, ed in particolare il
                        numero di annunci online facenti capo all’inserzionista&nbsp;ed una stima del tempo di
                        utilizzo/frequentazione dello stesso del Servizio. Dette informazioni devono
                        ritenersi&nbsp;frutto di calcoli automatici approssimativi e non devono
                        interferire/pregiudicare/influenzare le trattative&nbsp;eventualmente nascenti dall’uso del
                        Servizio per le quali Cinebooking Italia S.r.l. resta estranea.</p>

                    <p>L’utente si impegna altresì, a non utilizzare il Servizio per la pubblicazione, trasmissione,
                        scambio di materiale illecito, volgare, osceno, calunnioso, diffamatorio, offensivo della morale
                        corrente, o, comunque, lesivo dei diritti altrui o di messaggi incitanti all’odio ed alla
                        discriminazione razziale o religioso. Inoltre, l’utente si impegna a non utilizzare il Servizio
                        in maniera tale da violare diritti di proprietà intellettuale o industriale di Cinebooking
                        Italia o di terzi.</p>

                    <h2>10. PUBBLICAZIONI ULTERIORI</h2>

                    <p>L’utente riconosce ed accetta la possibilità offerta da Cinebooking Italia S.r.l. di far pubblicare gli
                        annunci anche su altri siti internet o comunque appartenenti a soggetti terzi interessati a
                        promuovere e/o pubblicare gli annunci dell’utente. L’utente acconsente inoltre all’utilizzo del
                        contenuto dell’annuncio (foto, testo, ecc.) da parte di Cinebooking Italia S.r.l. al fine della
                        pubblicazione dello stesso su siti terzi, riviste on line o cartacee o altri media, impegnandosi
                        altresì a tenere indenne e manlevare Cinebooking Italia S.r.l. da qualsiasi responsabilità e/o
                        richiesta al riguardo.</p>

                    <p>Cinebooking Italia S.r.l. vieta l’utilizzo da parte di software/applicazioni/siti cd, aggregatori e/o
                        terzi, non espressamente e previamente autorizzati, di qualunque contenuto afferente al
                        Servizio. Ogni violazione al predetto divieto potrà essere perseguito a norma legge.</p>

                    <h2>11. GIURISDIZIONE, LEGGE APPLICABILE E FORO COMPETENTE</h2>

                    <p>I rapporti tra Cinebooking Italia S.r.l. e gli utenti sono regolati dalla legge e dalla giurisdizione
                        italiana, in base alla quale anche le presenti Condizioni Generali dovranno essere interpretate.
                        Salvo quanto disposto da norme di legge non derogabili, il <strong>Tribunale di Milano</strong>
                        sarà competente in via esclusiva a dirimere ogni controversia riguardante le presenti Condizioni
                        Generali ed i rapporti dalle stesse regolati.</p>

                    <h2>12. VALIDITÀ DELLE PRESENTI CONDIZIONI GENERALI</h2>

                    <p>Le presenti condizioni Generali di Servizio si considereranno applicabili, ove compatibili, anche
                        in caso di ulteriori, diversi e specifici accordi relativi ai servizi a pagamento. Cinebooking
                        Italia potrà comunque apportare&nbsp;unilateralmente in qualsiasi momento modifiche alle
                        presenti Condizioni Generali dandone comunicazione sulla propria piattaforma web.</p>

                    <h2>13. MODIFICHE</h2>

                    <p>Le presenti condizioni potrebbero essere soggette a modifiche. In caso di sostanziali modifiche,
                        Cinebooking Italia S.r.l. avviserà l’utente pubblicandole con la massima evidenza sulle proprie pagine
                        o tramite email o altro mezzo di&nbsp;comunicazione.</p>

                    <p>Cinebooking Italia S.r.l. P.iva 15338731001&nbsp; Rea RM 1512349</p>

                </div>
            </div>
        </div>
    </section>
</div>
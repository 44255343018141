import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Assistance } from 'src/core/assistance.core';
import { AssistanceService } from '../services/assistance.service';
import { LoadingService } from '../services/loading.service';

@Component({
  selector: 'app-centro-assistenza',
  templateUrl: './centro-assistenza.component.html',
  styleUrls: ['./centro-assistenza.component.scss']
})
export class CentroAssistenzaComponent implements OnInit {
  assistance: Assistance = new Assistance();
  onSubmit: boolean;
  onSuccess: boolean;
  onFailed: boolean;
  error: string;

  @ViewChild('name') name;
  @ViewChild('email') email;
  @ViewChild('phone') phone;
  @ViewChild('message') message;

  constructor(
    private assistanceService: AssistanceService,
    private loadingService: LoadingService,
    private title: Title
  ) { 
    this.title.setTitle('Centro assistenza – Cinebooking');
  }

  ngOnInit(): void {
  }

  submit() {
    this.onSubmit = true;

    if (this.name.invalid || this.email.invalid || this.phone.invalid || this.message.invalid) {
      return;
    }

    this.loadingService.start();
    this.assistanceService.insert(this.assistance).subscribe(() => {
      this.loadingService.stop();
      this.onSuccess = true;
      this.assistance = new Assistance();
      this.onSubmit = false;
    }, (error) => {
      console.error(error);
      this.onFailed = true;
      this.loadingService.stop();
    })
  }

}

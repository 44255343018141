<div class="container">
    <section class="about-cerca page-header-section page-header-padding bg-white">

        <div class="row">
            <div class="col-lg-12">
                <div class="about-info">
                    <p style="text-align:center"><span style="color:#db2557">Inserisci gratis una ricerca</span></p>

                    <h2 style="text-align:center">COSA CERCHI?</h2>

                    <p style="text-align:center"><span style="color:#db2557">Non sarà pubblica online</span></p>

                    <div class="pntmd-form-heading-text-1 pntmd-hidden-mobile">Seleziona una delle offerte di tuo
                        interesse, la funzione&nbsp;<strong>JOB ALERT&nbsp;- FIND ALERT </strong>sarà inviata
                        contestualmente in APP ed EMAIL a tutti gli utenti, &nbsp;secondo la qualifica o bene materiale
                        scelto a chi offre lavoro e servizi come professionisti Tecnici/Artisti&nbsp;- Fornitori -
                        Rental di beni materiali sia aziende che agenzie specializzate e privati a supporto delle
                        industrie di&nbsp; <strong>CINEMA, TELEVISIONE, PUBBLICITA', EVENTI, RADIO, MODA, MUSICA,
                            TEATRO.</strong> L’ALERT&nbsp;che determinerà il “match” sarà ricevuto con uno specifico
                        algoritmo da tutti i profili di candidati corrispondenti &nbsp;alle caratteristiche secondo le
                        opzioni scelte. Al termine del lavoro avrai la possibilità di lasciare una recensione per la
                        qualità e professionalità sul lavoro o fornitura ricevuta.</div>

                </div>
            </div>
        </div>
        <br>
        <br>
    </section>
</div>
<div class="container">
    <section class="job-alert-section color-green bg-white plr-40px">
        <div class="row">

            <div class="col-lg-6 col-md-6 ac-category reveal reveal_visible">
                <div class="cat-card">
                    <a routerLink="/inserimento-ricerca/figura-artistica-cerca">
                        <div class="ac-front-side face">
                            <div class="hovering-c">
                                <span class="cat-icon">
                                    <i class="pntmd-ico pntmd-ico-figura-artistica"></i>
                                </span>
                                <span class="category-name">CAST ARTISTICO</span>
                            </div>
                        </div>
                        <div class="ac-back-side face">
                            <div class="hovering-c">
                                <p>JOB ALERT</p>
                            </div>
                        </div>
                    </a>
                </div>
            </div>

            <div class="col-lg-6 col-md-6 ac-category reveal reveal_visible">
                <div class="cat-card">
                    <a routerLink="/inserimento-ricerca/figura-professionale-cerca">
                        <div class="ac-front-side face">
                            <div class="hovering-c">
                                <span class="cat-icon">
                                    <i class="pntmd-ico pntmd-ico-figura-professionale"></i>
                                </span>
                                <span class="category-name">CREW PROFESSIONALE</span>
                            </div>
                        </div>
                        <div class="ac-back-side face">
                            <div class="hovering-c">
                                <p>JOB ALERT</p>
                            </div>
                        </div>
                    </a>
                </div>
            </div>

        </div>
        <div class="row">
            <div class="col-lg-3 col-md-6 ac-category reveal reveal_visible">
                <div class="cat-card">
                    <a routerLink="/inserimento-ricerca/noleggio-equipments-cerca">
                        <div class="ac-front-side face">
                            <div class="hovering-c">
                                <span class="cat-icon">
                                    <i class="pntmd-ico pntmd-ico-equipments"></i>
                                </span>
                                <span class="category-name">EQUIPMENTS</span>
                            </div>
                        </div>
                        <div class="ac-back-side face">
                            <div class="hovering-c">
                                <p>FIND ALERT</p>
                            </div>
                        </div>
                    </a>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 ac-category reveal reveal_visible">
                <div class="cat-card">
                    <a routerLink="/inserimento-ricerca/noleggio-locations-cerca">
                        <div class="ac-front-side face">
                            <div class="hovering-c">
                                <span class="cat-icon">
                                    <svg class="svg-inline--fa fa-home fa-w-18" aria-hidden="true" focusable="false"
                                        data-prefix="fas" data-icon="home" role="img" xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 576 512" data-fa-i2svg="">
                                        <path fill="currentColor"
                                            d="M280.37 148.26L96 300.11V464a16 16 0 0 0 16 16l112.06-.29a16 16 0 0 0 15.92-16V368a16 16 0 0 1 16-16h64a16 16 0 0 1 16 16v95.64a16 16 0 0 0 16 16.05L464 480a16 16 0 0 0 16-16V300L295.67 148.26a12.19 12.19 0 0 0-15.3 0zM571.6 251.47L488 182.56V44.05a12 12 0 0 0-12-12h-56a12 12 0 0 0-12 12v72.61L318.47 43a48 48 0 0 0-61 0L4.34 251.47a12 12 0 0 0-1.6 16.9l25.5 31A12 12 0 0 0 45.15 301l235.22-193.74a12.19 12.19 0 0 1 15.3 0L530.9 301a12 12 0 0 0 16.9-1.6l25.5-31a12 12 0 0 0-1.7-16.93z">
                                        </path>
                                    </svg><!-- <i class="fas fa-home"></i> -->
                                </span>
                                <span class="category-name">LOCATIONS</span>
                            </div>
                        </div>
                        <div class="ac-back-side face">
                            <div class="hovering-c">
                                <p>FIND ALERT</p>
                            </div>
                        </div>
                    </a>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 ac-category reveal reveal_visible">
                <div class="cat-card">
                    <a routerLink="/inserimento-ricerca/noleggio-arredo-costumi-cerca">
                        <div class="ac-front-side face">
                            <div class="hovering-c">
                                <span class="cat-icon">
                                    <i class="pntmd-ico pntmd-ico-arredo-costumi"></i>
                                </span>
                                <span class="category-name">ARREDO - COSTUMI</span>
                            </div>
                        </div>
                        <div class="ac-back-side face">
                            <div class="hovering-c">
                                <p>FIND ALERT</p>
                            </div>
                        </div>
                    </a>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 ac-category reveal reveal_visible">
                <div class="cat-card">
                    <a routerLink="/inserimento-ricerca/noleggio-trasporti-cerca">
                        <div class="ac-front-side face">
                            <div class="hovering-c">
                                <span class="cat-icon">
                                    <i class="pntmd-ico pntmd-ico-trasporti"></i>
                                </span>
                                <span class="category-name">TRASPORTI</span>
                            </div>
                        </div>
                        <div class="ac-back-side face">
                            <div class="hovering-c">
                                <p>FIND ALERT</p>
                            </div>
                        </div>
                    </a>
                </div>
            </div>


        </div>

        <div class="row">
            <div class="col-lg-3 col-md-6 ac-category reveal reveal_visible">
                <div class="cat-card">
                    <a routerLink="/inserimento-ricerca/noleggio-auto-moto-nautica-cerca">
                        <div class="ac-front-side face">
                            <div class="hovering-c">
                                <span class="cat-icon">
                                    <svg class="svg-inline--fa fa-car fa-w-16" aria-hidden="true" focusable="false"
                                        data-prefix="fa" data-icon="car" role="img" xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 512 512" data-fa-i2svg="">
                                        <path fill="currentColor"
                                            d="M499.99 176h-59.87l-16.64-41.6C406.38 91.63 365.57 64 319.5 64h-127c-46.06 0-86.88 27.63-103.99 70.4L71.87 176H12.01C4.2 176-1.53 183.34.37 190.91l6 24C7.7 220.25 12.5 224 18.01 224h20.07C24.65 235.73 16 252.78 16 272v48c0 16.12 6.16 30.67 16 41.93V416c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32v-32h256v32c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32v-54.07c9.84-11.25 16-25.8 16-41.93v-48c0-19.22-8.65-36.27-22.07-48H494c5.51 0 10.31-3.75 11.64-9.09l6-24c1.89-7.57-3.84-14.91-11.65-14.91zm-352.06-17.83c7.29-18.22 24.94-30.17 44.57-30.17h127c19.63 0 37.28 11.95 44.57 30.17L384 208H128l19.93-49.83zM96 319.8c-19.2 0-32-12.76-32-31.9S76.8 256 96 256s48 28.71 48 47.85-28.8 15.95-48 15.95zm320 0c-19.2 0-48 3.19-48-15.95S396.8 256 416 256s32 12.76 32 31.9-12.8 31.9-32 31.9z">
                                        </path>
                                    </svg><!-- <i class="fa fa-car"></i> -->
                                </span>
                                <span class="category-name">AUTO - MOTO - NAUTICA</span>
                            </div>
                        </div>
                        <div class="ac-back-side face">
                            <div class="hovering-c">
                                <p>FIND ALERT</p>
                            </div>
                        </div>
                    </a>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 ac-category reveal reveal_visible">
                <div class="cat-card">
                    <a routerLink="/inserimento-ricerca/noleggio-animali-cerca">
                        <div class="ac-front-side face">
                            <div class="hovering-c">
                                <span class="cat-icon">
                                    <i class="pntmd-ico pntmd-ico-animali"></i>
                                </span>
                                <span class="category-name">ANIMALI</span>
                            </div>
                        </div>
                        <div class="ac-back-side face">
                            <div class="hovering-c">
                                <p>FIND ALERT</p>
                            </div>
                        </div>
                    </a>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 ac-category reveal reveal_visible">
                <div class="cat-card">
                    <a routerLink="/inserimento-ricerca/film-services-italia-cerca">
                        <div class="ac-front-side face">
                            <div class="hovering-c">
                                <span class="cat-icon">
                                    <i class="pntmd-ico pntmd-ico-film-services-italia"></i>
                                </span>
                                <span class="category-name">ITALY FILMING PERMITS</span>
                            </div>
                        </div>
                        <div class="ac-back-side face">
                            <div class="hovering-c">
                                <p>FIND ALERT</p>
                            </div>
                        </div>
                    </a>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 ac-category reveal reveal_visible">
                <div class="cat-card">
                    <a routerLink="/inserimento-ricerca/space-stage-studios-cerca">
                        <div class="ac-front-side face">
                            <div class="hovering-c">
                                <span class="cat-icon">
                                    <i class="pntmd-ico pntmd-ico-space-stage-studios"></i>
                                </span>
                                <span class="category-name">SPACE STAGE STUDIOS</span>
                            </div>
                        </div>
                        <div class="ac-back-side face">
                            <div class="hovering-c">
                                <p>FIND ALERT</p>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </section>
</div>
import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Project } from 'src/core/project.core';
import { LoadingService } from '../services/loading.service';
import { ProjectService } from '../services/project.service';

@Component({
  selector: 'app-segnala-un-progetto',
  templateUrl: './segnala-un-progetto.component.html',
  styleUrls: ['./segnala-un-progetto.component.scss']
})
export class SegnalaUnProgettoComponent implements OnInit {
  project: Project = new Project();
  onSubmit: boolean;
  onSuccess: boolean;
  onFailed: boolean;
  error: string;

  @ViewChild('name') name;
  @ViewChild('email') email;
  @ViewChild('project_and_period_start') project_and_period_start;
  @ViewChild('work_place') work_place;

  constructor(
    private projectService: ProjectService,
    private loadingService: LoadingService,
    private title: Title
  ) { 
    this.title.setTitle('Segnala un progetto – Cinebooking');
  }

  ngOnInit(): void {
  }

  submit() {
    this.onSubmit = true;
    if (this.name.invalid || this.email.invalid || this.project_and_period_start.invalid || this.work_place.invalid) {
      return;
    }

    this.loadingService.start();
    this.projectService.insert(this.project).subscribe(() => {
      this.loadingService.stop();
      this.onSuccess = true;
      this.project = new Project();
      this.onSubmit = false;
    }, (error) => {
      console.error(error);
      this.onFailed = true;
      this.loadingService.stop();
    })
  }

}

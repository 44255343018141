import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-inserimento-ricerca',
  templateUrl: './inserimento-ricerca.component.html',
  styleUrls: ['./inserimento-ricerca.component.scss']
})
export class InserimentoRicercaComponent implements OnInit {

  constructor(
    private title: Title
  ) { 
    this.title.setTitle('Inserisci una ricerca – Cinebooking');
  }

  ngOnInit(): void {
  }

}

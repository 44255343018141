import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute } from '@angular/router';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LoadingService } from 'src/app/services/loading.service';
import { UserService } from 'src/app/services/user.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    private token: string;

    constructor(
        private router: Router,
        private userService: UserService,
        private loadingService: LoadingService
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
        this.loadingService.start();
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('t');
        let autenticationAddress = this.userService.refreshAccessToken();

        if (token) {
            autenticationAddress = this.userService.isValidTokenCMS(token);
        }

        return from(autenticationAddress).pipe(map((auth) => {
            if (auth) {
                this.loadingService.stop();
                return true;
            }
            this.loadingService.stop();
            this.router.navigate(['/my-account/sing-in'], { queryParams: { returnUrl: state.url } });
            return false;
        }, (error) => {
            this.loadingService.stop();
            this.router.navigate(['/my-account/sing-in'], { queryParams: { returnUrl: state.url } });
        }));
    }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Match } from 'src/core/match.core';
import { DeleteModalComponent } from '../delete-modal/delete-modal.component';
import { LoadingService } from '../services/loading.service';
import { ResearchService } from '../services/research.service';

@Component({
  selector: 'app-matching-searchs',
  templateUrl: './matching-searchs.component.html',
  styleUrls: ['./matching-searchs.component.scss']
})
export class MatchingSearchsComponent implements OnInit {
  @Input() matchs: Match[] = [];
  @Input() slug: string;
  @Input() uuid: string;
  @Output() onDeleteMatch = new EventEmitter();


  constructor(
    private activeModal: NgbActiveModal,
    private router: Router,
    private modal: NgbModal,
    private researchService: ResearchService,
  ) { }

  ngOnInit(): void {
  }

  open(match: Match) {
    this.activeModal.dismiss();
    this.router.navigateByUrl(`/inserimento-proposta/scheda/${this.slug.replace('-cerca', '')}/${match.uuid}/${this.uuid}`)
  }

  delete(match: Match, i: number) {
    const ref = this.modal.open(DeleteModalComponent);
    ref.componentInstance.onDelete.subscribe(() => {
      this.researchService.deleteMatch(this.uuid, match.uuid).subscribe(() => {
        this.matchs.splice(i, 1);
        if (!match.is_visited_proposal) {
          this.onDeleteMatch.emit();
        }
        ref.close();
      }, (error) => {
        console.error(error);
        ref.close();
      })
    });
  }

  dismiss() {
    this.activeModal.dismiss();
  }

}

<div class="success" *ngIf="onSuccess">
    <div class="container">
        <div class="row">
            <div class="alert alert-success col-md-7 col-md-push-2 col-sm-8 col-sm-push-2 col-xs-8 col-xs-push-4   alert-custom"
                role="alert">
                <svg class="svg-inline--fa fa-check-circle fa-w-16" style="color: #3bbdb6;" aria-hidden="true"
                    focusable="false" data-prefix="fa" data-icon="check-circle" role="img"
                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
                    <path fill="currentColor"
                        d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z">
                    </path>
                </svg><!-- <i class="fa fa-check-circle" style="color: #3bbdb6;"></i> -->
                <span class="ml-2"> Invia contatto con successo</span>

                <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="onSuccess = false">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
        </div>
    </div>
</div>
<div class="container">
    <section id="bg-image" data-type="background" data-speed="5" 
        class="page-header-padding bg-white f-cinebook-section">
        <div class="row">
            <div class="col-lg-8 offset-lg-2 col-md-10 offset-md-1">
                <div class="containt-box">
                    <div
                        class="elementor-element elementor-element-89a5d78 elementor-widget elementor-widget-text-editor">
                        <div class="elementor-widget-container">
                            <div class="elementor-clearfix elementor-text-editor">
                                <h1 style="text-align:center">CONTATTACI</h1>

                                <p style="color:#000">Descrivi le tue necessità o esponi la tua problematica.<br/>
                                   Il nostro team ti risponderà o si metterà in contatto con te entro 72 ore.</p>
                            </div>
                        </div>
                    </div>

                    <div class="form-design">
                        <form role="form" id="sendContact" class="cine-booking-form">
                            <div class="form-row">
                                <div class="form-group col-xs-12 col-sm-12 col-md-12">
                                    <label>Nome (richiesto)</label>
                                    <input [(ngModel)]="contact.name" type="text" name="name" id="name"
                                        class="form-control input-sm" [required]="true"
                                        [ngModelOptions]="{standalone: true}" #name="ngModel">
                                </div>
                                <div *ngIf="onSubmit && name.invalid ">
                                    <small *ngIf="name.errors?.required" style="color: red;">Il campo non può essere
                                        vuoto.</small>
                                </div>
                            </div>

                            <div class="form-row">
                                <div class="form-group col-xs-12 col-sm-12 col-md-12">
                                    <label>Cognome (richiesto)</label>
                                    <input [(ngModel)]="contact.surname" type="text" name="surname" id="surname"
                                        class="form-control input-sm" [required]="true"
                                        [ngModelOptions]="{standalone: true}" #surname="ngModel">
                                </div>
                                <div *ngIf="onSubmit && surname.invalid ">
                                    <small *ngIf="surname.errors?.required" style="color: red;">Il campo non può essere
                                        vuoto.</small>
                                </div>
                            </div>

                            <div class="form-row">
                                <div class="form-group col-xs-12 col-sm-12 col-md-12">
                                    <label>Qualifica (opzionale)</label>
                                    <input [(ngModel)]="contact.qualification" type="string" name="qualification" id="qualification"
                                        class="form-control input-sm" [ngModelOptions]="{standalone: true}" #qualification="ngModel">
                                </div>
                            </div>

                            <div class="form-row">
                                <div class="form-group col-xs-12 col-sm-12 col-md-12">
                                    <label>Email (richiesto)</label>
                                    <input [(ngModel)]="contact.email" type="email" name="email" id="email"
                                        class="form-control input-sm" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$"
                                        [required]="true" [ngModelOptions]="{standalone: true}" #email="ngModel">
                                </div>
                                <div *ngIf="onSubmit && email.invalid ">
                                    <small *ngIf="email.errors?.required" style="color: red;">Il campo non può essere
                                        vuoto.</small>
                                    <small *ngIf="email.errors?.pattern" style="color: red;">L'email non risulta
                                        valida.</small>
                                </div>
                            </div>

                            <div class="form-row">
                                <div class="form-group col-xs-12 col-sm-12 col-md-12">
                                    <label>Telefono (richiesto)</label>
                                    <input [(ngModel)]="contact.phone" type="string" name="phone" id="phone" 
                                        class="form-control input-sm" pattern="^[0-9]+$"
                                        [required]="true" [ngModelOptions]="{standalone: true}" #phone="ngModel">
                                </div>
                                <div *ngIf="onSubmit && phone.invalid ">
                                    <small *ngIf="phone.errors?.required" style="color: red;">Il campo non può essere
                                        vuoto.</small>
                                    <small *ngIf="phone.errors?.pattern" style="color: red;">Il numero di telefono non risulta
                                        valida.</small>
                                </div>
                            </div>

                            <div class="form-row">
                                <div class="form-group col-xs-12 col-sm-12 col-md-12">
                                    <label>Motivazione del contatto</label>

                                    <ng-select [(ngModel)]="contact.reason"
                                                name="reason" id="reason"
                                                aria-hidden="true"
                                                #reason="ngModel"
                                                [required]="true" [ngModelOptions]="{standalone: true}"
                                                class="custom form-control select-wo-border pl-0 custome-select-design select2-hidden-accessible">
                                                <ng-option [value]="'tecnico-funzionale'">Tecnico/Funzionale</ng-option>
                                                <ng-option [value]="'amministrativo'">Amministrativo</ng-option>
                                                <ng-option [value]="'stampa'">Stampa</ng-option>
                                                <ng-option [value]="'interesse-come-agente'">Interesse come agente</ng-option>
                                                <ng-option [value]="'proposta-commerciale'">Proposta commerciale</ng-option>
                                                <ng-option [value]="'altro'">Altro</ng-option>
                                            </ng-select>
                                </div>
                                <div *ngIf="onSubmit && reason.invalid ">
                                    <small *ngIf="reason.errors?.required" style="color: red;">Il campo non può essere
                                        vuoto.</small>
                                </div>
                            </div>

                            <div class="form-row">
                                <div class="form-group col-xs-12 col-sm-12 col-md-12">
                                    <label>Hai un abbonamento Premium?</label>

                                    <ng-select [(ngModel)]="contact.hasPremium"
                                                name="hasPremium" id="hasPremium"
                                                aria-hidden="true"
                                                #hasPremium="ngModel"
                                                [required]="true" [ngModelOptions]="{standalone: true}"
                                                class="custom form-control select-wo-border pl-0 custome-select-design select2-hidden-accessible">
                                                <ng-option [value]="true">Si</ng-option>
                                                <ng-option [value]="false">No</ng-option>
                                            </ng-select>
                                </div>
                                <div *ngIf="onSubmit && hasPremium.invalid ">
                                    <small *ngIf="hasPremium.errors?.required" style="color: red;">Il campo non può essere
                                        vuoto.</small>
                                </div>
                            </div>

                            <div class="form-row">
                                <div class="form-group col-xs-12 col-sm-12 col-md-12">
                                    <label>Messaggio</label>
                                    <textarea [(ngModel)]="contact.message" class="form-control input-sm"
                                        #message="ngModel"
                                        [required]="true" 
                                        name="message" id="message" rows="5"  [ngModelOptions]="{standalone: true}"></textarea>
                                </div>
                                <div *ngIf="onSubmit && message.invalid ">
                                    <small *ngIf="message.errors?.required" style="color: red;">Il campo non può essere
                                        vuoto.</small>
                                </div>
                            </div>

                            <div class="form-row">
                                <div class="form-group col-xs-12">
                                    <input (click)="submit()" type="submit" id="submit" value="INVIA"
                                        class="btn btn-primary btn-block pink-btn">
                                </div>
                            </div>


                        </form>

                    </div>

                </div>
            </div>
        </div>


    </section>
</div>
import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { changePassword } from 'src/core/changePassword.core';
import { ResetPassword } from 'src/core/reset-password.core';
import { Singin, Singup, User } from 'src/core/user.core';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class UserService {
  private resource = 'users/';
  mobile_token: string;
  platform: string; 

  onAutentichate = new EventEmitter();
  onUpdateUser = new EventEmitter();

  constructor(
    private http: HttpClient,
    private cookieService: CookieService
  ) { }

  singup(user: Singup) {
    user.mobile_token = this.mobile_token;
    user.platform = this.platform;
    return this.http.post(`${environment.api}${this.resource}singup`, user);
  }

  singin(user: Singin) {
    user.mobile_token = this.mobile_token;
    user.platform = this.platform;
    return this.http.post(`${environment.api}${this.resource}singin`, user);
  }

  confirmAccount(registration_token: string) {
    return this.http.post(`${environment.api}${this.resource}confirmAccount`, { registration_token: registration_token });
  }

  getUser() {
    const user_id = this.getUserId();
    return this.http.get(`${environment.api}${this.resource}getUser/${user_id}`);
  }

  updateUser(user: User) {
    return this.http.post(`${environment.api}${this.resource}updateUser`, user);
  }

  changePassword(changePassword: changePassword) {
    return this.http.post(`${environment.api}${this.resource}changePassword`, changePassword);
  }

  resetPasswordToken(user_email: string) {
    return this.http.post(`${environment.api}${this.resource}resetPasswordToken`, { user_email: user_email });
  }

  resetPassword(reset: ResetPassword) {
    return this.http.post(`${environment.api}${this.resource}resetPassword`, reset);
  }

  deleteAccount() {
    const user_id = this.getUserId();
    return this.http.delete(`${environment.api}${this.resource}deleteAccount/${user_id}`);
  }

  isAuthenticated() {
    const token = this.cookieService.get('token');
    if (!token) {
      return of(false);
    }
    return this.http.post(`${environment.api}${this.resource}isAuthenticated`, {});
  }

  refreshAccessToken() {
    const token = this.cookieService.get('token');
    if (!token) {
      return of(false);
    }
    return this.http.post(`${environment.api}${this.resource}refreshAccessToken`, {}).pipe(map((user: any) => {
      if (!user) {
        return false;
      }
      this.cookieService.set('user_id', user.user_id, undefined, undefined, undefined, true, "None");
      this.cookieService.set('token', user.token, undefined, undefined, undefined, true, "None");
      return true;
    }));
  }

  isValidTokenCMS(token: string) {
    if (!token) {
      return of(false);
    }
    return this.http.post(`${environment.api}cms/isValidToken`, {token: token}).pipe(map((response: any) => {
      if(!response) {
        return false;
      }
      return true;
    }));
  }

  getUserId() {
    const user_id = this.cookieService.get('user_id');
    return user_id;
  }

  isViewableScheda(proposal_uuid: string, research_uuid: string, type: string) {
    const params = { 
      user_id: this.getUserId(),
      proposal_uuid: proposal_uuid,
      research_uuid: research_uuid,
      type: type
    }
    return this.http.post(`${environment.api}${this.resource}isViewableScheda`, params);
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Match } from 'src/core/match.core';
import { DeleteModalComponent } from '../delete-modal/delete-modal.component';
import { ProposalService } from '../services/proposal.service';
import { UserService } from '../services/user.service';
import { User } from 'src/core/user.core';
import { LoadingService } from '../services/loading.service';

@Component({
  selector: 'app-matching-listing',
  templateUrl: './matching-listing.component.html',
  styleUrls: ['./matching-listing.component.scss']
})
export class MatchingListingComponent implements OnInit {
  @Input() matchs: Match[] = [];
  @Input() slug: string;
  @Input() uuid: string;
  @Output() onDeleteMatch = new EventEmitter();
  section: string;

  constructor(
    private activeModal: NgbActiveModal,
    private router: Router,
    private route: ActivatedRoute,
    private modal: NgbModal,
    private proposalService: ProposalService,
    private userService: UserService,
    private loadingService: LoadingService
  ) { 
    this.route.queryParams.subscribe((params) => {
      this.section = params['section'];
    })
  }

  ngOnInit(): void {
    this.matchs = this.matchs.filter(m => !this.isExpiresAfter5Days(m));
  }

  open(match) {
    this.activeModal.dismiss();
    let qSection = '';
    if (this.section) {
      qSection = `?section=${this.section}`;
    }
    this.router.navigateByUrl(`/inserimento-ricerca/scheda/${this.slug}-cerca/${match.uuid}/${this.uuid}${qSection}`)
  }

  delete(match: Match, i: number) {
    const ref = this.modal.open(DeleteModalComponent);
    ref.componentInstance.onDelete.subscribe(() => {
      this.proposalService.deleteMatch(this.uuid, match.uuid).subscribe(() => {
        this.matchs.splice(i, 1);
        if (!match.is_visited_search) {
          this.onDeleteMatch.emit();
        }
        ref.close();
      }, (error) => {
        console.error(error);
        ref.close();
      })
    });
  }

  isExpires(match: Match) {
    const date = new Date(match.date);
    date.setMonth(date.getMonth() + 1);

    const now = new Date();
    return date < now;
  }

  isExpiresAfter5Days(match: Match) {
    const current = new Date();
    if (!match.expires) {
      match.expires = new Date(match.date);
      match.expires.setMonth(match.expires.getMonth() + 1);
    }
    const expires = new Date(match.expires);
    expires.setDate(expires.getDate() + 5);

    if (expires <= current) {
      return true;
    }
    return false;
  }

  dismiss() {
    this.activeModal.dismiss();
  }

}

<div class="container">

    <section id="bg-image" data-type="background" data-speed="5" style="background: url(red_7.jpg) no-repeat;"
        class="page-header-padding bg-white f-cinebook-section parallax-bg">
        <div class="row">
            <div class="col-lg-8 offset-lg-2 col-md-10 offset-md-1">
                <div class="containt-box chi-siamo-bold">
                    <h1>COOKIE</h1>

                    <p><span style="color:#555555">Nessun dato personale degli utenti viene in proposito acquisito dal
                            sito.</span></p>

                    <p><span style="color:#555555">Non viene fatto uso di cookies per la trasmissione di informazioni di
                            carattere personale ovvero sistemi per il tracciamento degli utenti.</span></p>

                    <p><span style="color:#555555">Questo sito utilizza sia cookies persistenti che di sessione. Quelli
                            di sessione non vengono memorizzati in modo permanente sul Suo computer e svaniscono con la
                            chiusura del browser.</span></p>

                    <p><span style="color:#555555">L’uso di c.d. cookies di sessione (che non vengono memorizzati in
                            modo persistente sul computer dell’utente e svaniscono con la chiusura del browser) è
                            strettamente limitato alla trasmissione di identificativi di sessione (costituiti da numeri
                            casuali generati dal server) necessari per consentire l’esplorazione sicura ed efficiente
                            del sito.</span></p>

                    <p><span style="color:#555555">I c.d. cookies di sessione utilizzati in questo sito evitano il
                            ricorso ad altre tecniche informatiche potenzialmente pregiudizievoli per la riservatezza
                            della navigazione degli utenti e non consentono l’acquisizione di dati personali
                            identificativi dell’utente.</span></p>

                    <p><span style="color:#555555">I c.d.cookies persistenti servono per personalizzare la navigazione
                            in funzione dello strumento utilizzato dall’utente (computer, tablet, smartphone) così come
                            i cookies di terze parti servono per analizzare gli accessi del sito (es. Google Analytics).
                            Questi cookies, vengono memorizzati in modo permanente sul Suo computer e hanno una durata
                            variabile.</span></p>

                    <p><span style="color:#555555">Il nostro sito utilizza Google Analytics di Google, Inc., un servizio
                            che offre statistiche di misurazione ed analisi delle performance del sito, tramite l’uso di
                            Cookie. Per consultare l’informativa privacy del servizio Google Analytics, visiti la
                            pagina&nbsp;</span><a
                            href="http://www.google.com/intl/en/analytics/privacyoverview.html"><span
                                style="color:#555555">http://www.google.com/intl/en/analytics/privacyoverview.html</span></a><span
                            style="color:#555555">.</span></p>

                    <p><span style="color:#555555">Per le norme sulla privacy di Google, segnaliamo il seguente
                            indirizzo&nbsp;</span><a
                            href="http://www.google.com/intl/it/privacy/privacy-policy.html"><span
                                style="color:#555555">http://www.google.com/intl/it/privacy/privacy-policy.html</span></a><span
                            style="color:#555555">.</span></p>

                </div>
            </div>
        </div>
    </section>
</div>
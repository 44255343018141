<div class="modal-body">
    <button (click)="dismiss()" tabindex="-1" type="button" class="bootbox-close-button close" aria-hidden="true">×</button>
    <div class="bootbox-body">Richiedi fattura</div>

    <hr />

    <div class="form-design">

        <div class="alert alert-danger alert-block" style="top:0!important" *ngIf="onFailed">
            <button type="button" class="colse" data-dismiss="alert" (click)="onFailed = false">X</button>
            <strong>{{error}}</strong>
        </div>

        <form role="form" id="invoice" class="cine-booking-form">
            <div class="form-row">
                <div class="form-group col-xs-12 col-sm-12 col-md-12">
                    <label>Ragione sociale</label>
                    <input [(ngModel)]="invoice.businessName" type="text" name="businessName" id="businessName"
                        class="form-control input-sm" [required]="true"
                        [ngModelOptions]="{standalone: true}" #businessName="ngModel">
                </div>
                <div *ngIf="onSubmit && businessName.invalid ">
                    <small *ngIf="businessName.errors?.required" style="color: red;">Il campo non può essere
                        vuoto.</small>
                </div>
            </div>

            <div class="form-row">
                <div class="form-group col-xs-12 col-sm-12 col-md-12">
                    <label>Partita IVA</label>
                    <input [(ngModel)]="invoice.vatNumber" type="text" name="vatNumber" id="vatNumber"
                        class="form-control input-sm" [required]="true"
                        [ngModelOptions]="{standalone: true}" #vatNumber="ngModel">
                </div>
                <div *ngIf="onSubmit && vatNumber.invalid ">
                    <small *ngIf="vatNumber.errors?.required" style="color: red;">Il campo non può essere
                        vuoto.</small>
                </div>
            </div>

            <div class="form-row">
                <div class="form-group col-xs-12 col-sm-12 col-md-12">
                    <label>Email pec</label>
                    <input [(ngModel)]="invoice.pec" type="pec" name="pec" id="pec"
                        class="form-control input-sm" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$"
                        [required]="true" [ngModelOptions]="{standalone: true}" #pec="ngModel">
                </div>
                <div *ngIf="onSubmit && pec.invalid ">
                    <small *ngIf="pec.errors?.required" style="color: red;">Il campo non può essere
                        vuoto.</small>
                    <small *ngIf="pec.errors?.pattern" style="color: red;">L'email non risulta
                        valida.</small>
                </div>
            </div>

            <div class="form-row">
                <div class="form-group col-xs-12 col-sm-12 col-md-12">
                    <label>Codice SDI</label>
                    <input [(ngModel)]="invoice.sdi" type="text" name="sdi" id="sdi" 
                        class="form-control input-sm"
                        [required]="true" [ngModelOptions]="{standalone: true}" #sdi="ngModel">
                </div>
                <div *ngIf="onSubmit && sdi.invalid ">
                    <small *ngIf="sdi.errors?.required" style="color: red;">Il campo non può essere
                        vuoto.</small>
                </div>
            </div>

            
            <div class="form-row">
                <div class="form-group col-xs-12 col-sm-12 col-md-12">
                    <label>Indirizzo di fatturazione</label>
                    <input [(ngModel)]="invoice.address" type="text" name="address" id="address" 
                        class="form-control input-sm"
                        [required]="true" [ngModelOptions]="{standalone: true}" #address="ngModel">
                </div>
                <div *ngIf="onSubmit && address.invalid ">
                    <small *ngIf="address.errors?.required" style="color: red;">Il campo non può essere
                        vuoto.</small>
                </div>
            </div>

            <div class="form-row">
                <div class="form-group col-xs-12 col-sm-12 col-md-12">
                    <label>Numero civico</label>
                    <input [(ngModel)]="invoice.streetNumber" type="text" name="streetNumber" id="streetNumber" 
                        class="form-control input-sm"
                        [required]="true" [ngModelOptions]="{standalone: true}" #streetNumber="ngModel">
                </div>
                <div *ngIf="onSubmit && streetNumber.invalid ">
                    <small *ngIf="streetNumber.errors?.required" style="color: red;">Il campo non può essere
                        vuoto.</small>
                </div>
            </div>

            <div class="form-row">
                <div class="form-group col-xs-12 col-sm-12 col-md-12">
                    <label>Città</label>
                    <input [(ngModel)]="invoice.city" type="text" name="city" id="city" 
                        class="form-control input-sm"
                        [required]="true" [ngModelOptions]="{standalone: true}" #city="ngModel">
                </div>
                <div *ngIf="onSubmit && city.invalid ">
                    <small *ngIf="city.errors?.required" style="color: red;">Il campo non può essere
                        vuoto.</small>
                </div>
            </div>

            <div class="form-row">
                <div class="form-group col-xs-12 col-sm-12 col-md-12">
                    <label>Codice postale</label>
                    <input [(ngModel)]="invoice.postalCode" type="text" name="postalCode" id="postalCode" 
                        class="form-control input-sm"
                        [required]="true" [ngModelOptions]="{standalone: true}" #postalCode="ngModel">
                </div>
                <div *ngIf="onSubmit && postalCode.invalid ">
                    <small *ngIf="postalCode.errors?.required" style="color: red;">Il campo non può essere
                        vuoto.</small>
                </div>
            </div>
        </form>

    </div>
</div>
<div class="modal-footer">
    <button (click)="dismiss()"  tabindex="-1" type="button" class="btn btn-danger bootbox-cancel">Annulla</button>
    <button (click)="submit()" tabindex="-1" type="button" class="btn btn-success bootbox-accept">Richiedi</button>
</div>
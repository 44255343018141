<div class="container">

    <section id="bg-image" data-type="background" data-speed="5" style="background: url(green_2.jpg) no-repeat;"
        class="page-header-padding bg-white f-cinebook-section parallax-bg">
        <div class="row">
            <div class="col-lg-8 offset-lg-2 col-md-10 offset-md-1">
                <div class="containt-box chi-siamo-bold">
                    <h1>CINEBOOKING UNA STARTUP INNOVATIVA TUTTA ITALIANA</h1>

                    <p>Nasce a Roma c/o Cinecittà Studios l’innovativa piattaforma web con iscrizione gratuita per tutti
                        i lavoratori tecnici e artistici del Cinema, Televisione, Pubblicità, Eventi, Radio, Moda,
                        Musica, Teatro.</p>

                    <p>Già presentata da Gianfranco De Rosa (Produttore Esecutivo) in off-line in occasione del
                        Microsalon 2018 al teatro 10 in Cinecittà ha subito suscitato interesse da parte degli addetti
                        ai lavori raccogliendo più di 600 iscrizioni, tra cui il Produttore Marco Belardi, Paolo
                        Genovese, Marco Risi, Carlo Verdone.</p>

                    <p>Cinebooking.com con una semplice iscrizione darà vita ad un infinità di opportunità sia
                        nell’offerta che per la ricerca senza alcun profilo in bacheca pubblica o CV alla portata di
                        tutti ed è proprio questo che rende innovativa la piattaforma e cioè l’assoluta novità nel
                        rendere il servizio solo ed esclusivamente con un “match” che viene generato da una specifica
                        ricerca, assicurando in questo modo la visione dei dati sensibili esclusivamente a chi l’utente
                        ha autorizzato attraverso il consenso nel match.</p>

                </div>
            </div>
        </div>
    </section>
</div>
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Utils } from 'src/utils/utils';

@Injectable({
  providedIn: 'root'
})
export class UploadService {
  private resource = 'files/';

  constructor(
    private http: HttpClient
  ) { }

  upload(files: File[]) {
    const formData = new FormData();
    for (const file of files) {
      formData.append('files', file)
    }
    return this.http.post(`${environment.apiGateway}files/upload`, formData);
  }

  uploadPresignedUrl(url: string, file: File) {
    const req = new HttpRequest('PUT', url, file, { headers: new HttpHeaders({ 'Anonymous': 'Anonymous' }) });
    req.headers.append('x-amz-acl', 'public-read');
    req.headers.append('Content-Type', file.type);
    return this.http.request(req).toPromise();
  }


  presignedUrl(files: File[]) {
    const params = [];
    for (const file of files) {
      const ext = file.name.split('.')[file.name.split('.').length - 1];
      params.push({ name: `${Utils.uuid()}.${ext}` });
    }
    return this.http.post(`${environment.apiGateway}files/presignedUrl`, params);
  }
}

export class User {
    _id: number;
    user_login: string;
    user_pass: string;
    user_nicename: string;
    user_email: string;
    user_name: string;
    user_surname: string;
    user_province: string;
    user_city: string;
    user_address: string;
    user_cap: string;
    user_gender: string;
    user_profile: string;
    user_url: string;
    user_registered: string;
    display_name: string;
    subscription: Subcription;
}

export class JobAlertViewableFree {
    proposal_uuid: string;
    research_uuid: string;
    date: Date;
    type: String;
}

export class Subcription {
    _id: string;
    status: string;
    type: string;
    start: Date;
    end: Date;
    customer: string;
}

export class Singup {
    user_name: string;
    user_surname: string;
    user_email: string;
    confirm_email: string;
    user_pass: string;
    mobile_token: string;
    platform: string;
    consentTermsConditionsUse: boolean;
    consentNewsletter: boolean;
    onSubmit: boolean;
}

export class Singin {
    user_email: string;
    user_pass: string;
    mobile_token: string;
    platform: string;
    onSubmit: boolean;
}

export class CmsSingin {
    username: string;
    password: string;
    onSubmit: boolean;
}
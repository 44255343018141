import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-entra-nel-club',
  templateUrl: './entra-nel-club.component.html',
  styleUrls: ['./entra-nel-club.component.scss']
})
export class EntraNelClubComponent implements OnInit {

  constructor(
    private title: Title
  ) { 
    this.title.setTitle('Entra nel club – Cinebooking');
  }

  ngOnInit(): void {
  }

}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FormSettingServiceService {
  private resource = 'formSettings/';
  constructor(
    private http: HttpClient
  ) { }

  getFormSettings(slug) {
    return this.http.get(`${environment.api}${this.resource}get/${slug}`);
  }

  editFormSettings(slug: string, uuid: string) {
    return this.http.post(`${environment.api}${this.resource}edit`, { slug, uuid });
  }
}

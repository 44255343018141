<div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel" style="color: #db2557;font-weight: 600;">
        {{waNumber ? 'DAI SEGUITO CLICCA QUI' : 'CANDIDATURA INVIATA'}}
    </h5>
    <button tabindex="-1" (click)="dismiss()" type="button" class="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">×</span>
    </button>
</div>
<div class="modal-body">
    <p *ngIf="!waNumber"><strong style="color: #db2557">NOTA:</strong> NON DEVI FARE ALTRO, SOLO ATTENDERE LA SCELTA TRA
        I CANDIDATI DEL RECRUITER SELEZIONATORE,
        CHE HA RICEVUTO LA TUA SCHEDA E SARÀ LUI QUALORA SARAI SCELTA/O A CONTATTARTI DIRETTAMENTE.</p>
    <p *ngIf="waNumber"><strong style="color: #db2557">NOTA:</strong> QUESTO MATCH PREVEDE L’INVIO DELLA TUA SCHEDA AL
        RECRUITER SELEZIONATORE TRAMITE IL TUO ACCOUNT WHATSAPP, CLICCA SUL TASTO VERDE E DAI SEGUITO ALLA CANDIDATURA.
    </p>
</div>
<div class="modal-footer">
    <button (click)="dismiss()" type="button" data-dismiss="modal" aria-label="Close" *ngIf="!waNumber"
        class="btn btn-primary" style="color: #337ab7;
        background-color: #ffffff;
        border-color: #337ab7;
        border-radius: 5px;" tabindex="-1">Chiudi</button>
    <div (click)="sendWa()" type="button" *ngIf="waNumber" class="btn btn-primary" style="background: #25d366;
        border:#25d366;
        color:#fff;
        border-radius: 5px;" tabindex="-1">Invia candidatura tramite whatsapp</div>
</div>
<div class="success" *ngIf="onSuccess">
    <div class="container">
        <div class="row">
            <div class="alert alert-success col-md-7 col-md-push-2 col-sm-8 col-sm-push-2 col-xs-8 col-xs-push-4   alert-custom"
                role="alert">
                <svg class="svg-inline--fa fa-check-circle fa-w-16" style="color: #3bbdb6;" aria-hidden="true"
                    focusable="false" data-prefix="fa" data-icon="check-circle" role="img"
                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
                    <path fill="currentColor"
                        d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z">
                    </path>
                </svg><!-- <i class="fa fa-check-circle" style="color: #3bbdb6;"></i> -->
                <span class="ml-2"> Abbonamento disdetto con successo</span>

                <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="onSuccess = false">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
        </div>
    </div>
</div>
<div class="container">
    <section id="bg-image" data-type="background" data-speed="5" 
        class="page-header-padding bg-white f-cinebook-section">
        <div class="row">
            <div class="col-lg-8 offset-lg-2 col-md-10 offset-md-1">
                <div class="containt-box">
                    <div
                        class="elementor-element elementor-element-89a5d78 elementor-widget elementor-widget-text-editor">
                        <div class="elementor-widget-container">
                            <div class="elementor-clearfix elementor-text-editor">
                                <h1 style="text-align:center">DISDICI ABBONAMENTO</h1>

                                <p style="color:#000">Non ti sei trovato bene con Cinebooking?</p>
                            </div>
                        </div>
                    </div>

                    <div class="form-design">
                        <form role="form" id="sendContact" class="cine-booking-form">

                            <div class="alert alert-danger alert-block" *ngIf="onFailed" style="top:0">
                                <button type="button" class="colse" data-dismiss="alert"
                                    (click)="onFailed = false">X</button>
                                <strong>{{error}}</strong>
                            </div>

                            <div class="form-row">
                                <div class="form-group col-xs-12 col-sm-12 col-md-12">
                                    <label>Motivazione della disdetta</label>

                                    <ng-select [(ngModel)]="cancellation.reason"
                                                name="reason" id="reason"
                                                aria-hidden="true"
                                                #reason="ngModel"
                                                [required]="true" [ngModelOptions]="{standalone: true}"
                                                class="custom form-control select-wo-border pl-0 custome-select-design select2-hidden-accessible">
                                                    <ng-option [value]="'poche-offerte'">Poche offerte di lavoro</ng-option>
                                                    <ng-option [value]="'ho-trovato-un-impiego'">Ho trovato un impiego fisso</ng-option>
                                                    <ng-option [value]="'ho-cambiato-lavoro'">Ho cambiato lavoro</ng-option>
                                                    <ng-option [value]="'non-utile'">Non lo ritengo utile</ng-option>
                                                    <ng-option [value]="'troppo-costoso'">Troppo costoso</ng-option>
                                                    <ng-option [value]="'rinnovo-all-occorenza'">Preferisco rinnovarlo all’esigenza</ng-option>
                                                    <ng-option [value]="'difficolta-comprensione'">Ho difficoltà di comprensione</ng-option>
                                                    <ng-option [value]="'cerco-autonomamento'">Cerco autonomamente le offerte</ng-option>
                                                    <ng-option [value]="'impossibilitato-a-lavorare'">Impossibilitato a lavorare</ng-option>
                                                    <ng-option [value]="'utilizzo-altra-piattaforma'">Utilizzo altra piattaforma</ng-option>
                                                    <ng-option [value]="'altro'">Altro</ng-option>
                                            </ng-select>
                                </div>
                                <div *ngIf="onSubmit && reason.invalid ">
                                    <small *ngIf="reason.errors?.required" style="color: red;">Il campo non può essere
                                        vuoto.</small>
                                </div>
                            </div>

                            <div class="form-row" *ngIf="cancellation.reason == 'altro'">
                                <div class="form-group col-xs-12 col-sm-12 col-md-12">
                                    <label>Descrivici per favore il motivo </label>
                                    <textarea [(ngModel)]="cancellation.message" class="form-control input-sm"
                                        #message="ngModel"
                                        [required]="true" 
                                        name="message" id="message" rows="5"  [ngModelOptions]="{standalone: true}"></textarea>
                                </div>
                                <div *ngIf="onSubmit && message.invalid ">
                                    <small *ngIf="message.errors?.required" style="color: red;">Il campo non può essere
                                        vuoto.</small>
                                </div>
                            </div>

                            <div class="form-row">
                                <div class="form-group col-xs-12">
                                    <input (click)="submit()" type="submit" id="submit" value="DISDICI ABBONAMENTO"
                                        class="btn btn-primary btn-block pink-btn">
                                </div>
                            </div>


                        </form>

                    </div>

                </div>
            </div>
        </div>


    </section>
</div>
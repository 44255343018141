<div *ngIf="!isEdit">
    <div class="container">
        <section class="about-proposta page-header-section page-header-padding bg-white">
            <div class="row">
                <div class="col-lg-12">
                    <div #header class="about-info"></div>
                </div>
            </div>
        </section>
    </div>

    <div class="container">
        <form role="form" id="propesta-form" class="after-login-formdesign">
            <section class="job-alert-login-section bg-white plr-40px">
                <div class="row" [ngClass]="{'bg-error': hasErrors}">
                    <div class="col-lg-8 offset-lg-2">
                        <div class="job-alert-login-box">
                            <div class="job-alert-login-title {{colorClass('color-')}} mb-4">
                                <span class="rounded-circle"><svg
                                        class="svg-inline--fa fa-pencil-alt fa-w-16 color-white" aria-hidden="true"
                                        focusable="false" data-prefix="fas" data-icon="pencil-alt" role="img"
                                        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
                                        <path fill="currentColor"
                                            d="M497.9 142.1l-46.1 46.1c-4.7 4.7-12.3 4.7-17 0l-111-111c-4.7-4.7-4.7-12.3 0-17l46.1-46.1c18.7-18.7 49.1-18.7 67.9 0l60.1 60.1c18.8 18.7 18.8 49.1 0 67.9zM284.2 99.8L21.6 362.4.4 483.9c-2.9 16.4 11.4 30.6 27.8 27.8l121.5-21.3 262.6-262.6c4.7-4.7 4.7-12.3 0-17l-111-111c-4.8-4.7-12.4-4.7-17.1 0zM124.1 339.9c-5.5-5.5-5.5-14.3 0-19.8l154-154c5.5-5.5 14.3-5.5 19.8 0s5.5 14.3 0 19.8l-154 154c-5.5 5.5-14.3 5.5-19.8 0zM88 424h48v36.3l-64.5 11.3-31.1-31.1L51.7 376H88v48z">
                                        </path>
                                    </svg></span>
                                <h5>{{title}}</h5>
                            </div>

                            <div *ngIf="!authenticate" class="job-alert-login-body">
                                <label class="{{colorClass('color-', '')}}">Hai un account?</label>
                                <div class="account-sign-in {{colorClass('color-', '')}}">
                                    <div class="account-sign-in-title">
                                        <p>
                                            <svg
                                                class="svg-inline--fa fa-lock fa-w-14" aria-hidden="true" focusable="false"
                                                data-prefix="fas" data-icon="lock" role="img"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg="">
                                                <path fill="currentColor"
                                                    d="M400 224h-24v-72C376 68.2 307.8 0 224 0S72 68.2 72 152v72H48c-26.5 0-48 21.5-48 48v192c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V272c0-26.5-21.5-48-48-48zm-104 0H152v-72c0-39.7 32.3-72 72-72s72 32.3 72 72v72z">
                                                </path>
                                            </svg>Per effettuare l'inserimento devi accedere al tuo account o eseguire un'iscrizione gratuita.
                                        </p>
                                    </div>
                                    <div class="account-sign-in-cta">
                                        <a class="btn btn-primary btn-green" routerLink="/my-account/sing-in" style="color: #fff;">Accedi</a>
                                        <a class="btn btn-primary btn-pink" routerLink="/my-account" style="color: #fff;">Iscriviti</a>
                                    </div>
                                </div>
                            </div>
                            <div class="form-design {{colorClass('', '-label')}}" *ngIf="authenticate && formSettings">
                                <div class="form-row" *ngFor="let field of fieldsSectionOne">
                                    <div class="form-group col-xs-12 col-sm-12 col-md-12"
                                        *ngIf="isParentOptionSelected(field)">
                                        <label class="proposta-form-label">{{field.label}}
                                            <span *ngIf="!field.is_required"
                                                class="color-light font-12px">(facoltativo)</span>
                                        </label>
                                        <div class="mt-10px" *ngIf="isText(field)">
                                            <input type="text" [(ngModel)]="field.value"
                                                [ngClass]="{'text-red': field.error}" (input)="onChangeField(field)"
                                                name="{{field.field_name}}" id="{{field.field_name}}"
                                                maxlength="{{field.max}}" class="form-control input-sm pl-0"
                                                placeholder="{{field.placeholder}}" />
                                                <div style="text-align: end;" *ngIf="field.max && (field.field_name == 'search' || field.field_name == 'proposal')">
                                                    <span class=" small-text-form" style="font-style: normal;">{{field.value ? field.value.length : 0}}/{{field.max}} carratteri</span>
                                                </div>
                                        </div>
                                        <div class="mt-10px" *ngIf="isNumber(field)">
                                            <input type="number" [(ngModel)]="field.value"
                                                [ngClass]="{'text-red': field.error}" (input)="onChangeField(field)"
                                                name="{{field.field_name}}" id="{{field.field_name}}"
                                                max="{{field.max}}" min="{{field.min}}"
                                                class="form-control input-sm pl-0" placeholder="{{field.placeholder}}">
                                        </div>
                                        <div class="mt-10px" *ngIf="isLongText(field)">
                                            <textarea [(ngModel)]="field.value" [ngClass]="{'text-red': field.error}"
                                                (input)="onChangeField(field)" class="form-control input-sm pl-0"
                                                id="{{field.field_name}}" name="{{field.field_name}}" rows="5"
                                                maxlength="{{field.max}}"
                                                placeholder="{{field.placeholder}}"></textarea>
                                                <div style="text-align: end;" *ngIf="field.max">
                                                    <span class=" small-text-form" style="font-style: normal;">{{field.value ? field.value.length : 0}}/{{field.max}} carratteri</span>
                                                </div>
                                        </div>
                                        <div class="mt-10px" *ngIf="isDropdown(field)">
                                            <ng-select *ngIf="!field.is_master" [(ngModel)]="field.value"
                                                [ngClass]="{'text-red': field.error}" (change)="onChangeField(field)"
                                                [placeholder]="field.placeholder" [searchable]="'true'"
                                                id="{{field.field_name}}" name="{{field.field_name}}" tabindex="-1"
                                                aria-hidden="true"
                                                class="custom form-control select-wo-border pl-0 custome-select-design select2-hidden-accessible">
                                                <ng-option *ngFor="let option of field.options; let i = index"
                                                    [value]="i+1">{{option}}</ng-option>
                                            </ng-select>
                                            <ng-select *ngIf="field.is_master" [(ngModel)]="field.value"
                                                [ngClass]="{'text-red': field.error}" (change)="onChangeField(field)"
                                                [placeholder]="field.placeholder" [searchable]="'true'"
                                                [multiple]="true" [maxSelectedItems]="field.max"
                                                id="{{field.field_name}}" name="{{field.field_name}}" tabindex="-1"
                                                aria-hidden="true"
                                                class="custom form-control select-wo-border pl-0 custome-select-design select2-hidden-accessible">
                                                <ng-option *ngFor="let option of field.options; let i = index"
                                                    [value]="option._id"
                                                    [disabled]="option.disabled">{{option.option_name}}</ng-option>
                                            </ng-select>
                                        </div>
                                        <div class="mt-10px" *ngIf="isFile(field)">
                                            <span *ngIf="field.placeholder"
                                                class="color-light font-12px">{{field.placeholder}}</span>
                                            <div class="job-manager-uploaded-files">
                                                <div class="phfiles" style="font-size:13px; color:#ccc">
                                                    <div class="job-manager-uploaded-file c27-uploaded-image" style="display: flex;align-items: center;flex-wrap: wrap;"
                                                        *ngIf="field.file_type == '1'">
                                                        <div class="job-manager-uploaded-file-preview img-style-1" 
                                                        style="position: relative;" 
                                                        *ngFor="let address of field.value; let i = index">
                                                            <div class="delete-icon" (click)="deleteFile(i, field.value)">
                                                                <img src="/assets/img/icons8-trash-can.svg" style="padding: 3px;margin: 0;" />
                                                            </div>
                                                            <img src="{{address}}" class="img-style-1">
                                                        </div>
                                                    </div>
                                                    <div class="job-manager-uploaded-file c27-uploaded-image"
                                                        *ngIf="field.file_type == '2'">
                                                        <div *ngFor="let address of field.value">
                                                            <div class="delete-icon-files"  [ngClass]="{'delete-icon-files-3': field.placeholder}" (click)="deleteFile(i, field.value)">
                                                                <img src="/assets/img/icons8-trash-can.svg" style="padding: 3px;margin: 0;" />
                                                            </div>
                                                            <video controls width="250">
                                                                <source src="{{address}}">
                                                            </video>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div style="display:flex">
                                                <svg style="width: 30px;margin-right: 10px;" viewBox="25 25 50 50"
                                                    *ngIf="inUpload">
                                                    <circle class="path" cx="50" cy="50" r="20" fill="none"
                                                        stroke-width="2" stroke-miterlimit="10">
                                                    </circle>
                                                </svg>
                                                <input type="file" #inputFile [multiple]="field.multiple_files"  
                                                    [ngClass]="{'text-red': field.error}"
                                                    (change)="onChangeFile($event, inputFile, field)"
                                                    name="{{field.field_name}}" id="{{field.field_name}}"
                                                    class="form-control input-sm pl-0" />
                                            </div>
                                        </div>
                                        <div class="mt-10px" *ngIf="isDate(field)">
                                            <input type="date" [(ngModel)]="field.value"
                                                [ngClass]="{'text-red': field.error}" (input)="onChangeField(field)"
                                                name="{{field.field_name}}" id="{{field.field_name}}"
                                                class="form-control input-sm pl-0" placeholder="{{field.placeholder}}">
                                        </div>
                                        <span *ngIf="field.error" class="text-red">{{field.errorMsg}}</span>
                                        <div>
                                            <span *ngIf="field.hint" class="small-text-form {{colorClass('color-')}}">
                                                <svg class="svg-inline--fa fa-arrow-alt-circle-up fa-w-16"
                                                    aria-hidden="true" focusable="false" data-prefix="far"
                                                    data-icon="arrow-alt-circle-up" role="img"
                                                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"
                                                    data-fa-i2svg="">
                                                    <path fill="currentColor"
                                                        d="M256 504c137 0 248-111 248-248S393 8 256 8 8 119 8 256s111 248 248 248zm0-448c110.5 0 200 89.5 200 200s-89.5 200-200 200S56 366.5 56 256 145.5 56 256 56zm20 328h-40c-6.6 0-12-5.4-12-12V256h-67c-10.7 0-16-12.9-8.5-20.5l99-99c4.7-4.7 12.3-4.7 17 0l99 99c7.6 7.6 2.2 20.5-8.5 20.5h-67v116c0 6.6-5.4 12-12 12z">
                                                    </path>
                                                </svg>
                                                {{field.hint}}
                                            </span>
                                            <span class="small-text-form text-suggestion" *ngIf="field.is_suggestion"
                                                (click)="openSuggestion(field)">
                                                <a>{{field.suggestion}}</a>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-row" *ngIf="!hasSectionTwo()">
                                    <div class="form-group col">
                                        <div class="custom-green-btn full-width single-color-btn">
                                            <input (click)="insert()" type="submit" value="Anteprima" id="submitBtn"
                                                class="btn btn-primary btn-block {{colorClass('bg-color-', '')}} border-0 w-100 bigfont text-uppercase font-regular">
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="hasErrors" class="pntmd-error-general" id="pntmd-error-general"
                                    style="margin-top: 7px; text-align: center;">
                                    Alcuni valori inseriti sono errati, mancanti o incompleti.<br>Controlla i campi
                                    evidenziati in rosso.
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
            <section class="job-alert-login-section bg-white plr-40px"
                *ngIf="authenticate && formSettings && hasSectionTwo()">
                <div class="row" [ngClass]="{'bg-error': hasErrors}">
                    <div class="col-lg-8 offset-lg-2">
                        <div class="job-alert-login-box mt-3 toggle-wrapper ">
                            <button
                                class="btn btn-primary w-100 toggle-btn {{colorClass('bg-color-')}} color-white border-0 collapsible"
                                type="button" (click)="toogleProsegui()">
                                <span *ngIf="!onProsegui" class="close-tit">PROSEGUI</span>
                                <span *ngIf="onProsegui" class="open-tit">
                                    Mostra meno
                                </span>
                                <span><svg [ngStyle]="{'transform': onProsegui ? 'rotate(180deg)' : ''}"
                                        class="svg-inline--fa fa-angle-down fa-w-10" aria-hidden="true"
                                        focusable="false" data-prefix="fas" data-icon="angle-down" role="img"
                                        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" data-fa-i2svg="">
                                        <path fill="currentColor"
                                            d="M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z">
                                        </path>
                                    </svg>
                                </span>
                            </button>
                            <div>
                                <p>Completa qui la tua scheda con maggiori dettagli: avrai piú possibilità di essere
                                    contattato/a.</p>
                            </div>
                            <div class="form-design {{colorClass('', '-label')}}" *ngIf="onProsegui">
                                <div class="form-row" *ngFor="let field of fieldsSectionTwo">
                                    <div class="form-group col-xs-12 col-sm-12 col-md-12"
                                        *ngIf="isParentOptionSelected(field)">
                                        <label class="proposta-form-label">{{field.label}}
                                            <span *ngIf="!field.is_required"
                                                class="color-light font-12px">(facoltativo)</span>
                                        </label>
                                        <div class="mt-10px" *ngIf="isText(field)">
                                            <input type="text" [(ngModel)]="field.value"
                                                [ngClass]="{'text-red': field.error}" (input)="onChangeField(field)"
                                                name="{{field.field_name}}" id="{{field.field_name}}"
                                                maxlength="{{field.max}}" class="form-control input-sm pl-0"
                                                placeholder="{{field.placeholder}}" />
                                        </div>
                                        <div class="mt-10px" *ngIf="isNumber(field)">
                                            <input type="number" [(ngModel)]="field.value"
                                                [ngClass]="{'text-red': field.error}" (input)="onChangeField(field)"
                                                name="{{field.field_name}}" id="{{field.field_name}}"
                                                max="{{field.max}}" min="{{field.min}}"
                                                class="form-control input-sm pl-0" placeholder="{{field.placeholder}}">
                                        </div>
                                        <div class="mt-10px" *ngIf="isLongText(field)">
                                            <textarea [(ngModel)]="field.value" [ngClass]="{'text-red': field.error}"
                                                (input)="onChangeField(field)" class="form-control input-sm pl-0"
                                                id="{{field.field_name}}" name="{{field.field_name}}" rows="5"
                                                maxlength="{{field.max}}"
                                                placeholder="{{field.placeholder}}"></textarea>
                                                <div style="text-align: end;" *ngIf="field.max">
                                                    <span class=" small-text-form" style="font-style: normal;">{{field.value ? field.value.length : 0}}/{{field.max}} carratteri</span>
                                                </div>

                                        </div>
                                        <div class="mt-10px" *ngIf="isDropdown(field)">
                                            <ng-select *ngIf="!field.is_master" [(ngModel)]="field.value"
                                                [ngClass]="{'text-red': field.error}" (change)="onChangeField(field)"
                                                [placeholder]="field.placeholder" [searchable]="'true'"
                                                id="{{field.field_name}}" name="{{field.field_name}}" tabindex="-1"
                                                aria-hidden="true"
                                                class="custom form-control select-wo-border pl-0 custome-select-design select2-hidden-accessible">
                                                <ng-option *ngFor="let option of field.options; let i = index"
                                                    [value]="i+1">{{option}}</ng-option>
                                            </ng-select>
                                            <ng-select *ngIf="field.is_master" [(ngModel)]="field.value"
                                                [ngClass]="{'text-red': field.error}" (change)="onChangeField(field)"
                                                [placeholder]="field.placeholder" [searchable]="'true'"
                                                [multiple]="true" [maxSelectedItems]="field.max"
                                                id="{{field.field_name}}" name="{{field.field_name}}" tabindex="-1"
                                                aria-hidden="true"
                                                class="custom form-control select-wo-border pl-0 custome-select-design select2-hidden-accessible">
                                                <ng-option *ngFor="let option of field.options; let i = index"
                                                    [value]="option._id"
                                                    [disabled]="option.disabled">{{option.option_name}}</ng-option>
                                            </ng-select>
                                        </div>
                                        <div class="mt-10px" *ngIf="isFile(field)">
                                            <span *ngIf="field.placeholder"
                                                class="color-light font-12px">{{field.placeholder}}</span>
                                            <div class="job-manager-uploaded-files">
                                                <div class="phfiles" style="font-size:13px; color:#ccc">
                                                    <div class="job-manager-uploaded-file c27-uploaded-image" style="display: flex;align-items: center;flex-wrap: wrap;"
                                                        *ngIf="field.file_type == '1'">
                                                        <div class="job-manager-uploaded-file-preview img-style-1" 
                                                            style="position: relative;" 
                                                            *ngFor="let address of field.value; let i = index">
                                                            <div class="delete-icon" (click)="deleteFile(i, field.value)">
                                                                <img src="/assets/img/icons8-trash-can.svg" style="padding: 3px;margin: 0;" />
                                                            </div>
                                                            <img src="{{address}}" class="img-style-1">
                                                        </div>
                                                    </div>
                                                    <div class="job-manager-uploaded-file c27-uploaded-image"
                                                        *ngIf="field.file_type == '2'">
                                                        <div *ngFor="let address of field.value">
                                                            <div class="delete-icon-files"  [ngClass]="{'delete-icon-files-3': field.placeholder}" (click)="deleteFile(i, field.value)">
                                                                <img src="/assets/img/icons8-trash-can.svg" style="padding: 3px;margin: 0;" />
                                                            </div>
                                                            <video controls width="250">
                                                                <source src="{{address}}">
                                                            </video>
                                                        </div>
                                                    </div>
                                                    <div class="job-manager-uploaded-file c27-uploaded-image"
                                                        *ngIf="field.file_type == '3'">
                                                        <div *ngFor="let address of field.value">
                                                            <div class="delete-icon-files" [ngClass]="{'delete-icon-files-3': field.placeholder}" (click)="deleteFile(i, field.value)">
                                                                <img src="/assets/img/icons8-trash-can.svg" style="padding: 3px;margin: 0;" />
                                                            </div>
                                                            <embed id="{{address}}" [src]="address | safe" width="250" height="400" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div style="display:flex">
                                                <svg style="width: 30px;margin-right: 10px;" viewBox="25 25 50 50"
                                                    *ngIf="inUpload">
                                                    <circle class="path" cx="50" cy="50" r="20" fill="none"
                                                        stroke-width="2" stroke-miterlimit="10">
                                                    </circle>
                                                </svg>
                                                <input type="file" #inputFile [multiple]="field.multiple_files"
                                                    [ngClass]="{'text-red': field.error}"
                                                    (change)="onChangeFile($event, inputFile, field)"
                                                    name="{{field.field_name}}" id="{{field.field_name}}"
                                                    class="form-control input-sm pl-0" />
                                            </div>
                                        </div>
                                        <div class="mt-10px" *ngIf="isDate(field)">
                                            <input type="date" [(ngModel)]="field.value"
                                                [ngClass]="{'text-red': field.error}" (input)="onChangeField(field)"
                                                name="{{field.field_name}}" id="{{field.field_name}}"
                                                class="form-control input-sm pl-0" placeholder="{{field.placeholder}}">
                                        </div>
                                        <span *ngIf="field.error" class="text-red">{{field.errorMsg}}</span>
                                        <div>
                                            <span *ngIf="field.hint" class="small-text-form {{colorClass('color-')}}">
                                                <svg class="svg-inline--fa fa-arrow-alt-circle-up fa-w-16"
                                                    aria-hidden="true" focusable="false" data-prefix="far"
                                                    data-icon="arrow-alt-circle-up" role="img"
                                                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"
                                                    data-fa-i2svg="">
                                                    <path fill="currentColor"
                                                        d="M256 504c137 0 248-111 248-248S393 8 256 8 8 119 8 256s111 248 248 248zm0-448c110.5 0 200 89.5 200 200s-89.5 200-200 200S56 366.5 56 256 145.5 56 256 56zm20 328h-40c-6.6 0-12-5.4-12-12V256h-67c-10.7 0-16-12.9-8.5-20.5l99-99c4.7-4.7 12.3-4.7 17 0l99 99c7.6 7.6 2.2 20.5-8.5 20.5h-67v116c0 6.6-5.4 12-12 12z">
                                                    </path>
                                                </svg>
                                                {{field.hint}}
                                            </span>
                                            <span class="small-text-form text-suggestion" *ngIf="field.is_suggestion"
                                                (click)="openSuggestion(field)">
                                                <a>{{field.suggestion}}</a>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="form-group col">
                                        <div class="custom-green-btn full-width single-color-btn">
                                            <input (click)="insert()" type="submit" value="Anteprima" id="submitBtn"
                                                class="btn btn-primary btn-block {{colorClass('bg-color-', '')}} border-0 w-100 bigfont text-uppercase font-regular">
                                        </div>
                                        <div class="pntmd-error-general" id="pntmd-error-general"
                                            style="margin-top:7px; text-align: center; display: none; ">Alcuni valori
                                            inseriti sono errati, mancanti o incompleti.<br>Controlla i campi
                                            evidenziati in
                                            rosso.</div>
                                    </div>
                                </div>
                                <div *ngIf="hasErrors" class="pntmd-error-general" id="pntmd-error-general"
                                    style="margin-top: 7px; text-align: center;">
                                    Alcuni valori inseriti sono errati, mancanti o incompleti.<br>Controlla i campi
                                    evidenziati in rosso.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </form>
    </div>
</div>

<div *ngIf="isEdit">
    <div class="container">
        <section class="page-header-padding bg-white account-page edit-account left-div-section">
            <div class="row">
                <div class="col-md-12 col-lg-2 col-xl-2">
                    <div class="account-tab">
                        <ul class="list-group">
                            <li class="list-group-item">
                                <a routerLink="/my-account/edit-account">
                                    <svg class="svg-inline--fa fa-user fa-w-14 d-block d-lg-none account-icon"
                                        aria-hidden="true" focusable="false" data-prefix="far" data-icon="user"
                                        role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"
                                        data-fa-i2svg="">
                                        <path fill="currentColor"
                                            d="M313.6 304c-28.7 0-42.5 16-89.6 16-47.1 0-60.8-16-89.6-16C60.2 304 0 364.2 0 438.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-25.6c0-74.2-60.2-134.4-134.4-134.4zM400 464H48v-25.6c0-47.6 38.8-86.4 86.4-86.4 14.6 0 38.3 16 89.6 16 51.7 0 74.9-16 89.6-16 47.6 0 86.4 38.8 86.4 86.4V464zM224 288c79.5 0 144-64.5 144-144S303.5 0 224 0 80 64.5 80 144s64.5 144 144 144zm0-240c52.9 0 96 43.1 96 96s-43.1 96-96 96-96-43.1-96-96 43.1-96 96-96z">
                                        </path>
                                    </svg><!-- <i class="far fa-user d-block d-lg-none account-icon"></i> -->
                                    <span class="d-none d-lg-block">Account</span>
                                </a>
                            </li>
                            <li class="list-group-item" [ngClass]="{'list-group-li active' : !isCerca}">
                                <a routerLink="/my-account/my-listings">
                                    Proposte
                                    <div *ngIf="countMatchs.proposal_matchs > 0" class="badge badge-secondary"> <svg
                                            class="svg-inline--fa fa-envelope fa-w-16" aria-hidden="true"
                                            focusable="false" data-prefix="fa" data-icon="envelope" role="img"
                                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
                                            <path fill="currentColor"
                                                d="M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z">
                                            </path>
                                        </svg>
                                        <span style="margin-left: 5px;">{{countMatchs.proposal_matchs}}</span>
                                    </div>
                                </a>
                            </li>
                            <li class="list-group-item" [ngClass]="{'list-group-li active' : isCerca}">
                                <a routerLink="/my-account/my-search">
                                    Ricerche
                                    <div *ngIf="countMatchs.search_matchs !== 0" class="badge badge-success"> <svg
                                            class="svg-inline--fa fa-envelope fa-w-16" aria-hidden="true"
                                            focusable="false" data-prefix="fa" data-icon="envelope" role="img"
                                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
                                            <path fill="currentColor"
                                                d="M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z">
                                            </path>
                                        </svg>
                                        <span style="margin-left: 5px;">{{countMatchs.search_matchs}}</span>
                                    </div>
                                </a>
                            </li>
                            <li class="list-group-item"><a routerLink="/my-account/logout">Logout</a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-md-12 col-lg-10 col-xl-10">
                    <div class="form-design">
                        <div class="account-form-box">
                            <form role="form" id="propesta-form" class="after-login-formdesign">
                                <div class="account-form-title {{colorClass('color-')}}">
                                    <span class="rounded-circle">
                                        <svg class="svg-inline--fa fa-notes-medical fa-w-12 color-white"
                                            aria-hidden="true" focusable="false" data-prefix="fas"
                                            data-icon="notes-medical" role="img" xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 384 512" data-fa-i2svg="">
                                            <path fill="currentColor"
                                                d="M336 64h-80c0-35.3-28.7-64-64-64s-64 28.7-64 64H48C21.5 64 0 85.5 0 112v352c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48zM192 40c13.3 0 24 10.7 24 24s-10.7 24-24 24-24-10.7-24-24 10.7-24 24-24zm96 304c0 4.4-3.6 8-8 8h-56v56c0 4.4-3.6 8-8 8h-48c-4.4 0-8-3.6-8-8v-56h-56c-4.4 0-8-3.6-8-8v-48c0-4.4 3.6-8 8-8h56v-56c0-4.4 3.6-8 8-8h48c4.4 0 8 3.6 8 8v56h56c4.4 0 8 3.6 8 8v48zm0-192c0 4.4-3.6 8-8 8H104c-4.4 0-8-3.6-8-8v-16c0-4.4 3.6-8 8-8h176c4.4 0 8 3.6 8 8v16z">
                                            </path>
                                        </svg><!-- <i class="fas fa-notes-medical color-white"></i> -->
                                    </span>
                                    <h5>{{title}}</h5>
                                </div>
                                <div class="job-alert-login-box">
                                    <div *ngIf="!authenticate" class="job-alert-login-body">
                                        <label class="{{colorClass('color-', '')}}">Hai un account?</label>
                                        <div class="account-sign-in {{colorClass('color-', '')}}">
                                            <p>Per effettuare l'inserimento devi accedere al tuo account o
                                                eseguire
                                                un'iscrizione
                                                gratuita. </p>
                                            <a class="btn btn-primary btn-pink" (click)="openSingin()" style="color: #fff;">
                                                <svg class="svg-inline--fa fa-lock fa-w-14" aria-hidden="true"
                                                    focusable="false" data-prefix="fas" data-icon="lock" role="img"
                                                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"
                                                    data-fa-i2svg="">
                                                    <path fill="currentColor"
                                                        d="M400 224h-24v-72C376 68.2 307.8 0 224 0S72 68.2 72 152v72H48c-26.5 0-48 21.5-48 48v192c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V272c0-26.5-21.5-48-48-48zm-104 0H152v-72c0-39.7 32.3-72 72-72s72 32.3 72 72v72z">
                                                    </path>
                                                </svg><!-- <i class="fas fa-lock"></i> --> Clicca qui </a>
                                        </div>
                                    </div>
                                    <div class="form-design {{colorClass('', '-label')}}"
                                        *ngIf="authenticate && formSettings">
                                        <div class="form-row" *ngFor="let field of fieldsSectionOne">
                                            <div class="form-group col-xs-12 col-sm-12 col-md-12"
                                                *ngIf="isParentOptionSelected(field)">
                                                <label class="proposta-form-label">{{field.label}}
                                                    <span *ngIf="!field.is_required"
                                                        class="color-light font-12px">(facoltativo)</span>
                                                </label>
                                                <div class="mt-10px" *ngIf="isText(field)">
                                                    <input type="text" [(ngModel)]="field.value"
                                                        [ngClass]="{'text-red': field.error}"
                                                        (input)="onChangeField(field)" name="{{field.field_name}}"
                                                        id="{{field.field_name}}" maxlength="{{field.max}}"
                                                        class="form-control input-sm pl-0"
                                                        placeholder="{{field.placeholder}}" />
                                                </div>
                                                <div class="mt-10px" *ngIf="isNumber(field)">
                                                    <input type="number" [(ngModel)]="field.value"
                                                        [ngClass]="{'text-red': field.error}"
                                                        (input)="onChangeField(field)" name="{{field.field_name}}"
                                                        id="{{field.field_name}}" max="{{field.max}}"
                                                        min="{{field.min}}" class="form-control input-sm pl-0"
                                                        placeholder="{{field.placeholder}}">
                                                </div>
                                                <div class="mt-10px" *ngIf="isLongText(field)">
                                                    <textarea [(ngModel)]="field.value"
                                                        [ngClass]="{'text-red': field.error}"
                                                        (input)="onChangeField(field)"
                                                        class="form-control input-sm pl-0" id="{{field.field_name}}"
                                                        name="{{field.field_name}}" rows="5" maxlength="{{field.max}}"
                                                        placeholder="{{field.placeholder}}"></textarea>
                                                        <div style="text-align: end;" *ngIf="field.max">
                                                            <span class=" small-text-form" style="font-style: normal;">{{field.value ? field.value.length : 0}}/{{field.max}} carratteri</span>
                                                        </div>
                                                </div>
                                                <div class="mt-10px" *ngIf="isDropdown(field)">
                                                    <ng-select *ngIf="!field.is_master" [(ngModel)]="field.value"
                                                        [ngClass]="{'text-red': field.error}"
                                                        (change)="onChangeField(field)"
                                                        [placeholder]="field.placeholder" [searchable]="'true'"
                                                        id="{{field.field_name}}" name="{{field.field_name}}"
                                                        tabindex="-1" aria-hidden="true"
                                                        class="custom form-control select-wo-border pl-0 custome-select-design select2-hidden-accessible">
                                                        <ng-option *ngFor="let option of field.options; let i = index"
                                                            [value]="i+1">{{option}}</ng-option>
                                                    </ng-select>
                                                    <ng-select *ngIf="field.is_master" [(ngModel)]="field.value"
                                                        [ngClass]="{'text-red': field.error}"
                                                        (change)="onChangeField(field)"
                                                        [placeholder]="field.placeholder" [searchable]="'true'"
                                                        [multiple]="true" [maxSelectedItems]="field.max"
                                                        id="{{field.field_name}}" name="{{field.field_name}}"
                                                        tabindex="-1" aria-hidden="true"
                                                        class="custom form-control select-wo-border pl-0 custome-select-design select2-hidden-accessible">
                                                        <ng-option *ngFor="let option of field.options; let i = index" 
                                                            [value]="option._id"
                                                            [disabled]="option.disabled">{{option.option_name}}
                                                        </ng-option>
                                                    </ng-select>
                                                </div>
                                                <div class="mt-10px" *ngIf="isFile(field)">
                                                    <span *ngIf="field.placeholder"
                                                        class="color-light font-12px">{{field.placeholder}}</span>
                                                    <div class="job-manager-uploaded-files">
                                                        <div class="phfiles" style="font-size:13px; color:#ccc">
                                                            <div class="job-manager-uploaded-file c27-uploaded-image" style="display: flex;align-items: center;flex-wrap: wrap;"
                                                                *ngIf="field.file_type == '1'">
                                                                <div class="job-manager-uploaded-file-preview img-style-1" 
                                                                    style="position: relative;" 
                                                                    *ngFor="let address of field.value; let i = index">
                                                                    <div class="delete-icon" (click)="deleteFile(i, field.value)">
                                                                        <img src="/assets/img/icons8-trash-can.svg" style="padding: 3px;margin: 0;" />
                                                                    </div>
                                                                    <img src="{{address}}" class="img-style-1">
                                                                </div>
                                                            </div>
                                                            <div class="job-manager-uploaded-file c27-uploaded-image"
                                                                *ngIf="field.file_type == '2'">
                                                                <div *ngFor="let address of field.value">
                                                                    <div class="delete-icon-files"  [ngClass]="{'delete-icon-files-3': field.placeholder}" (click)="deleteFile(i, field.value)">
                                                                        <img src="/assets/img/icons8-trash-can.svg" style="padding: 3px;margin: 0;" />
                                                                    </div>
                                                                    <video controls width="250">
                                                                        <source src="{{address}}">
                                                                    </video>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div style="display:flex">
                                                        <svg style="width: 30px;margin-right: 10px;"
                                                            viewBox="25 25 50 50" *ngIf="inUpload">
                                                            <circle class="path" cx="50" cy="50" r="20" fill="none"
                                                                stroke-width="2" stroke-miterlimit="10">
                                                            </circle>
                                                        </svg>
                                                        <input type="file" #inputFile [multiple]="field.multiple_files"
                                                            [ngClass]="{'text-red': field.error}"
                                                            (change)="onChangeFile($event, inputFile, field)"
                                                            name="{{field.field_name}}" id="{{field.field_name}}"
                                                            class="form-control input-sm pl-0" />
                                                    </div>
                                                </div>
                                                <div class="mt-10px" *ngIf="isDate(field)">
                                                    <input type="date" [(ngModel)]="field.value"
                                                        [ngClass]="{'text-red': field.error}"
                                                        (input)="onChangeField(field)" name="{{field.field_name}}"
                                                        id="{{field.field_name}}" class="form-control input-sm pl-0"
                                                        placeholder="{{field.placeholder}}">
                                                </div>
                                                <span *ngIf="field.error" class="text-red">{{field.errorMsg}}</span>
                                                <div>
                                                    <span *ngIf="field.hint"
                                                        class="small-text-form {{colorClass('color-')}}">
                                                        <svg class="svg-inline--fa fa-arrow-alt-circle-up fa-w-16"
                                                            aria-hidden="true" focusable="false" data-prefix="far"
                                                            data-icon="arrow-alt-circle-up" role="img"
                                                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"
                                                            data-fa-i2svg="">
                                                            <path fill="currentColor"
                                                                d="M256 504c137 0 248-111 248-248S393 8 256 8 8 119 8 256s111 248 248 248zm0-448c110.5 0 200 89.5 200 200s-89.5 200-200 200S56 366.5 56 256 145.5 56 256 56zm20 328h-40c-6.6 0-12-5.4-12-12V256h-67c-10.7 0-16-12.9-8.5-20.5l99-99c4.7-4.7 12.3-4.7 17 0l99 99c7.6 7.6 2.2 20.5-8.5 20.5h-67v116c0 6.6-5.4 12-12 12z">
                                                            </path>
                                                        </svg>
                                                        {{field.hint}}
                                                    </span>
                                                    <span class="small-text-form text-suggestion"
                                                        *ngIf="field.is_suggestion" (click)="openSuggestion(field)">
                                                        <a>{{field.suggestion}}</a>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-row" *ngIf="!hasSectionTwo()">
                                            <div class="form-group col">
                                                <div class="custom-green-btn full-width single-color-btn">
                                                    <input (click)="insert()" type="submit" value="Anteprima"
                                                        id="submitBtn"
                                                        class="btn btn-primary btn-block {{colorClass('bg-color-', '')}} border-0 w-100 bigfont text-uppercase font-regular">
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="hasErrors" class="pntmd-error-general" id="pntmd-error-general"
                                            style="margin-top: 7px; text-align: center;">
                                            Alcuni valori inseriti sono errati, mancanti o
                                            incompleti.<br>Controlla i campi
                                            evidenziati in rosso.
                                        </div>
                                    </div>

                                </div>
                                <div class="job-alert-login-box mt-3 toggle-wrapper ">
                                    <button
                                        class="btn btn-primary w-100 toggle-btn {{colorClass('bg-color-')}} color-white border-0 collapsible"
                                        type="button" (click)="toogleProsegui()">
                                        <span *ngIf="!onProsegui" class="close-tit">PROSEGUI</span>
                                        <span *ngIf="onProsegui" class="open-tit">
                                            Mostra meno
                                        </span>
                                        <span><svg [ngStyle]="{'transform': onProsegui ? 'rotate(180deg)' : ''}"
                                                class="svg-inline--fa fa-angle-down fa-w-10" aria-hidden="true"
                                                focusable="false" data-prefix="fas" data-icon="angle-down" role="img"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"
                                                data-fa-i2svg="">
                                                <path fill="currentColor"
                                                    d="M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z">
                                                </path>
                                            </svg>
                                        </span>
                                    </button>
                                    <div>
                                        <p>Completa qui la tua scheda con maggiori dettagli: avrai piú
                                            possibilità di essere
                                            contattato/a.</p>
                                    </div>
                                    <div class="form-design {{colorClass('', '-label')}}" *ngIf="onProsegui">
                                        <div class="form-row" *ngFor="let field of fieldsSectionTwo">
                                            <div class="form-group col-xs-12 col-sm-12 col-md-12"
                                                *ngIf="isParentOptionSelected(field)">
                                                <label class="proposta-form-label">{{field.label}}
                                                    <span *ngIf="!field.is_required"
                                                        class="color-light font-12px">(facoltativo)</span>
                                                </label>
                                                <div class="mt-10px" *ngIf="isText(field)">
                                                    <input type="text" [(ngModel)]="field.value"
                                                        [ngClass]="{'text-red': field.error}"
                                                        (input)="onChangeField(field)" name="{{field.field_name}}"
                                                        id="{{field.field_name}}" maxlength="{{field.max}}"
                                                        class="form-control input-sm pl-0"
                                                        placeholder="{{field.placeholder}}" />
                                                </div>
                                                <div class="mt-10px" *ngIf="isNumber(field)">
                                                    <input type="number" [(ngModel)]="field.value"
                                                        [ngClass]="{'text-red': field.error}"
                                                        (input)="onChangeField(field)" name="{{field.field_name}}"
                                                        id="{{field.field_name}}" max="{{field.max}}"
                                                        min="{{field.min}}" class="form-control input-sm pl-0"
                                                        placeholder="{{field.placeholder}}">
                                                </div>
                                                <div class="mt-10px" *ngIf="isLongText(field)">
                                                    <textarea [(ngModel)]="field.value"
                                                        [ngClass]="{'text-red': field.error}"
                                                        (input)="onChangeField(field)"
                                                        class="form-control input-sm pl-0" id="{{field.field_name}}"
                                                        name="{{field.field_name}}" rows="5" maxlength="{{field.max}}"
                                                        placeholder="{{field.placeholder}}"></textarea>
                                                        <div style="text-align: end;" *ngIf="field.max">
                                                            <span class=" small-text-form" style="font-style: normal;">{{field.value ? field.value.length : 0}}/{{field.max}} carratteri</span>
                                                        </div>
                                                </div>
                                                <div class="mt-10px" *ngIf="isDropdown(field)">
                                                    <ng-select *ngIf="!field.is_master" [(ngModel)]="field.value"
                                                        [ngClass]="{'text-red': field.error}"
                                                        (change)="onChangeField(field)"
                                                        [placeholder]="field.placeholder" [searchable]="'true'"
                                                        id="{{field.field_name}}" name="{{field.field_name}}"
                                                        tabindex="-1" aria-hidden="true"
                                                        class="custom form-control select-wo-border pl-0 custome-select-design select2-hidden-accessible">
                                                        <ng-option *ngFor="let option of field.options; let i = index"
                                                            [value]="i+1">{{option}}</ng-option>
                                                    </ng-select>
                                                    <ng-select *ngIf="field.is_master" [(ngModel)]="field.value"
                                                        [ngClass]="{'text-red': field.error}"
                                                        (change)="onChangeField(field)"
                                                        [placeholder]="field.placeholder" [searchable]="'true'"
                                                        [multiple]="true" [maxSelectedItems]="field.max"
                                                        id="{{field.field_name}}" name="{{field.field_name}}"
                                                        tabindex="-1" aria-hidden="true"
                                                        class="custom form-control select-wo-border pl-0 custome-select-design select2-hidden-accessible">
                                                        <ng-option *ngFor="let option of field.options; let i = index"
                                                            [value]="option._id"
                                                            [disabled]="option.disabled">{{option.option_name}}
                                                        </ng-option>
                                                    </ng-select>
                                                </div>
                                                <div class="mt-10px" *ngIf="isFile(field)">
                                                    <span *ngIf="field.placeholder"
                                                        class="color-light font-12px">{{field.placeholder}}</span>
                                                    <div class="job-manager-uploaded-files">
                                                        <div class="phfiles" style="font-size:13px; color:#ccc">
                                                            <div class="job-manager-uploaded-file c27-uploaded-image" style="display: flex;align-items: center;flex-wrap: wrap;"
                                                                *ngIf="field.file_type == '1'">
                                                                <div class="job-manager-uploaded-file-preview img-style-1" 
                                                                    style="position: relative;" 
                                                                    *ngFor="let address of field.value; let i = index">
                                                                    <div class="delete-icon" (click)="deleteFile(i, field.value)">
                                                                        <img src="/assets/img/icons8-trash-can.svg" style="padding: 3px;margin: 0;" />
                                                                    </div>
                                                                    <img src="{{address}}" class="img-style-1">
                                                                </div>
                                                            </div>
                                                            <div class="job-manager-uploaded-file c27-uploaded-image" *ngIf="field.file_type == '2'">
                                                                <div *ngFor="let address of field.value">
                                                                    <div class="delete-icon-files" [ngClass]="{'delete-icon-files-3': field.placeholder}" (click)="deleteFile(i, field.value)">
                                                                        <img src="/assets/img/icons8-trash-can.svg" style="padding: 3px;margin: 0;" />
                                                                    </div>
                                                                    <video controls width="250">
                                                                        <source src="{{address}}">
                                                                    </video>
                                                                </div>
                                                            </div>
                                                            <div class="job-manager-uploaded-file c27-uploaded-image" *ngIf="field.file_type == '3'">
                                                                <div *ngFor="let address of field.value">
                                                                    <div class="delete-icon-files" [ngClass]="{'delete-icon-files-3': field.placeholder}" (click)="deleteFile(i, field.value)">
                                                                        <img src="/assets/img/icons8-trash-can.svg" style="padding: 3px;margin: 0;" />
                                                                    </div>
                                                                    <embed id="{{address}}"  [src]="address | safe" width="250" height="400" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div style="display: flex">
                                                        <svg style="width: 30px;margin-right: 10px;"
                                                            viewBox="25 25 50 50" *ngIf="inUpload">
                                                            <circle class="path" cx="50" cy="50" r="20" fill="none"
                                                                stroke-width="2" stroke-miterlimit="10">
                                                            </circle>
                                                        </svg>
                                                        <input type="file" #inputFile [multiple]="field.multiple_files"
                                                            [ngClass]="{'text-red': field.error}"
                                                            (change)="onChangeFile($event, inputFile, field)"
                                                            name="{{field.field_name}}" id="{{field.field_name}}"
                                                            class="form-control input-sm pl-0" />
                                                    </div>
                                                </div>
                                                <div class="mt-10px" *ngIf="isDate(field)">
                                                    <input type="date" [(ngModel)]="field.value"
                                                        [ngClass]="{'text-red': field.error}"
                                                        (input)="onChangeField(field)" name="{{field.field_name}}"
                                                        id="{{field.field_name}}" class="form-control input-sm pl-0"
                                                        placeholder="{{field.placeholder}}">
                                                </div>
                                                <span *ngIf="field.error" class="text-red">{{field.errorMsg}}</span>
                                                <div>
                                                    <span *ngIf="field.hint"
                                                        class="small-text-form {{colorClass('color-')}}">
                                                        <svg class="svg-inline--fa fa-arrow-alt-circle-up fa-w-16"
                                                            aria-hidden="true" focusable="false" data-prefix="far"
                                                            data-icon="arrow-alt-circle-up" role="img"
                                                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"
                                                            data-fa-i2svg="">
                                                            <path fill="currentColor"
                                                                d="M256 504c137 0 248-111 248-248S393 8 256 8 8 119 8 256s111 248 248 248zm0-448c110.5 0 200 89.5 200 200s-89.5 200-200 200S56 366.5 56 256 145.5 56 256 56zm20 328h-40c-6.6 0-12-5.4-12-12V256h-67c-10.7 0-16-12.9-8.5-20.5l99-99c4.7-4.7 12.3-4.7 17 0l99 99c7.6 7.6 2.2 20.5-8.5 20.5h-67v116c0 6.6-5.4 12-12 12z">
                                                            </path>
                                                        </svg>
                                                        {{field.hint}}
                                                    </span>
                                                    <span class="small-text-form text-suggestion"
                                                        *ngIf="field.is_suggestion" (click)="openSuggestion(field)">
                                                        <a>{{field.suggestion}}</a>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-row">
                                            <div class="form-group col">
                                                <div class="custom-green-btn full-width single-color-btn">
                                                    <input (click)="insert()" type="submit" value="Anteprima"
                                                        id="submitBtn"
                                                        class="btn btn-primary btn-block {{colorClass('bg-color-', '')}} border-0 w-100 bigfont text-uppercase font-regular">
                                                </div>
                                                <div class="pntmd-error-general" id="pntmd-error-general"
                                                    style="margin-top:7px; text-align: center; display: none; ">
                                                    Alcuni valori
                                                    inseriti sono errati, mancanti o
                                                    incompleti.<br>Controlla i campi
                                                    evidenziati in
                                                    rosso.</div>
                                            </div>
                                        </div>
                                        <div *ngIf="hasErrors" class="pntmd-error-general" id="pntmd-error-general"
                                            style="margin-top: 7px; text-align: center;">
                                            Alcuni valori inseriti sono errati, mancanti o
                                            incompleti.<br>Controlla i campi
                                            evidenziati in rosso.
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>
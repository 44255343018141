import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-come-funziona',
  templateUrl: './come-funziona.component.html',
  styleUrls: ['./come-funziona.component.scss']
})
export class ComeFunzionaComponent implements OnInit {
  popup: string;
  @ViewChild('checosae') checosae;
  @ViewChild('comefareperproporsi') perproporsi;
  @ViewChild('comefarepercercare') percercare;


  constructor(
    private modal: NgbModal,
    private route: ActivatedRoute,
    private title: Title
  ) {
    this.title.setTitle('Come funziona – Cinebooking');
    this.route.params
      .subscribe(params => {
        this.popup = params.popup;
      });
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    if (this.popup === 'che-cosa-e') {
      this.openContent(this.checosae);
    }
    if (this.popup === 'per-proporsi') {
      this.openContent(this.perproporsi);
    }
    if (this.popup === 'per-cercare') {
      this.openContent(this.percercare);
    }
  }

  openContent(content) {
    this.modal.open(content, { centered: true, });
    setTimeout(() => {
      window.document.querySelector('.modal-dialog').scrollIntoView()
    }, 100);
  }

}

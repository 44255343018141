import { AfterViewInit, Component, ElementRef, NgZone, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, UrlSegment } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CountMatch } from 'src/core/count-match.core';
import { CMSPages } from 'src/static/cms-pages';
import { Utils } from 'src/utils/utils';
import { ErrorModalComponent } from '../error-modal/error-modal.component';
import { InserimentoPropostaModalComponent } from '../inserimento-proposta-modal/inserimento-proposta-modal.component';
import { InserimentoRicercaModalComponent } from '../inserimento-ricerca-modal/inserimento-ricerca-modal.component';
import { FormSettingServiceService } from '../services/form-setting-service.service';
import { LoadingService } from '../services/loading.service';
import { MatchService } from '../services/match.service';
import { ProposalService } from '../services/proposal.service';
import { ResearchService } from '../services/research.service';
import { UserService } from '../services/user.service';
import { SingInPopupComponent } from '../sing-in-popup/sing-in-popup.component';
import { SuggestionModalComponent } from '../suggestion-modal/suggestion-modal.component';
import { UploadService } from '../upload.service';

@Component({
  selector: 'app-builder-forms',
  templateUrl: './builder-forms.component.html',
  styleUrls: ['./builder-forms.component.scss']
})
export class BuilderFormsComponent implements OnInit, AfterViewInit {
  slug: string;
  uuid: string;

  formSettings;
  fieldsSectionOne;
  fieldsSectionTwo;

  onProsegui: boolean;
  authenticate: boolean;

  title: string;
  isCerca: boolean;
  isEdit: boolean;
  onSubmit: boolean;
  hasErrors: boolean;

  inUpload: boolean = false;

  @ViewChild('header') header: ElementRef;
  countMatchs: CountMatch = new CountMatch();

  constructor(
    private formSettingService: FormSettingServiceService,
    private uploadService: UploadService,
    private userService: UserService,
    private proposalService: ProposalService,
    private researchService: ResearchService,
    private matchService: MatchService,
    private loadingService: LoadingService,
    private modal: NgbModal,
    private route: ActivatedRoute,
    private router: Router,
    private zone: NgZone
  ) {
    this.loadingService.start();
    this.route.params.subscribe(params => {
        this.slug = params.slug;
        this.uuid = params.uuid;
        this.isCerca = this.slug.includes('cerca');

    });
    this.route.url.subscribe((segments: UrlSegment[]) => {
      this.isEdit = segments.some(s => s.path === 'edit');
    })
  }

  ngOnInit(): void {
    if (!this.slug) {
      return;
    }
    this.userService.isAuthenticated().subscribe((authenticate: boolean) => {
      this.authenticate = authenticate;
      if (!authenticate) {
        this.loadingService.stop();
        return;
      }
      this.instanciateFormSettings();
    }, (err) => {
      this.loadingService.stop();
      this.authenticate = false;
    });
    this.matchService.countMatchs.subscribe((count: CountMatch) => {
      if(!count) {
        return;
      }
      this.countMatchs = count;
    });
  }

  instanciateFormSettings() {
    this.getFormSettings().subscribe((formSettings: { fields: any[] }) => {
      this.formSettings = formSettings;
      for (const field of this.formSettings.fields) {
        if (!field.options || field.options.length == 0) {
          continue;
        }
        if (!field.is_master) {
          continue;
        }
        if (this.isCerca) {
          field.options = Utils.sort(field.options.filter(o => o.is_job == true), 'sort_order');
        } else {
          field.options = Utils.sort(field.options.filter(o => o.is_proposal == true), 'sort_order');
        }
      }
      this.fieldsSectionOne = Utils.sort(formSettings.fields.filter(f => f.section == 1), 'sort_order');
      this.fieldsSectionTwo = Utils.sort(formSettings.fields.filter(f => f.section == 2), 'sort_order');
      this.loadingService.stop();
      if(this.isCerca) {
        this.openInserimentoRicercaModal();
      } else {
        this.openInserimentoPropostaModal();
      }
    }, (error) => {
      this.loadingService.stop();
      console.info(error);
    })
  }

  getFormSettings() {
    if(!this.isEdit) {
      return this.formSettingService.getFormSettings(this.slug);
    }
    return this.formSettingService.editFormSettings(this.slug, this.uuid);
  }

  ngAfterViewInit(): void {
    const cmsPage = CMSPages.page(this.slug);
    this.title = cmsPage.title_from;

    if (this.isEdit) {
      return;
    }
    this.header.nativeElement.innerHTML = cmsPage.content;
  }

  isText(field) {
    return field.display_type.trim() == 'text';
  }

  isDropdown(field) {
    return field.display_type.trim() == 'dropdown' || field.display_type.trim() == 'multi_dropdown';
  }

  isLongText(field) {
    return field.display_type.trim() == 'textarea';

  }

  isFile(field) {
    return field.display_type.trim() == 'file';

  }

  isDate(field) {
    return field.display_type.trim() == 'date';
  }

  isNumber(field) {
    return field.display_type.trim() == 'number';
  }

  isParentOptionSelected(field) {
    if (!field.parent_id) {
      return true;
    }
    const parent = this.formSettings.fields.find(f => f.id == field.parent_id);
    if (!parent) {
      return false;
    }
    if (parent.value == field.parent_option_id) {
      return true;
    }
    return false;
  }

  hasSectionTwo() {
    return this.fieldsSectionTwo.length !== 0;
  }

  toogleProsegui() {
    this.onProsegui = !this.onProsegui;
  }

  countChar(field) {
    if (!field.value) {
      return field.max;
    }
    const char = field.value.length;
    return field.max - char;
  }

  colorClass(pre = '', post = '') {
    return this.isCerca ? `${pre}green${post}` : `${pre}nblue${post}`;
  }

  openSuggestion(field) {
    const tmpLabel = field.suggestion.replace('suggerisci una', '').trim();
    const label = tmpLabel.charAt(0).toUpperCase() + tmpLabel.slice(1);
    const suggestion = this.modal.open(SuggestionModalComponent, { backdrop: true, centered: true });
    suggestion.componentInstance.field = field.field_name;
    suggestion.componentInstance.label = label;
  }

  onChangeFile(event, ref, field) {
    const files = Array.from(event.target.files) as File[];
    for (const file of files) {
      const format = file.name.split('.')[file.name.split('.').length - 1];
      const formats = field.file_format.split('|').map(f => f.trim()?.toLowerCase());
      const mb = file.size / (1024 ** 2);
      if (mb > field.file_size) {
        alert("La dimensione del file è troppo grande.");
        ref.value = null;
        return;
      }
      if (!formats.includes(format?.toLowerCase())) {
        alert(`Formato del file non supportato. I formati disponibili sono: ${formats.join(',')}`);
        ref.value = null;
        return;
      }
    }
    this.inUpload = true;
    this.uploadService.presignedUrl(files).subscribe((response: { presignedUrls: string[] }) => {
      const promises = [];
      for (const i in response.presignedUrls) {
        promises.push(this.uploadService.uploadPresignedUrl(response.presignedUrls[i], files[i]));
      }
      Promise.all(promises).then((data) => {
        if (!field.value) {
          field.value = [];
        }
        const addresses = response.presignedUrls.map(p => p.split('?')[0]);
        if (field.max == field.value.length) {
          for (const address of addresses) {
            field.value.pop();
          }
        }
        for (const address of addresses) {
          field.value.push(address);
        }
        this.inUpload = false;

        setTimeout(() => {
          for (const address of addresses) {
            const embed = document.getElementById(address);
            if (!embed) {
              continue;
            }
            embed.style.width = '250px';
            embed.style.height = '400px';
          }
        }, 250);
      }).catch((err) => {
        console.error(err);
      })
    })
  }

  onChangeField(field) {
    if (field.is_master) {
      this.disableOtherOptions(field);
    }
    if (!this.onSubmit) {
      return;
    }
    this.validateField(field);
  }

  disableOtherOptions(field) {
    if (!field || !field.value || !field.options) {
      this.resetOptionsDisable(field.options);
      return;
    }
    if (field.options.length == 0 || field.value.length == 0) {
      this.resetOptionsDisable(field.options);
      return;
    }
    const iOptions = field.options.findIndex(o => field.value.includes(o._id));
    if (!field.options[iOptions].is_wildcard) {
      this.resetOptionsDisable(field.options);
      return;
    };
    field.value = [field.options[iOptions]._id];
    for (let i = 0; i < field.options.length; i++) {
      if (i == iOptions) {
        continue;
      }
      field.options[i].disabled = true;
    }
  }

  resetOptionsDisable(options) {
    for (const o of options) {
      o.disabled = false;
    }
  }

  validateField(field) {
    const enableValidation = this.isParentOptionSelected(field);
    if (!enableValidation) {
      return;
    }
    if (field.is_required && !field.value) {
      field.error = true;
      field.errorMsg = 'Campo obbligatorio';
      return;
    }
    if (field.is_required && Array.isArray(field.value) && field.value.length == 0) {
      field.error = true;
      field.errorMsg = 'Campo obbligatorio';
      return;
    }
    if (!field.value) {
      this.resetError(field);
      return;
    }
    if (field.file_type == "6" && !this.isEmail(field.value)) {
      field.error = true;
      field.errorMsg = 'Email Deve essere un indirizzo email valido';
      return;
    }
    if (field.file_type == "5" && !this.isNumberPhone(field.value)) {
      field.error = true;
      field.errorMsg = 'Numero di telefono deve essere un numero';
      return;
    }
    if (this.isNumber(field)) {
      if (isNaN(field.value)) {
        field.error = true;
        field.errorMsg = 'Formato non corretto';
        return;
      }
      if (field.min && parseInt(field.value) < field.min) {
        field.error = true;
        field.errorMsg = `Minimo valore consentito: ${field.min}`;
        return;
      }
      if (field.max && parseInt(field.value) > field.max) {
        field.error = true;
        field.errorMsg = `Massimo valore consentito: ${field.max}`;
        return;
      }
    }
    if (this.isText(field) || this.isLongText(field)) {
      if (field.min && field.value.length < field.min) {
        field.error = true;
        field.errorMsg = `Minimo numero di caratteri: ${field.min}`;
        return;
      }
      if (field.max && field.value.length > field.max) {
        field.error = true;
        field.errorMsg = `Massimo numero di caratteri: ${field.max}`;
        return;
      }
    }
    if(this.isFile(field)) {
      if (field.min && parseInt(field.value.length) < field.min) {
        field.error = true;
        field.errorMsg = `Numero minimo di file da caricare: ${field.min}`;
        return;
      }
      if (field.max && parseInt(field.value.length) > field.max) {
        field.error = true;
        field.errorMsg = `Numero massimo di file da caricare: ${field.max}`;
        return;
      }
    }
    this.resetError(field);
  }

  resetError(field) {
    field.error = false;
    field.errorMsg = '';
  }

  isNumberPhone(input) {
    const regex = new RegExp("^[0-9]+$");
    return regex.test(input.toLowerCase());
  }
  
  isEmail(input) {
    const regex = new RegExp(/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
    return regex.test(input);
  }

  openError() {
    const error = this.modal.open(ErrorModalComponent, { centered: true });
    error.componentInstance.onClose.subscribe(() => {
      error.close();
      setTimeout(() => {
        const errors = this.formSettings.fields.filter(f => f.error);
        if (!errors || errors.length == 0) {
          this.scrollOnForm();
          return;
        }
        const fieldHTML = document.querySelector(`#${errors[0].field_name}`);
        if (fieldHTML) {
          const y = fieldHTML.getBoundingClientRect().top + window.pageYOffset - 100;
          window.scrollTo({top: y, behavior: 'smooth'});
          return;
        }
        this.scrollOnForm();
      }, 300);
    })
  }

  openInserimentoRicercaModal() {
    const popup = this.modal.open(InserimentoRicercaModalComponent, { centered: true, windowClass: 'pntmd-modal-form-ricerca' });
    popup.componentInstance.slug = this.slug;
  }

  openInserimentoPropostaModal() {
    const popup = this.modal.open(InserimentoPropostaModalComponent, { centered: true, windowClass: 'pntmd-modal-form-ricerca' });
    popup.componentInstance.slug = this.slug;
  }

  scrollOnForm() {
    document.querySelector('form').scrollIntoView()
  }

  insert() {
    this.onSubmit = true;
    for (const field of this.formSettings.fields) {
      this.validateField(field);
    }
    if (this.formSettings.fields.filter(f => f.error).length !== 0) {
      this.hasErrors = true;
      this.openError();
      return;
    }

    const ouput = {
      name: this.formSettings.name,
      slug: this.formSettings.slug,
      nice_name: this.formSettings.nice_name,
      label: this.formSettings.slug.replace('cerca', '').replace(/-/g, ' ').toUpperCase(),
      fields: this.formSettings.fields.map(f => this.mapFormField(f)),
      user_id: this.userService.getUserId()
    };

    if (this.isCerca) {
      if (this.isEdit) {
        this.editSearch(ouput);
      } else {
        this.insertSearch(ouput)
      }
    } else {
      if (this.isEdit) {
        this.editProposal(ouput);
      } else {
        this.insertProposal(ouput)
      }
    }
  }

  mapFormField(field) {
    const output: any = {};
    output.field_name = field.field_name;
    output.file_type = field.file_type;
    output.display_type = field.display_type;
    output.label = field.label;
    output.value = field.value;
    output.stringValue = field.value;

    if (!field.value || !this.isDropdown(field)) {
      return output;
    }
    if (!field.is_master) {
      if (!Array.isArray(field.value)) {
        output.stringValue = field.options[field.value - 1];
      } else {
        const opts = [];
        for (const value of field.value) {
          opts.push(field.options[value - 1])
        }
        output.stringValue = opts.join(' , ');
      }
    } else {
      if (!Array.isArray(field.value)) {
        output.stringValue = field.options.find(o => o._id == field.value).option_name;
      } else {
        const opts = [];
        for (const value of field.value) {
          opts.push(field.options.find(o => o._id == value).option_name)
        }
        output.stringValue = opts.join(' , ');
      }
    }
    return output;
  }

  insertSearch(search) {
    this.loadingService.start();
    return this.researchService.insert(search).subscribe((response: any) => {
      this.router.navigateByUrl(`inserimento-ricerca/preview/${this.slug}/${response.uuid}`);
    }, (err) => {
      this.loadingService.stop();
      console.error(err);
    });
  }

  insertProposal(proposal) {
    this.loadingService.start();
    return this.proposalService.insert(proposal).subscribe((response: any) => {
      this.router.navigateByUrl(`inserimento-proposta/preview/${this.slug}/${response.uuid}`);
    }, (err) => {
      this.loadingService.stop();
      console.error(err);
    });
  }

  editSearch(search) {
    this.loadingService.start();
    return this.researchService.edit(search, this.uuid).subscribe((response: any) => {
      this.router.navigateByUrl(`inserimento-ricerca/preview/${this.slug}/${response.uuid}`);
    }, (err) => {
      this.loadingService.stop();
      console.error(err);
    });
  }

  editProposal(proposal) {
    this.loadingService.start();
    return this.proposalService.edit(proposal, this.uuid).subscribe((response: any) => {
      this.router.navigateByUrl(`inserimento-proposta/preview/${this.slug}/${response.uuid}`);
    }, (err) => {
      this.loadingService.stop();
      console.error(err);
    });
  }

  deleteFile(i: number, array: []) {
    array.splice(i, 1);
  }

  openSingin() {
    const singinPopup = this.modal.open(SingInPopupComponent, { centered: true });
    singinPopup.componentInstance.redirect = false;
    this.userService.onAutentichate.subscribe(() => {
      window.location.reload();
    })
  }
}

export class Contact {
    name: string;
    surname: string;
    qualification: string;
    email: string;
    phone: string;
    reason: string;
    hasPremium: boolean;
    message: string;
    status: string;
    user: string;
}
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ResearchService {
  private resource = 'researchs/';

  constructor(
    private http: HttpClient
  ) { }

  read(uuid: string) {
    return this.http.get(`${environment.api}${this.resource}read/${uuid}`);
  }

  insert(research) {
    return this.http.post(`${environment.api}${this.resource}insert`, research);
  }

  edit(research, uuid: string) {
    return this.http.post(`${environment.api}${this.resource}edit/${uuid}`, research);
  }

  publish(uuid: string) {
    return this.http.post(`${environment.api}${this.resource}publish`, { uuid });
  }

  setViews(uuid: string, match_uuid: string) {
    return this.http.post(`${environment.api}${this.resource}setViews`, { uuid, match_uuid });
  }

  delete(uuid: string) {
    return this.http.post(`${environment.api}${this.resource}delete`, { uuid });
  }

  deleteMatch(uuid: string, match_uuid: string) {
    return this.http.post(`${environment.api}${this.resource}deleteMatch`, { uuid, match_uuid });
  }

  deleteAttachemnt(params) {
    return this.http.post(`${environment.api}${this.resource}deleteAttachment`, params);
  }

  listing(user_id: string) {
    return this.http.get(`${environment.api}${this.resource}listing/${user_id}`);
  }
}

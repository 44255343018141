<div class="container">
    <section class="page-header-padding bg-white account-page">
        <div class="row">
            <div class="col-lg-3 col-xl-3"></div>
            <div class="col-md-12 col-lg-6 col-xl-6">
                <div id="login-id">
                    <div class="form-design">
                        <div class="account-form-box">
                            <form role="form" class="cine-booking-form" method="POST" id="login-form">
                                <input type="hidden" name="_token" value="E80t3VLLhMOXUpJUnFUx2zeTy26bweYtCSTURzut">
        
                                <div class="alert alert-danger alert-block" *ngIf="onSingInError">
                                    <button type="button" class="colse" data-dismiss="alert" (click)="onSingInError = false">X</button>
                                    <strong>{{singInError}}</strong>
                                </div>
        
                                <div class="account-form-title">
                                    <h5><svg class="svg-inline--fa fa-user fa-w-14" aria-hidden="true" focusable="false"
                                            data-prefix="fa" data-icon="user" role="img"
                                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg="">
                                            <path fill="currentColor"
                                                d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4z">
                                            </path>
                                        </svg><!-- <i class="fa fa-user"></i> -->Accedi</h5>
                                </div>
                                <div class="form-row">
                                    <div class="form-group col-xs-12 col-sm-12 col-md-12">
                                        <input [(ngModel)]="singinForm.user_email" type="email" name="email" id="email" class="form-control input-sm"
                                        placeholder="Email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$" [required]="true" #singin_mail="ngModel">
                                    <div *ngIf="singinForm.onSubmit && singin_mail.invalid ">
                                        <small *ngIf="singin_mail.errors?.required" style="color: red;">Il campo non può essere vuoto.</small>
                                        <small *ngIf="singin_mail.errors?.pattern" style="color: red;">L'email non risulta valida.</small>
                                    </div>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="form-group col-xs-12 col-sm-12 col-md-12">
                                        <input [(ngModel)]="singinForm.user_pass" type="password" name="password" id="password" class="form-control input-sm"
                                        placeholder="Password" [required]="true" #singin_password="ngModel">
                                    <div *ngIf="singinForm.onSubmit && singin_password.invalid ">
                                        <small *ngIf="singin_password.errors?.required" style="color: red;">Il campo non può essere vuoto.</small>
                                    </div>
                                    </div>
                                </div>
        
                                <p>Inserisci gratis la tua proposta o ricerca:
                                    accedi alla piattaforma web per lavoro e forniture nel Cinema, Televisione,
                                    Pubblicitá, Eventi, Radio, Moda, Musica, Teatro.
                                    Risposte immediate e candidature in "match".
                                    <span class="color-green">Accedi</span>, clicca su "Proponi" o "Cerca" e crea la
                                    scheda.
                                </p>
        
                                <div class="form-row">
                                    <div class="form-group col">
                                        <div class="login-btn">
                                            <input (click)="singin()" type="submit" id="loginBtn" value="Accedi"
                                                class="btn btn-primary btn-block green-btn w-100 bigfont text-uppercase font-medium">
                                            <svg class="svg-inline--fa fa-angle-right fa-w-8" aria-hidden="true"
                                                focusable="false" data-prefix="fas" data-icon="angle-right" role="img"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"
                                                data-fa-i2svg="">
                                                <path fill="currentColor"
                                                    d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z">
                                                </path>
                                            </svg><!-- <i class="fas fa-angle-right"></i> -->
                                        </div>
                                    </div>
                                </div>
        
                                <div class="form-group col-xs-12 col-sm-6" style="padding:0">
                                    <div class="forgot-password">
                                        <a routerLink="/my-account">Non hai un account? Registrati</a>
                                    </div>
                                </div>
        
                                <div class="form-row">
                                    <div class="form-group col-xs-12 col-sm-6">
                                        <div class="form-check md-checkbox">
                                            <input class="form-check-input" type="checkbox" name="exampleCheckbox"
                                                id="exampleRadios1" value="option1">
                                            <label class="form-check-label" for="exampleRadios1">
                                                Ricordati di me
                                            </label>
                                        </div>
                                    </div>
                                    <div class="form-group col-xs-12 col-sm-6">
                                        <div class="forgot-password text-right">
                                            <a routerLink="/my-account/lost-password">Password
                                                dimenticata?</a>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-xl-3"></div>
        </div>
    </section>
</div>
<div class="container">

    <section id="bg-image" data-type="background" data-speed="5" style="background: url(red_7.jpg) no-repeat;"
        class="page-header-padding bg-white f-cinebook-section parallax-bg">
        <div class="row">
            <div class="col-lg-8 offset-lg-2 col-md-10 offset-md-1">
                <div class="containt-box chi-siamo-bold">
                    <h1>PROTEZIONE DATI</h1>

                    <p>Il Decreto Legislativo n. 196 del 30 giugno 2003 (“Codice della Privacy”) è il nuovo regolamento
                        europeo per la protezione dei dati personali (GDPR) hanno la finalità di garantire che il
                        trattamento dei Suoi dati personali si svolga nel rispetto dei diritti, delle libertà
                        fondamentali e della dignità delle persone, con particolare riferimento alla riservatezza e
                        all’identità personale. Pertanto è nostro dovere informarla sulla nostra policy in materia di
                        privacy .</p>

                    <p>1.&nbsp; <strong>Finalità del trattamento</strong></p>

                    <p>1.1 La informiamo, ai sensi dell’art. 13 del Codice in materia di protezione dei dati personali,
                        che i dati personali da Lei forniti all’atto della registrazione al Servizio, ovvero altrimenti
                        acquisiti nell’ambito dell’attività da noi svolta, potranno formare oggetto di trattamento, per
                        le finalità connesse alla fornitura del Servizio medesimo. Dopo la registrazione, ai sensi
                        dell’art. 130 comma 4 del D.LGS. 196/2003 potremo inviarle e-mail relative a servizi analoghi a
                        quelli forniti e lei potrà opporsi a tale invio di e-mail in qualsiasi momento.</p>

                    <p>1.2 I Suoi dati saranno utilizzati, qualora venga fornita l’autorizzazione, per l’invio di
                        informazioni ed offerte commerciali, anche da parte di soggetti terzi comunque facenti parte del
                        medesimo gruppo societario di Cinebooking Italia S.r.l. &nbsp;ai quali sarà possibile per il Titolare
                        comunicare i dati personali da Lei forniti.</p>

                    <p>1.3 I Suoi dati saranno utilizzati, qualora venga fornita l’autorizzazione, per raccogliere dati
                        ed informazioni in via generale e particolare sui Suoi orientamenti e/o preferenze.</p>

                    <p>2.&nbsp; <strong>Modalità del trattamento</strong></p>

                    <p>2.1 I dati sono raccolti dai soggetti indicati al punto 5, secondo le indicazioni del Codice
                        della Privacy, con particolare riguardo alle misure minime di sicurezza previste (artt. 33-34),
                        e del GDPR (art.32) per quanto riguarda il loro trattamento mediante strumenti informatici,
                        manuali ed automatizzati con logiche strettamente correlate alle finalità sopra indicate e
                        comunque in modo da garantire la sicurezza e la riservatezza dei dati stessi.</p>

                    <p>2.2 Nel rispetto della normativa applicabile, è attivo un sistema di verifica antispam sulle
                        comunicazioni effettuate per il tramite del form di risposta agli annunci messo a disposizione
                        degli utenti. I dati ivi inseriti potranno essere verificati al solo scopo di individuare
                        attività illecite o contenuti non conformi alle Condizioni generali del Servizio, ma non saranno
                        trattati o comunicati per finalità commerciali o promozionali.</p>

                    <p>3.&nbsp; <strong>Facoltatività del conferimento dei dati</strong></p>

                    <p>3.1 Il conferimento dei dati personali da parte Sua è assolutamente facoltativo; tuttavia
                        l’eventuale rifiuto ad inserirli nella pagina dedicata alla registrazione per le finalità di
                        erogazione del Servizio (punto 1.1) rende impossibile l’utilizzo del Servizio offerto
                        nell’ambito del sito web&nbsp;<a href="http://www.1008site.com">www.cinebooking.com</a></p>

                    <p>3.2 Il conferimento dei suoi dati personali è facoltativo per le finalità commerciali (punto 1.2)
                        e di profilazione (1.3) e l’eventuale rifiuto di prestare il consenso non ha conseguenze
                        sull’erogazione del Servizio.</p>

                    <p>4.&nbsp; <strong>Categorie di soggetti ai quali possono essere comunicati i dati
                            personali</strong></p>

                    <p>4.1 I dati raccolti per finalità di erogazione del Servizio (punto 1.1) potranno essere
                        comunicati a Società che svolgono funzioni strettamente connesse e strumentali all’operatività
                        del Servizio, o, comunque, che erogano servizi finalizzati alla verifica che la pubblicazione
                        degli annunci non sia volta alla violazione dei diritti di privativa appartenenti a soggetti
                        terzi. Tali dati potranno essere trasferite &nbsp;verso &nbsp;società &nbsp;collegate &nbsp;e/o
                        facenti &nbsp;parte &nbsp;del &nbsp;medesimo &nbsp;gruppo&nbsp; societario di Cinebooking
                        Italia.</p>

                    <p>4.2 I dati raccolti per finalità commerciale (punto 1.2) e per finalità di profilazione (punto
                        1.3) potranno essere ceduti a terzi facenti parte del medesimo Gruppo Cinebooking Italia S.r.l. anche
                        per la vendita o tentata vendita, ovvero per tutte quelle finalità a carattere commerciale e/o
                        statistico lecite. L’elenco dei soggetti ai quali sono stati ceduti i Suoi dati personali potrà
                        essere richiesto al Titolare mediante l’utilizzo dell’indirizzo di posta elettronica che trova
                        al punto 6.2.</p>

                    <p>5.&nbsp; <strong>Titolare del trattamento, Responsabili ed Incaricati</strong></p>

                    <p>5.1&nbsp;Cinebooking Italia S.r.l. è Titolare autonomo del trattamento dei dati in persona del suo
                        legale rappresentante pro-tempore, con sede legale sita in 00132 Roma V. R. Verduzio, 14 P.iva
                        14321521008</p>

                    <p>6.&nbsp; <strong>Diritti dell’Interessato</strong></p>

                    <p>6.1&nbsp;Le ricordiamo che, in ogni momento, conserva il diritto di ottenere dai contitolari
                        informazioni circa i dati personali che la riguardano, l’origine degli stessi, le finalità e le
                        modalità di trattamento; conserva, altresì, il diritto di richiedere l’aggiornamento, la
                        rettifica, l’integrazione, la cancellazione, la trasformazione in forma anonima o il blocco dei
                        dati personali, trattati in violazione di legge, compresi quelli di cui non è necessaria la
                        conservazione in relazione agli scopi per i quali i dati sono stati raccolti e/o successivamente
                        trattati.</p>

                    <p>6.2 Per l’esercizio dei Suoi diritti le forniamo questo indirizzo di posta elettronica dedicato:
                        info@cinebooking.com</p>

                    <p>6.3 Le rammentiamo che inoltre ha diritto a proporre reclamo presso un’autorità di controllo.</p>

                    <p>6.4 Qualora non fosse più interessato alle comunicazioni di Cinebooking Italia S.r.l. e volesse
                        disiscriversi dalla newsletter, potrà farlo cliccando sul link “cancellazione” presente in fondo
                        a ciascuna mail inviata da Cinebooking Italia S.r.l. o aggiornando le sue preferenze dal Suo account
                        personale.</p>

                    <p>7.&nbsp; <strong>Ulteriori informazioni su cookies, tecnologie similari, motori di ricerca e dati
                            sulla posizione</strong></p>

                    <p>7.1 Cookies e tecnologie similari</p>

                    <p>7.1.1 Cinebooking Italia S.r.l. utilizza i cookies, dei file di testo di dimensioni ridotte che vengono
                        memorizzati sul Suo dispositivo durante la navigazione dei Servizi di Cinebooking Italia S.r.l.. I
                        cookies possono essere memorizzati in modo permanente sul Suo dispositivo ed avere un durata
                        variabile (cookies persistenti), oppure possono cancellarsi con la chiusura del browser o ancora
                        avere una durata limitata (cookies di sessione). I cookies possono essere istallati da
                        Cinebooking Italia S.r.l. o possono essere istallati da altri siti web (cookies di terze parti).</p>

                    <p>7.1.2 &nbsp;Le funzioni principale dei cookies installati da Cinebooking Italia S.r.l. sono: (i)
                        tecniche, vengono cioè utilizzati per migliorare costantemente il Servizio, (ii) statistiche
                        (conteggio delle visite a Cinebooking Italia S.r.l.), oppure (iii) per memorizzare gli annunci
                        preferiti sotto la sezione “Le mie ricerche”. L’utilizzo di cookies è dunque strettamente
                        finalizzato a facilitare le funzioni del server di Cinebooking Italia S.r.l. durante la navigazione del
                        Servizio.</p>

                    <p>7.1.3 Cinebooking Italia S.r.l. si impegna a fornire pubblicità, comunicazioni promozionali e servizi
                        utili e pertinenti ai propri utenti. Al fine di garantire un elevato grado di corrispondenza tra
                        i contenuti del Servizio e la pubblicità e/o le comunicazioni promozionali, Cinebooking Italia S.r.l.
                        consente l’installazione di cookies di terze parti da parte dei seguenti soggetti: <a
                            href="http://www.4wmarketplace.com/it/page/read/privacy-policy">4w MarketPlace
                            Srl</a>,&nbsp;<a href="https://www.appnexus.com/en/company/platform-privacy-policy">App
                            Nexus</a>,&nbsp;<a
                            href="https://advertise.bingads.microsoft.com/en-us/resources/policies/microsoft-bing-ads-privacy-policy">Bing
                            Ads</a>,&nbsp;<a href="http://www.criteo.com/it/privacy/">Criteo</a>,&nbsp;<a
                            href="http://www.google.com/policies/technologies/types/">DoubleClick e Google
                            Analytics</a>,&nbsp;<a
                            href="https://www.cxense.com/about/privacy-policy/">Emediate</a>,&nbsp;<a
                            href="https://www.facebook.com/privacy/explanation">Facebook</a>,&nbsp;<a
                            href="http://www.kenshoo.co.uk/privacy-policy/">Kenshoo</a>,&nbsp;<a
                            href="http://www.leonardo.it/privacy/">Leonardo</a>,&nbsp;<a
                            href="https://www.ligatus.it/dichiarazione-di-privacy">Ligatus</a>,&nbsp;<a
                            href="https://mixpanel.com/privacy/">Mixpanel</a>,&nbsp;<a
                            href="https://www.optimizely.com/privacy/">Optimizely</a>,&nbsp;<a
                            href="http://rocketfuel.com/privacy-policy">Rocket Fuel Inc</a>,&nbsp;<a
                            href="http://www.sizmek.com/about-us/cookie">Sizmek</a>,&nbsp;<a
                            href="http://www.tagcommander.com/en/privacy">Tagcommander</a>,&nbsp;<a
                            href="http://www.tradedoubler.com/it/politica-sulla-privacy/">TradeDoubler</a>,&nbsp;<a
                            href="https://twitter.com/privacy?lang=it">Twitter</a>&nbsp;<a
                            href="http://www.xaxis.com/static/view/privacy-policy">Xasis</a>,&nbsp;<a
                            href="http://www.atinternet.com/en/corporate/corporate/data-protection-and-privacy/">Xiti</a>,&nbsp;<a
                            href="https://policies.yahoo.com/ie/it/yahoo/privacy/topics/cookies/index.htm">Yahoo</a>,&nbsp;
                        per l’offerta di annunci pubblicitari. Attraverso queste tecnologie gli inserzionisti hanno la
                        possibilità di offrire annunci basati su interessi associati ad attività online che non
                        identificano personalmente gli utenti, come le ricerche effettuate, la frequenza di caricamento
                        delle pagine o l’attività su altri siti di partner. Gli inserzionisti possono inoltre offrire
                        annunci in base alla precedente attività degli utenti sui loro siti web. Queste informazioni,
                        che non identificano personalmente l’utente, vengono gestite separatamente dai dati
                        identificativi in possesso di Cinebooking Italia S.r.l.</p>

                    <p>L’utente può, in ogni momento, disabilitare i cookie installati dai predetti soggetti terzi
                        selezionando i link sopra riportati. Per interrompere l’utilizzo del cookie di App Nexus ed
                        effettuare il relativo opt-out si può cliccare&nbsp;<a
                            href="https://www.appnexus.com/en/company/platform-privacy-policy#choices">qui</a>.</p>

                    <p>Inoltre, potrebbero essere attivati dei cookies specialmente concepiti per «reti sociali». Essi
                        permettono all’utente di interagire tramite le reti sociali (funzione condividi,&nbsp;<a
                            href="https://www.facebook.com/privacy/explanation">Facebook</a>&nbsp;e&nbsp;<a
                            href="https://twitter.com/privacy?lang=it">Twitter</a>). Quando una pagina contiene questo
                        comando, si stabilisce un collegamento diretto con la rete sociale selezionata.</p>

                    <p>7.1.4 L’utente può visualizzare, modificare o aggiungere voci alle categorie utilizzate per
                        fornire pubblicità basata su interessi in&nbsp;<a
                            href="https://www.google.com/settings/u/0/ads?hl=it&amp;sig=ACi0TCgil1hKLIMsef8ANyoRISCqtZ6-TM4e64T5C0egw_1OlPID-o03ecxfUoHSmltUmEzPqQEhVC19-dH9BUmf1OF4k9-iu22cfeb8LCC3iH29Akia2bXfEqwzV9qGSapsEfS0QursEMXWs8hrZarzNasWYLOwYVJETVLFUqN8h2NZAEzNNMQ">Gestione
                            preferenze annunci</a>, allo scopo di associare i propri interessi al cookie di DoubleClick.
                        Le preferenze pubblicitarie verranno rispettate su Cinebooking Italia S.r.l. e sui siti dei partner
                        AdSense che utilizzano il cookie di DoubleClick per offrire gli annunci. Per ulteriori
                        informazioni relative alla pubblicità basata sugli interessi e su Gestione preferenze annunci,
                        si può consultare la pagina&nbsp;<a href="http://www.google.it/policies/faq/">Domande frequenti
                            di Google</a>. L’utente può decidere di revocare&nbsp;il consenso all’uso del cookie di
                        DoubleClick in qualsiasi momento, selezionando il cookie di opt-out di DoubleClick. Ulteriori
                        informazioni sull’opzione di opt-out relativa al cookie di DoubleClick sono disponibili&nbsp;<a
                            href="http://www.google.it/policies/faq/">qui</a>.</p>

                    <p>7.1.5 &nbsp;I cookies possono essere completamente disattivati dal browser utilizzando l’apposita
                        funzione prevista nella maggior parte dei programmi di navigazione. È bene sapere però che
                        disattivando i cookies alcune delle funzionalità di Cinebooking Italia S.r.l. potrebbero non essere
                        utilizzabili. Di seguito si riportano le modalità proposte dai principali browser:&nbsp;<a
                            href="https://support.google.com/chrome/answer/95647?hl=it">Chrome</a>,&nbsp;<a
                            href="https://support.mozilla.org/it/kb/Gestione%20dei%20cookie">Firefox</a>,&nbsp;<a
                            href="http://windows.microsoft.com/it-it/internet-explorer/delete-manage-cookies#ie=ie-11">Internet
                            Explorer</a>,&nbsp;<a
                            href="http://help.opera.com/Windows/10.00/it/cookies.html">Opera</a>,&nbsp;<a
                            href="https://support.apple.com/it-it/HT201265">Safari</a>, Google Analytics scaricando uno
                        specifico&nbsp;<a href="https://tools.google.com/dlpage/gaoptout">plug-in</a>&nbsp;del browser.
                    </p>

                    <p>7.1.6 Cinebooking Italia S.r.l. oltre ai cookies, per migliorare il Servizio e la navigazione potrebbe
                        consentire l’utilizzo di tecnologie similari sui Suoi dispositivi quali i tools di&nbsp;<a
                            href="http://www.appsflyer.com/privacy-policy/?utm_source=google&amp;utm_medium=cpc&amp;utm_term=appsflyer&amp;utm_content=ad4&amp;utm_campaign=Brand_Europe&amp;sa=l&amp;ai=CM3xbsuZdVdujN8uGzAPsz4L4CKGD_NsGge7hovQBseqxhJYBCAAQASgCYP2CkQSgAb_2rt8DyAEBqgQkT9AQm2VFIF9V290mJ7prQeciY1k_6NBBSeJxaro41ZxS7m4OgAepidEgkAcBqAemvhuoB5PCG6gHlMIb2AcB&amp;sig=AOD64_2SmRGbDdrbF9bzLwJeNcJrEPcVXg&amp;rct=j&amp;q=&amp;ved=0CCIQ0Qw&amp;adurl=http://www.appsflyer.com/%3Futm_source%3Dgoogle%26utm_medium%3Dcpc%26utm_term%3Dappsflyer%26utm_content%3Dad4%26utm_campaign%3DBrand_Europ">AppsFlyer</a>,&nbsp;<a
                            href="https://policies.yahoo.com/us/en/yahoo/privacy/index.htm">Flurry</a>,&nbsp;<a
                            href="http://www.google.com/policies/privacy/">Google Analytics</a>,&nbsp;<a
                            href="http://www.google.com/policies/privacy/">Google Ads</a>,&nbsp;<a
                            href="http://www.inmobi.com/privacy-policy/">InMobi</a>,&nbsp;<a
                            href="https://www.hotjar.com/privacy">Hotjar</a>&nbsp;e&nbsp;<a
                            href="http://www.atinternet.com/en/corporate/corporate/data-protection-and-privacy/">Xiti</a>.
                        L’impiego di tali tecnologie è regolato dalle informative sulla privacy di dette società e non
                        dall’informativa privacy di Cinebooking Italia S.r.l..</p>

                    <p>8.&nbsp; <strong>Motori di ricerca</strong></p>

                    <p>8.1 Le informazioni relative agli annunci inseriti su Cinebooking Italia S.r.l. saranno visibili nelle
                        ricerche effettuate nel motore di ricerca interno e potrebbero essere messe a disposizione di
                        motori di ricerca terzi in quanto Cinebooking Italia S.r.l. consente l’indicizzazione dei propri
                        contenuti da parte di motori terzi. Nel caso in cui la pagina relativa all’annuncio sia già
                        stata rimossa da Cinebooking Italia S.r.l. &nbsp;è possibile che la copia cache permanga tra i
                        risultati di ricerca per qualche giorno. I risultati della ricerca non sono gestiti da
                        Cinebooking Italia S.r.l., ma l’utente può segnalare la rimozione della pagina e sollecitare
                        l’aggiornamento della copia cache direttamente al motore di ricerca a questo&nbsp;<a
                            href="https://support.google.com/webmasters/answer/1663691">indirizzo</a>.</p>

                    <p>9.&nbsp; <strong>Posizione</strong></p>

                    <p>9.1 Quando utilizza le applicazioni di Cinebooking Italia S.r.l. con il rilevamento della posizione
                        attivo, Cinebooking Italia S.r.l. potrebbe raccogliere ed elaborare informazioni sulla sua posizione
                        attuale. Questi dati vengono trattati in forma anonima, in un formato che non consente di
                        identificare personalmente l’utente, e utilizzati al solo fine di facilitare la fruizione di
                        alcune funzioni dell’applicazione basati sulla posizione. I servizi di localizzazione possono
                        essere attivati o disattivati dall’utente in qualunque momento accedendo alle impostazioni del
                        proprio dispositivo (per ulteriori informazioni si prega di consultare la guida del proprio
                        dispositivo).</p>

                    <p>10.&nbsp; <strong>Modifiche</strong></p>

                    <p>10.1 La presente informativa potrebbe essere soggetta a modifiche. Qualora vengano apportate
                        sostanziali modifiche all’utilizzo dei dati relativi all’utente da parte di Cinebooking Italia S.r.l.
                        l’utente avviserà l’utente pubblicandole con la massima evidenza sulle proprie pagine.</p>

                    <p>Ultimo aggiornamento al 18/10/2017</p>

                    <p>Note 1 – Per trattamento di dati personali si intende la loro raccolta, registrazione,
                        organizzazione, conservazione, elaborazione, modificazione, selezione, estrazione, raffronto,
                        utilizzo, diffusione, cancellazione, distribuzione, interconnessione e quant’altro sia utile per
                        l’esecuzione del Servizio, compresa la combinazione di due o più di tali operazioni.</p>

                    <p>Cinebooking Italia S.r.l. – Sede legale: P.zza della Croce Rossa, 2/C 00161 Roma<br>
                        Codice Fiscale/Partita IVA/Registro Imprese di Roma 15338731001<br>
                        R.E.A. Roma n° 1583716</p>

                </div>
            </div>
        </div>
    </section>
</div>
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-stampa',
  templateUrl: './stampa.component.html',
  styleUrls: ['./stampa.component.scss']
})
export class StampaComponent implements OnInit {

  constructor(
    private title: Title
  ) { 
    this.title.setTitle('Stampa – Cinebooking');
  }

  ngOnInit(): void {
  }

}

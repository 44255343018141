import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpRequest, HttpHandler } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(
        private cookieService: CookieService,
    ) { }

    intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if(httpRequest.url.includes('cinebooking-staging-media.s3.eu-central-1.amazonaws.com')) {
            return next.handle(httpRequest);
        }
        if(httpRequest.url.includes('cms')) {
            httpRequest = httpRequest.clone({
                setHeaders: {
                    Authorization: `${this.cookieService.get('admin-token')}`,
                }
            });
            return next.handle(httpRequest);
        }
        httpRequest = httpRequest.clone({
            setHeaders: {
                Authorization: `${this.cookieService.get('token')}`,
            }
        });
        return next.handle(httpRequest);
    }
}
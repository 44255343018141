import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CountMatch } from 'src/core/count-match.core';
import { ListingProposals, Proposal, SectionProposals } from 'src/core/listing_proposals.core';
import { environment } from 'src/environments/environment';
import { Utils } from 'src/utils/utils';
import { DeleteModalComponent } from '../delete-modal/delete-modal.component';
import { MatchingListingComponent } from '../matching-listing/matching-listing.component';
import { LoadingService } from '../services/loading.service';
import { MatchService } from '../services/match.service';
import { ProposalService } from '../services/proposal.service';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-my-listings',
  templateUrl: './my-listings.component.html',
  styleUrls: ['./my-listings.component.scss']
})
export class MyListingsComponent implements OnInit {
  sections: SectionProposals[] = [];
  queryParamSection: string;
  match_uuidParam: string;
  number_matchs: number = 0;
  currentSection: SectionProposals;

  countMatchs: CountMatch = new CountMatch();
  loadingImage = 'https://cinebooking-staging-media.s3.eu-central-1.amazonaws.com/files/lazyloading.gif';
  navbarOnMobile: boolean;

  constructor(
    private userService: UserService,
    private proposalService: ProposalService,
    private matchService: MatchService,
    private loadingService: LoadingService,
    private modal: NgbModal,
    private router: Router,
    private route: ActivatedRoute,
  ) { 
    this.route.queryParams.subscribe((params) => {
      this.queryParamSection = params['section'];
      this.match_uuidParam = params['match_uuid'];
    })
  }

  ngOnInit(): void {
    this.loadingService.start();
    this.listing();
    this.matchService.count();
    this.matchService.countMatchs.subscribe((count: CountMatch) => {
      if(!count) {
        return;
      }
      this.countMatchs = count;
    });
  }

  listing() {
    const user_id = this.userService.getUserId();
    this.proposalService.listing(user_id).subscribe((listing: ListingProposals) => {
      this.sections = listing.sections;
      this.number_matchs = listing.number_matchs;

      if (this.queryParamSection) {
        this.currentSection = this.sections.find(s => s.section == this.queryParamSection);
      }
      if (!this.currentSection) {
        this.currentSection = listing.sections[0];
      }
      if (this.currentSection) {
        this.setQueryParams(this.currentSection.section);
      }
      if(this.match_uuidParam) {
        const proposal = this.currentSection.proposals.find(s => s.uuid == this.match_uuidParam);
        if(proposal) {
          this.view(proposal);
        }
      }
      this.loadingService.stop();
    });
  }

  isCurrentSection(name: string) {
    return this.currentSection.section == name;
  }

  changeSection(name: string) {
    this.currentSection = this.sections.find(s => s.section == name);
    this.navbarOnMobile = false;
    this.setQueryParams(name);
  }

  setQueryParams(name: string) {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        section: name
      },
      queryParamsHandling: 'merge',
    });
  }

  view(proposal: Proposal) {
    const matching = this.modal.open(MatchingListingComponent, {centered: true });
    matching.componentInstance.slug = this.currentSection.slug;
    matching.componentInstance.matchs = proposal.matchs;
    matching.componentInstance.uuid = proposal.uuid;
    matching.componentInstance.onDeleteMatch.subscribe(() => {
      proposal.number_matchs -= 1;
      this.currentSection.number_matchs -= 1;
      this.number_matchs -= 1;
    })
  }

  delete(proposal: Proposal) {
    const popup = this.modal.open(DeleteModalComponent, { centered: true });
    popup.componentInstance.onDelete.subscribe(() => {
      this.loadingService.start();
      this.proposalService.delete(proposal.uuid).subscribe(() => {
        popup.close();
        this.loadingService.stop();
        this.listing();
      }, (error) => {
        this.loadingService.stop();
        console.error(error);
      })
    });
  }

  hasMatchs(proposal: Proposal) {
    if (!proposal.matchs || proposal.matchs.length == 0) {
      return false;
    }
    return true;
  }

  hasMoreListing() {
    if(!this.currentSection || !this.currentSection.proposals) {
      return;
    }
    return this.currentSection.proposals.length < this.currentSection.total;
  }

  moreListings() {
    const user_id = this.userService.getUserId();
    this.proposalService.moreListing(user_id, this.currentSection.section, this.currentSection.proposals.length)
    .subscribe((proposals: Proposal[]) => {
      this.currentSection.proposals = this.currentSection.proposals.concat(proposals);
    })
  }

  getStatus(proposal: Proposal) {
    switch (proposal.status) {
      case 'draft': {
        return 'Bozza'
      }
      case 'published': {
        return 'In attesa di approvazione'
      }
      case 'suspended': {
        return 'In attesa di approvazione'
      }
      case 'active': {
        return 'Attiva'
      }
      case 'inactive': {
        return 'Rifiutata'
      }
    }
  }

  isMobile() {
    return Utils.isMobile();
  }
}

<div class="container">
    <section id="bg-image" data-type="background" data-speed="5" style="background: url(cyan_3.jpg) no-repeat;"
        class="page-header-padding bg-white f-cinebook-section parallax-bg">
        <div class="row">
            <div class="col-lg-8 offset-lg-2 col-md-10 offset-md-1">
                <div class="containt-box doman-frequent">
                    <h1>Domande frequenti</h1>

                    <div class="faq-containt">

                        <h2>ISCRIZIONE E FUNZIONAMENTO</h2>
                        <div class="faq-item">
                            <div class="question"> Sono un’ attrice senza esperienza posso iscrivermi in Cinebooking?
                            </div>

                            <div class="answer"> ASSOLUTAMENTE SI. CINEBOOKING OFFRE OPPORTUNITÀ A TUTTI, CON O SENZA
                                ESPERIENZA. L’IMPORTANTE È SEMPRE CHIARIRE ESATTAMENTE NEL PROPRIO PROFILO QUALI SIANO
                                LE CONOSCENZE DI UN CANDIDATO SIA ARTISTICO CHE TECNICO.</div>

                        </div>
                        <div class="faq-item">
                            <div class="question"> In che tempi mi darete risposta per un lavoro per il quale mi sono
                                candidata?</div>

                            <div class="answer"> NON SIAMO DIRETTAMENTE NOI CHE DAREMO UNA RISPOSTA: E LA RICEVERAI SOLO
                                SE LA PERSONA CHE HA INVIATO LA RICERCA TI HA OPZIONATO. I TEMPI POSSONO VARIARE A
                                SECONDA DELLA DATA DEL LAVORO.</div>

                        </div>
                        <div class="faq-item">
                            <div class="question"> Sono un personaggio noto devo pagare Cinebooking?</div>

                            <div class="answer"> ASSOLUTAMENTE NO. CINEBOOKING NON HA ALCUN COSTO PER IL SERVIZIO CHE TI
                                OFFRE. CHIARAMENTE, SE DISPONI DI UN’AGENZIA DI RIFERIMENTO, IL TUO CONTATTO SARÀ
                                TRASFERITO ALL’AGENZIA E IL RECRUITMENT DOVRÀ SOSTENERE IL RELATIVO COSTO PERCENTUALE
                                ACCORDATO.</div>

                        </div>
                        <div class="faq-item">
                            <div class="question"> Non trovo la mia qualifica, come posso fare?</div>

                            <div class="answer"> VAI ALLA SEZIONE IN BASSO ED INVIA UN’ EMAIL NELLA SEZIONE SUGGERISCI
                                QUALIFICA, IL NOSTRO UFFICIO VALUTERÀ SE OPPORTUNO INSERIRLA.</div>

                        </div>
                        <div class="faq-item">
                            <div class="question"> Cosa intendete per “Dai un nome a questa ricerca o proposta”?</div>

                            <div class="answer"> SARÀ UTILE SOLO PER TE E CIOÈ SE EFFETTUI PIÙ RICERCHE O HAI PIÙ
                                PROPOSTE TI AUTERÀ NEL TUO ACCOUNT A DIFFERENZIARE LE SCHEDE PER EVENTUALI
                                AGGIORNAMENTI.</div>

                        </div>
                        <div class="faq-item">
                            <div class="question"> Sono un professionista. Devo pagare se trovo lavoro?</div>

                            <div class="answer"> NO, ASSOLUTAMENTE.</div>

                        </div>


                        <h2>AZIENDE</h2>
                        <div class="faq-item">
                            <div class="question"> Ho un azienda di forniture tecniche per Cinema e Tv come posso fare
                                per proporre le mie forniture?</div>

                            <div class="answer"> NELLA SEZIONE PROPONI TROVERA TUTTE LE CATEGORIE DI APPARTENENZA PER LE
                                FORNITURE.</div>

                        </div>


                        <h2>JOB ALERT – FIND ALERT</h2>
                        <div class="faq-item">
                            <div class="question"> Ho letto intermediario, posso rispondere ad una richiesta di lavoro
                                per conto di un amico?</div>

                            <div class="answer"> NO, MA PUOI SUGGERIRE AL TUO AMICO LA RICHIESTA IN CORSO E POTRA’
                                ISCRIVERSI VELOCEMENTE PER CANDIDARSI. LA SEZIONE INTERMEDIARIO E’ INDICATA PER AGENZIE
                                DI CASTING, LOCATIONS ED ALTRO.</div>

                        </div>
                        <div class="faq-item">
                            <div class="question"> Posso dare il contatto del mio agente a chi prende contatti con me?
                            </div>

                            <div class="answer"> PURTROPPO NO, AL TUO PROFILO ARTISTICO DOVRAI RISPONDERE PERSONALMENTE
                                E GESTIRE AUTONOMAMENTE IL LAVORO SALVO ECCEZIONE SE AVRAI ESPRESSAMENTE INDICATO DI
                                AVERE UN AGENTE AL MOMENTO DELLA REGISTRAZIONE SCHEDA PROFILO.</div>

                        </div>


                        <h2>SICUREZZA</h2>
                        <div class="faq-item">
                            <div class="question"> Mi hanno contattato per lavoro ma non vogliono fornirmi il numero di
                                telefono.</div>

                            <div class="answer">
                                <p>SIMILI COMPORTAMENTI NON SONO CONSONI, SEGNALACI L’UTENTE E FAREMO UNA VERIFICA. IN
                                    OGNI CASO E’ OPPORTUNO AVERE UN NUMERO DI TELEFONO DI CHI VI OFFRE LAVORO.</p>
                            </div>

                        </div>
                        <div class="faq-item">
                            <div class="question"> Mi ha contattato su Facebook una persona sostenendo di lavorare per
                                Cinebooking e voleva un mio contatto per propormi lavoro.</div>

                            <div class="answer">
                                <p>NOI NON ABBIAMO REFERENTI CHE SI ADOPERANO IN QUESTO MODO SEGNALACI EVENTUALMENTE
                                    QUANTO ACCADUTO.</p>
                            </div>

                        </div>


                        <h2>LAVORO</h2>
                        <div class="faq-item">
                            <div class="question"> Ho eseguito un lavoro e non sono stato pagato, cosa posso fare?</div>

                            <div class="answer">
                                <p>CINEBOOKING NON RISPONDE DELL’OPERATO DEI SUOI UTENTI, TI PREGHIAMO SEGNALARCI QUANTO
                                    ACCADUTO PER EVITARE CHE SIMILI COMPORTAMENTI POSSANO RIPETERSI.</p>
                            </div>

                        </div>


                        <h2>PROPOSTE BENI MATERIALI</h2>
                        <div class="faq-item">
                            <div class="question"> Ho una casa che vorrei proporre per un Film, ma poi dal punto di
                                vista contrattuale come posso fare per eseguire un rent giornaliero.</div>

                            <div class="answer">
                                <p>TROVA COME RISORSA IN CINEBOOKING UNA FIGURA DI LOCATION MANAGER CHE A FRONTE DI UNA
                                    PROVVIGIONE PUO’ ASSISTERTI A PERFEZIONARE IL NOLEGGIO.</p>
                            </div>

                        </div>


                    </div>



                </div>
            </div>
        </div>
    </section>
</div>
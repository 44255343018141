export class Invoice {
    _id: string;
    subsctiptionItemId: string;
    businessName: string;
    vatNumber: string;
    pec: string;
    sdi: string;
    address: string;
    city: string;
    streetNumber: string;
    postalCode: string;
    user: string;
}
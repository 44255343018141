<div class="container">
    <section class="page-header-padding bg-white account-page edit-account">
        <div class="row">
            <div class="col-md-12 col-lg-2 col-xl-2">
                <div class="account-tab account-tab-hide">
                    <ul class="list-group">
                        <li class="list-group-item  list-group-li account-button active">
                            <a routerLink="/my-account/edit-account">
                                <svg class="svg-inline--fa fa-user fa-w-14 d-block d-lg-none account-icon"
                                    aria-hidden="true" focusable="false" data-prefix="far" data-icon="user" role="img"
                                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg="">
                                    <path fill="currentColor"
                                        d="M313.6 304c-28.7 0-42.5 16-89.6 16-47.1 0-60.8-16-89.6-16C60.2 304 0 364.2 0 438.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-25.6c0-74.2-60.2-134.4-134.4-134.4zM400 464H48v-25.6c0-47.6 38.8-86.4 86.4-86.4 14.6 0 38.3 16 89.6 16 51.7 0 74.9-16 89.6-16 47.6 0 86.4 38.8 86.4 86.4V464zM224 288c79.5 0 144-64.5 144-144S303.5 0 224 0 80 64.5 80 144s64.5 144 144 144zm0-240c52.9 0 96 43.1 96 96s-43.1 96-96 96-96-43.1-96-96 43.1-96 96-96z">
                                    </path>
                                </svg><!-- <i class="far fa-user d-block d-lg-none account-icon"></i> -->
                                <span class="d-none d-lg-block">Account</span>
                            </a>
                        </li>

                        <li class="list-group-item"><a routerLink="/my-account/my-listings">
                            Proposte
                            <div *ngIf="countMatchs.proposal_matchs > 0" class="badge badge-secondary"> <svg class="svg-inline--fa fa-envelope fa-w-16"
                                aria-hidden="true" focusable="false" data-prefix="fa" data-icon="envelope" role="img"
                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
                                <path fill="currentColor"
                                   d="M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z">
                                </path>
                             </svg>
                             <span style="margin-left: 5px;">{{countMatchs.proposal_matchs}}</span>
                          </div>
                        </a></li>
                        <li class="list-group-item"><a routerLink="/my-account/my-search">Ricerche 
                            <div *ngIf="countMatchs.search_matchs !== 0" class="badge badge-success"> <svg class="svg-inline--fa fa-envelope fa-w-16" aria-hidden="true" focusable="false" data-prefix="fa" data-icon="envelope" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z"></path></svg>
                                <span style="margin-left: 5px;">{{countMatchs.search_matchs}}</span>
                            </div>
                        </a></li>
                        <li class="list-group-item"><a routerLink="/my-account/logout">Logout</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-md-12 col-lg-10 col-xl-10" *ngIf="user">
                <div class="form-design edit-account-form">
                    <div class="account-form-box">

                        <app-subcription></app-subcription>

                        <div style="padding: 0px 20px">
                            <hr />
                            <form role="form" class="cine-booking-form">
                                <div class="form-row">
                                    <div class="form-group col-xs-12 col-sm-12 col-md-12">
                                        <label>Email</label>
                                        <input [(ngModel)]="user.user_email" type="email" name="email" id="email" class="form-control input-sm"
                                            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$" [required]="true" [disabled]="true"
                                            [ngModelOptions]="{standalone: true}" #user_email="ngModel"
                                            [ngClass]="{'text-red': updateUserOnSubmit &&  user_email.invalid}">
                                    </div>
                                </div>
                            </form>
                        </div>

                        <form role="form" class="cine-booking-form" method="POST" *ngIf="false">
                            <div class="account-form-title color-pink">
                                <span class="rounded-circle"><svg
                                        class="svg-inline--fa fa-user-circle fa-w-16 color-white" aria-hidden="true"
                                        focusable="false" data-prefix="fas" data-icon="user-circle" role="img"
                                        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 512" data-fa-i2svg="">
                                        <path fill="currentColor"
                                            d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm0 96c48.6 0 88 39.4 88 88s-39.4 88-88 88-88-39.4-88-88 39.4-88 88-88zm0 344c-58.7 0-111.3-26.6-146.5-68.2 18.8-35.4 55.6-59.8 98.5-59.8 2.4 0 4.8.4 7.1 1.1 13 4.2 26.6 6.9 40.9 6.9 14.3 0 28-2.7 40.9-6.9 2.3-.7 4.7-1.1 7.1-1.1 42.9 0 79.7 24.4 98.5 59.8C359.3 421.4 306.7 448 248 448z">
                                        </path>
                                    </svg><!-- <i class="fas fa-user-circle color-white"></i> --></span>
                                <h5>Account Details</h5>
                            </div>
                            <div class="star mb-4 color-yellow" style="display: none;">
                                <svg class="svg-inline--fa fa-star fa-w-18 checked" aria-hidden="true" focusable="false"
                                    data-prefix="fas" data-icon="star" role="img" xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 576 512" data-fa-i2svg="">
                                    <path fill="currentColor"
                                        d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z">
                                    </path>
                                </svg><!-- <i class="fas fa-star checked"></i> -->
                                <svg class="svg-inline--fa fa-star-half-alt fa-w-17" aria-hidden="true"
                                    focusable="false" data-prefix="fas" data-icon="star-half-alt" role="img"
                                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 536 512" data-fa-i2svg="">
                                    <path fill="currentColor"
                                        d="M508.55 171.51L362.18 150.2 296.77 17.81C290.89 5.98 279.42 0 267.95 0c-11.4 0-22.79 5.9-28.69 17.81l-65.43 132.38-146.38 21.29c-26.25 3.8-36.77 36.09-17.74 54.59l105.89 103-25.06 145.48C86.98 495.33 103.57 512 122.15 512c4.93 0 10-1.17 14.87-3.75l130.95-68.68 130.94 68.7c4.86 2.55 9.92 3.71 14.83 3.71 18.6 0 35.22-16.61 31.66-37.4l-25.03-145.49 105.91-102.98c19.04-18.5 8.52-50.8-17.73-54.6zm-121.74 123.2l-18.12 17.62 4.28 24.88 19.52 113.45-102.13-53.59-22.38-11.74.03-317.19 51.03 103.29 11.18 22.63 25.01 3.64 114.23 16.63-82.65 80.38z">
                                    </path>
                                </svg><!-- <i class="fas fa-star-half-alt"></i> -->
                                <svg class="svg-inline--fa fa-star fa-w-18" aria-hidden="true" focusable="false"
                                    data-prefix="far" data-icon="star" role="img" xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 576 512" data-fa-i2svg="">
                                    <path fill="currentColor"
                                        d="M528.1 171.5L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6zM388.6 312.3l23.7 138.4L288 385.4l-124.3 65.3 23.7-138.4-100.6-98 139-20.2 62.2-126 62.2 126 139 20.2-100.6 98z">
                                    </path>
                                </svg><!-- <i class="far fa-star"></i> -->
                                <svg class="svg-inline--fa fa-star fa-w-18" aria-hidden="true" focusable="false"
                                    data-prefix="far" data-icon="star" role="img" xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 576 512" data-fa-i2svg="">
                                    <path fill="currentColor"
                                        d="M528.1 171.5L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6zM388.6 312.3l23.7 138.4L288 385.4l-124.3 65.3 23.7-138.4-100.6-98 139-20.2 62.2-126 62.2 126 139 20.2-100.6 98z">
                                    </path>
                                </svg><!-- <i class="far fa-star"></i> -->
                                <svg class="svg-inline--fa fa-star fa-w-18" aria-hidden="true" focusable="false"
                                    data-prefix="far" data-icon="star" role="img" xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 576 512" data-fa-i2svg="">
                                    <path fill="currentColor"
                                        d="M528.1 171.5L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6zM388.6 312.3l23.7 138.4L288 385.4l-124.3 65.3 23.7-138.4-100.6-98 139-20.2 62.2-126 62.2 126 139 20.2-100.6 98z">
                                    </path>
                                </svg><!-- <i class="far fa-star"></i> -->
                                <span class="color-black">(01)</span>
                            </div>


                            <div *ngIf="!hide_warning" class="alert alert-warning alert-dismissible fade show" role="alert">
                                <strong class="fs-16 color-red font-weight-medium"> ATTENZIONE! Questa sezione non vale
                                    come PROPOSTA CANDIDATURA.</strong> <br>
                                <span class="fs-14">É necessario <a class="link-pink font-weight-medium"
                                        style="text-decoration: underline" routerLink="/inserimento-proposta">completare
                                        in PROPONI
                                        una propria scheda professionale tecnica/artistica o aziendale</a> per ricevere
                                    le proposte di LAVORO.</span>

                                <button (click)="setHideWarning()" type="button" class="close" data-dismiss="alert" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <legend class="text-uppercase">Modifica Profilo</legend>

                            <input type="hidden" value="8006" name="id">
                            <div class="form-row">
                                <div class="form-group col-xs-12 col-sm-12 col-md-12">
                                    <label>Nome * <span class="color-light font-12px font-italic">( Visibile solo a te
                                            )</span></label>
                                    <input [(ngModel)]="user.user_name" type="text" name="first_name" id="first_name"
                                        class="form-control input-sm" [required]="true"
                                        [ngModelOptions]="{standalone: true}" #user_name="ngModel"
                                        [ngClass]="{'text-red': updateUserOnSubmit && user_name.invalid}">
                                    <div *ngIf="updateUserOnSubmit && user_name.invalid ">
                                        <small *ngIf="user_name.errors?.required" style="color: red;">
                                            Il campo non può essere vuoto.</small>
                                    </div>
                                </div>

                            </div>
                            <div class="form-row">
                                <div class="form-group col-xs-12 col-sm-12 col-md-12">
                                    <label>Cognome * <span class="color-light font-12px font-italic">( Visibile solo a
                                            te
                                            )</span></label>
                                    <input [(ngModel)]="user.user_surname" type="text" name="second_name"
                                        id="second_name" class="form-control input-sm" [required]="true"
                                        [ngModelOptions]="{standalone: true}" #user_surname="ngModel"
                                        [ngClass]="{'text-red': updateUserOnSubmit &&  user_surname.invalid}">
                                    <div *ngIf="updateUserOnSubmit && user_surname.invalid ">
                                        <small *ngIf="user_surname.errors?.required" style="color: red;">
                                            Il campo non può essere vuoto.</small>
                                    </div>
                                </div>
                            </div>

                            <div class="form-row">
                                <div class="form-group col-xs-12 col-sm-12 col-md-12">
                                    <label>Provincia</label>
                                    <input [(ngModel)]="user.user_province" type="text" name="user_province" id="user_province"
                                        class="form-control input-sm"  [ngModelOptions]="{standalone: true}"
                                        #user_province="ngModel">
                                </div>
                            </div>

                            <div class="form-row">
                                <div class="form-group col-xs-12 col-sm-12 col-md-12">
                                    <label>Città</label>
                                    <input [(ngModel)]="user.user_city" type="text" name="user_city" id="user_city"
                                        class="form-control input-sm"  [ngModelOptions]="{standalone: true}"
                                        #user_city="ngModel">
                                </div>
                            </div>

                            <div class="form-row">
                                <div class="form-group col-xs-12 col-sm-12 col-md-12">
                                    <label>CAP</label>
                                    <input [(ngModel)]="user.user_cap" type="text" name="user_cap" id="user_cap"
                                        class="form-control input-sm" [ngModelOptions]="{standalone: true}"
                                        #user_cap="ngModel">
                                </div>
                            </div>

                            <div class="form-row">
                                <div class="form-group col-xs-12 col-sm-12 col-md-12">
                                    <label>Indirizzo</label>
                                    <input [(ngModel)]="user.user_address" type="text" name="user_address" id="user_address"
                                        class="form-control input-sm" [ngModelOptions]="{standalone: true}"
                                        #user_address="ngModel">
                                </div>
                            </div>

                            <div class="form-row">
                                <div class="form-group col-xs-12 col-sm-12 col-md-12">
                                    <label>Sesso</label>
                                    <ng-select [(ngModel)]="user.user_gender" aria-hidden="true" [ngModelOptions]="{standalone: true}"
                                        class="custom form-control select-wo-border pl-0 custome-select-design select2-hidden-accessible">
                                        <ng-option [value]="'male'">Uomo</ng-option>
                                        <ng-option [value]="'female'">Donna</ng-option>
                                    </ng-select>
                                </div>
                            </div>


                            <p class="mb-4">
                                <label class="fs-16">Complimenti, ti sei registrato.</label><br>
                                <a class="link-pink fs-16 text-underline" routerLink="/inserimento-ricerca"
                                    title="Compila una scheda di ricerca">Clicca qui per eseguire le tue
                                    Ricerche.</a><br>
                                <span class="color-pink fs-14">Oppure </span>
                                <br />
                                <a class="link-pink fs-16 text-underline " routerLink="/inserimento-proposta"
                                    title="proponi">compila
                                    la tua scheda di proposta da candidato o azienda.</a>

                            </p>

                            <div class="form-row">
                                <div class="form-group col-xs-12 col-sm-12 col-md-12">
                                    <label>Email (richiesto)</label>
                                    <input [(ngModel)]="user.user_email" type="email" name="email" id="email"
                                        class="form-control input-sm" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$"
                                        [required]="true" [ngModelOptions]="{standalone: true}" #user_email="ngModel"
                                        [ngClass]="{'text-red': updateUserOnSubmit &&  user_email.invalid}">
                                    <div *ngIf="updateUserOnSubmit && user_email.invalid ">
                                        <small *ngIf="user_email.errors?.required" style="color: red;">Il campo non può
                                            essere
                                            vuoto.</small>
                                        <small *ngIf="user_email.errors?.pattern" style="color: red;">L'email non
                                            risulta
                                            valida.</small>
                                    </div>
                                </div>
                            </div>

                            <div class="form-row">
                                <div class="form-group col-xs-12 col-sm-12 col-md-12">
                                    <label>Profile Image</label>
                                    <div *ngIf="user.user_profile">
                                        <img src="{{user.user_profile}}" style="max-width:300px; border-radius: 8px;margin-bottom: 1rem;"/>
                                    </div>
                                    <input type="file" name="profile" id="profile" class="form-control input-sm" (change)="onChangeProfile($event)">
                                </div>
                            </div>

                            <div class="form-row" *ngIf="updateUserOnSuccess" >
                                <div class="form-group col-xs-12 col-sm-12 col-md-12">
                                    <div class="alert-success" style="padding: 1rem;"
                                        role="alert">
                                        <svg class="svg-inline--fa fa-check-circle fa-w-16" style="color: #3bbdb6;"
                                            aria-hidden="true" focusable="false" data-prefix="fa"
                                            data-icon="check-circle" role="img" xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 512 512" data-fa-i2svg="">
                                            <path fill="currentColor"
                                                d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z">
                                            </path>
                                        </svg><!-- <i class="fa fa-check-circle" style="color: #3bbdb6;"></i> -->
                                        <span class="ml-2"> Profilo aggiornato con successo</span>

                                        <button (click)="updateUserOnSuccess = false" type="button" class="close" data-dismiss="alert" aria-label="Close">
                                            <span aria-hidden="true">×</span>
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <p class="font-italic mb-0 font-12px font-italic font-weight-medium"> Clicca su "Salva
                                modifiche" per rendere permanenti le modifiche dell'avatar. </p>
                            <div class="form-row">
                                <div class="form-group col mb-10px">
                                    <div class="signup-btn">
                                        <input (click)="submit()" type="submit" id="updateBtn" value="Salva Modifiche"
                                            class="btn btn-primary btn-block hover-green-btn pink-btn w-100 text-uppercase font-medium">
                                    </div>
                                </div>
                            </div>
                        </form>

                        <form role="form" class="cine-booking-form" style="padding: 0px 20px">
                            <input type="hidden" name="_token" value="E80t3VLLhMOXUpJUnFUx2zeTy26bweYtCSTURzut">
                            <div class="form-row">
                                <div class="form-group col-xs-12 col-sm-12 col-md-12">
                                    <legend class="text-uppercase">Modifica password</legend>
                                </div>
                            </div>

                            <div class="form-row">
                                <div class="form-group col-xs-12 col-sm-12 col-md-12">

                                    <label>Password attuale</label>
                                    
                                        <input [(ngModel)]="changePasswordModel.previous_password" type="password" name="previous_password" id="previous_password" class="form-control input-sm"
                                            placeholder="Password" [required]="true" [minlength]="8" #previous_password="ngModel">
                                        <div *ngIf="changePasswordOnSubmit && previous_password.invalid ">
                                            <small *ngIf="previous_password.errors?.required" style="color: red;">Il campo non può essere vuoto.</small>
                                            <small *ngIf="previous_password.errors?.minlength" style="color: red;">Minimo 8 caratteri.</small>
                                        </div>
                                </div>
                            </div>

                            <div class="form-row">
                                <div class="form-group col-xs-12 col-sm-12 col-md-12">

                                    <label>Nuova password</label>
                                   
                                        <input [(ngModel)]="changePasswordModel.new_password" type="password" name="new_password" id="new_password" class="form-control input-sm"
                                            placeholder="Password" [required]="true" [minlength]="8" #new_password="ngModel">
                                        <div *ngIf="changePasswordOnSubmit && new_password.invalid ">
                                            <small *ngIf="new_password.errors?.required" style="color: red;">Il campo non può essere vuoto.</small>
                                            <small *ngIf="new_password.errors?.minlength" style="color: red;">Minimo 8 caratteri.</small>
                                        </div>
                                    

                                </div>
                            </div>

                            <div class="form-row">
                                <div class="form-group col-xs-12 col-sm-12 col-md-12">

                                    <label>Conferma nuova password</label>
                                    <input [(ngModel)]="changePasswordModel.new_password_repeat" type="password" name="new_password_repeat" id="new_password_repeat" class="form-control input-sm"
                                            placeholder="Password" [required]="true" [minlength]="8" #new_password_repeat="ngModel">
                                        <div *ngIf="changePasswordOnSubmit && new_password_repeat.invalid ">
                                            <small *ngIf="new_password_repeat.errors?.required" style="color: red;">Il campo non può essere vuoto.</small>
                                            <small *ngIf="new_password_repeat.errors?.minlength" style="color: red;">Minimo 8 caratteri.</small>
                                        </div>

                                </div>
                            </div>

                            <div class="form-row" *ngIf="changePasswordOnSuccess" >
                                <div class="form-group col-xs-12 col-sm-12 col-md-12">
                                    <div class="alert-success" style="padding: 1rem;"
                                        role="alert">
                                        <svg class="svg-inline--fa fa-check-circle fa-w-16" style="color: #3bbdb6;"
                                            aria-hidden="true" focusable="false" data-prefix="fa"
                                            data-icon="check-circle" role="img" xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 512 512" data-fa-i2svg="">
                                            <path fill="currentColor"
                                                d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z">
                                            </path>
                                        </svg><!-- <i class="fa fa-check-circle" style="color: #3bbdb6;"></i> -->
                                        <span class="ml-2"> Password aggiornato con successo</span>

                                        <button (click)="changePasswordOnSuccess = false" type="button" class="close" data-dismiss="alert" aria-label="Close">
                                            <span aria-hidden="true">×</span>
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div class="form-row" *ngIf="changePasswordOnFailed" >
                                <div class="form-group col-xs-12 col-sm-12 col-md-12">
                                    <div class="alert-danger" style="padding: 1rem;"
                                        role="alert">
                                        <span class="ml-2"> {{changePasswordError}}</span>

                                        <button (click)="changePasswordOnFailed = false" type="button" class="close" data-dismiss="alert" aria-label="Close">
                                            <span aria-hidden="true">×</span>
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div class="form-row">
                                <div class="form-group col mb-10px">
                                    <div class="signup-btn">
                                        <input (click)="changePassword()" type="submit" id="changePasswordBtn" value="Cambia la password"
                                            class="btn btn-primary btn-block hover-green-btn pink-btn w-100 text-uppercase font-medium">
                                    </div>
                                </div>
                            </div>

                            <div class="form-row">
                                <div class="form-group col ">
                                    <div class="signup-btn remove-account-btn">
                                        <input (click)="deleteAccount()" type="button" value="Rimuovi account" id="remove-account" class="btn
                                        btn-block btn-outline w-100 text-uppercase font-medium">
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>
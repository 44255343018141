import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HeaderComponent } from './header/header.component';
import { InserimentoPropostaComponent } from './inserimento-proposta/inserimento-proposta.component';
import { FooterComponent } from './footer/footer.component';
import { BuilderFormsComponent } from './builder-forms/builder-forms.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { ChiSiamoComponent } from './chi-siamo/chi-siamo.component';
import { DomandeFrequentiComponent } from './domande-frequenti/domande-frequenti.component';
import { SuggerimentiComponent } from './suggerimenti/suggerimenti.component';
import { StampaComponent } from './stampa/stampa.component';
import { CodiceEticoComponent } from './codice-etico/codice-etico.component';
import { BlogNewsComponent } from './blog-news/blog-news.component';
import { CentroAssistenzaComponent } from './centro-assistenza/centro-assistenza.component';
import { SegnalaUnProgettoComponent } from './segnala-un-progetto/segnala-un-progetto.component';
import { CookieComponent } from './cookie/cookie.component';
import { TerminiECondizioniComponent } from './termini-e-condizioni/termini-e-condizioni.component';
import { ProtezioneDatiComponent } from './protezione-dati/protezione-dati.component';
import { NoteLegaliComponent } from './note-legali/note-legali.component';
import { HomePageComponent } from './home-page/home-page.component';
import { InserimentoRicercaComponent } from './inserimento-ricerca/inserimento-ricerca.component';
import { MyAccountComponent } from './my-account/my-account.component';
import { LostPasswordComponent } from './lost-password/lost-password.component';
import { NgbModalModule, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { SuggestionModalComponent } from './suggestion-modal/suggestion-modal.component';
import { AuthInterceptor } from 'src/interceptor/auth.interceptor';
import { EditAccountComponent } from './edit-account/edit-account.component';
import { MyListingsComponent } from './my-listings/my-listings.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LogoutComponent } from './logout/logout.component';
import { ErrorModalComponent } from './error-modal/error-modal.component';
import { SafePipe } from './pipes/safe.pipe';
import { PreviewFormsComponent } from './preview-forms/preview-forms.component';
import { StatusFormsComponent } from './status-forms/status-forms.component';
import { InserimentoRicercaModalComponent } from './inserimento-ricerca-modal/inserimento-ricerca-modal.component';
import { ComeFunzionaComponent } from './come-funziona/come-funziona.component';
import { MatchingListingComponent } from './matching-listing/matching-listing.component';
import { MySearchComponent } from './my-search/my-search.component';
import { ChatBoxComponent } from './chat-box/chat-box.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { JobSendedComponent } from './job-sended/job-sended.component';
import { MatchingSearchsComponent } from './matching-searchs/matching-searchs.component';
import { DeleteModalComponent } from './delete-modal/delete-modal.component';
import { DeleteAccountComponent } from './delete-account/delete-account.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import {IvyGalleryModule} from 'angular-gallery';
import { ConfirmRegistrationComponent } from './confirm-registration/confirm-registration.component';
import { SingInPopupComponent } from './sing-in-popup/sing-in-popup.component';
import { SingInComponent } from './sing-in/sing-in.component';
import { InserimentoPropostaModalComponent } from './inserimento-proposta-modal/inserimento-proposta-modal.component';
import { WelcomePopupComponent } from './welcome-popup/welcome-popup.component';
import { ShareSchedaComponent } from './share-scheda/share-scheda.component';
import { EntraNelClubComponent } from './entra-nel-club/entra-nel-club.component';
import { ContattaciComponent } from './contattaci/contattaci.component';
import { FeedbackComponent } from './feedback/feedback.component';
import { ComingSoonComponent } from './coming-soon/coming-soon.component';
import { DiventaPremiumComponent } from './diventa-premium/diventa-premium.component';
import { AbbonamentoCompletatoComponent } from './abbonamento-completato/abbonamento-completato.component';
import { SubcriptionComponent } from './subcription/subcription.component';
import { DisdiciAbbonamentoComponent } from './disdici-abbonamento/disdici-abbonamento.component';
import { RichiediFatturaModalComponent } from './richiedi-fattura-modal/richiedi-fattura-modal.component';


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    InserimentoPropostaComponent,
    FooterComponent,
    BuilderFormsComponent,
    ChiSiamoComponent,
    DomandeFrequentiComponent,
    SuggerimentiComponent,
    StampaComponent,
    CodiceEticoComponent,
    BlogNewsComponent,
    CentroAssistenzaComponent,
    SegnalaUnProgettoComponent,
    CookieComponent,
    TerminiECondizioniComponent,
    ProtezioneDatiComponent,
    NoteLegaliComponent,
    HomePageComponent,
    InserimentoRicercaComponent,
    MyAccountComponent,
    LostPasswordComponent,
    SuggestionModalComponent,
    EditAccountComponent,
    MyListingsComponent,
    MySearchComponent,
    DashboardComponent,
    LogoutComponent,
    ErrorModalComponent,
    SafePipe,
    PreviewFormsComponent,
    StatusFormsComponent,
    ComeFunzionaComponent,
    InserimentoRicercaModalComponent,
    MatchingListingComponent,
    ChatBoxComponent,
    PageNotFoundComponent,
    JobSendedComponent,
    MatchingSearchsComponent,
    DeleteModalComponent,
    DeleteAccountComponent,
    ResetPasswordComponent,
    ConfirmRegistrationComponent,
    SingInPopupComponent,
    SingInComponent,
    InserimentoPropostaModalComponent,
    WelcomePopupComponent,
    ShareSchedaComponent,
    EntraNelClubComponent,
    ContattaciComponent,
    FeedbackComponent,
    ComingSoonComponent,
    DiventaPremiumComponent,
    AbbonamentoCompletatoComponent,
    SubcriptionComponent,
    DisdiciAbbonamentoComponent,
    RichiediFatturaModalComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    NgSelectModule,
    FormsModule,
    NgbModalModule,
    NgbPaginationModule,
    LazyLoadImageModule,
    IvyGalleryModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

<div class="modal-header">
    <h5 class="modal-title mt-0" style="text-transform: uppercase;">Suggerimento {{label}}</h5>
    <span class="close" (click)="dismiss()" aria-hidden="true">×</span>
</div>
<div class="modal-body">
    <form role="form" id="propesta-form" class="after-login-formdesign">
        <div class="form-design color-green">
            <div class="form-row">
                <div class="form-group col-xs-12 col-sm-12 col-md-12">
                    <label for="email" class="control-label" style="color: rgb(93, 165, 64);">Email</label>
                    <div>
                        <input [(ngModel)]="suggestion.email" type="email" class="form-control input-sm pl-0"
                            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$"
                            [required]="true" [ngModelOptions]="{standalone: true}"  #email="ngModel" />
                    </div>
                    <div *ngIf="onSubmit && email.invalid " >
                        <small *ngIf="email.errors?.required" style="color: red;">Il campo non può essere vuoto.</small>
                        <small *ngIf="email.errors?.pattern" style="color: red;">L'email non risulta valida.</small>
                    </div>
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-xs-12 col-sm-12 col-md-12">
                    <label for="email" class="control-label" style="color: rgb(93, 165, 64);">
                        {{label}}
                    </label>
                    <div>
                        <input [(ngModel)]="suggestion.suggestion" type="text" class="form-control input-sm pl-0" [required]="true"
                            [ngModelOptions]="{standalone: true}" #refSubject="ngModel" />
                        <div *ngIf="onSubmit && refSubject.invalid">
                            <small *ngIf="refSubject.errors?.required" style="color: red;">Il campo non può essere vuoto.</small>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-xs-12 col-sm-12 col-md-12">
                    <label for="email" class="control-label" style="color: rgb(93, 165, 64);">
                        Messaggio
                        <span class="color-light font-12px">(facoltativo)</span>
                    </label>
                    <div>
                        <textarea [(ngModel)]="suggestion.message" type="text" class="form-control input-sm pl-0"
                            [ngModelOptions]="{standalone: true}"></textarea>
                    </div>
                </div>
            </div>
        </div>
    </form>
    <div *ngIf="onSuccess" style="position: initial;width: 100%;" class="alert alert-success alert-custom" role="alert">
        <svg class="svg-inline--fa fa-check-circle fa-w-16" style="color: #3bbdb6;" aria-hidden="true" focusable="false" data-prefix="fa" data-icon="check-circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"></path></svg><!-- <i class="fa fa-check-circle" style="color: #3bbdb6;"></i> -->
        <span class="ml-2">Suggerimento inviato con successo</span>

        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">×</span>
        </button>
    </div>
</div>
<div class="modal-footer">
    <button (click)="dismiss()" type="button" class="btn btn-default" data-dismiss="modal">Chiudi</button>
    <button (click)="submit()" type="submit" class="btn btn-primary" id="invia-suggerimento"  *ngIf="!onSuccess">Invia suggerimento</button>
</div>
<div class="container">
    <section class="page-header-padding bg-white account-page edit-account">
        <div class="row">
            <div class="col-md-12 col-lg-2 col-xl-2">
                <div class="account-tab account-tab-hide">
                    <ul class="list-group">
                        <li class="list-group-item">
                            <a routerLink="/my-account/edit-account">
                                <svg class="svg-inline--fa fa-user fa-w-14 d-block d-lg-none account-icon"
                                    aria-hidden="true" focusable="false" data-prefix="far" data-icon="user" role="img"
                                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg="">
                                    <path fill="currentColor"
                                        d="M313.6 304c-28.7 0-42.5 16-89.6 16-47.1 0-60.8-16-89.6-16C60.2 304 0 364.2 0 438.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-25.6c0-74.2-60.2-134.4-134.4-134.4zM400 464H48v-25.6c0-47.6 38.8-86.4 86.4-86.4 14.6 0 38.3 16 89.6 16 51.7 0 74.9-16 89.6-16 47.6 0 86.4 38.8 86.4 86.4V464zM224 288c79.5 0 144-64.5 144-144S303.5 0 224 0 80 64.5 80 144s64.5 144 144 144zm0-240c52.9 0 96 43.1 96 96s-43.1 96-96 96-96-43.1-96-96 43.1-96 96-96z">
                                    </path>
                                </svg><!-- <i class="far fa-user d-block d-lg-none account-icon"></i> -->
                                <span class="d-none d-lg-block">Account</span>
                            </a>
                        </li>

                        <li class="list-group-item"><a
                                routerLink="/my-account/my-listings">Proposte
                                <div *ngIf="countMatchs.proposal_matchs > 0" class="badge badge-secondary"> <svg class="svg-inline--fa fa-envelope fa-w-16"
                                    aria-hidden="true" focusable="false" data-prefix="fa" data-icon="envelope" role="img"
                                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
                                    <path fill="currentColor"
                                       d="M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z">
                                    </path>
                                 </svg>
                                 <span style="margin-left: 5px;">{{countMatchs.proposal_matchs}}</span>
                              </div>
                            </a></li>
                        <li class="list-group-item list-group-li active"><a>Ricerche
                            <div *ngIf="countMatchs.search_matchs !== 0" class="badge badge-success"> <svg class="svg-inline--fa fa-envelope fa-w-16" aria-hidden="true" focusable="false" data-prefix="fa" data-icon="envelope" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z"></path></svg>
                                <span style="margin-left: 5px;">{{countMatchs.search_matchs}}</span>
                            </div>
                            </a></li>
                        <li class="list-group-item"><a
                                routerLink="/my-account/logout">Logout</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-md-12 col-lg-10 col-xl-10">
                <div class="add-proposal-btn">
                    <a class="btn btn-primary btn-green" routerLink="/inserimento-ricerca">
                        AGGIUNGI RICERCA
                        <svg class="svg-inline--fa fa-angle-right fa-w-8" aria-hidden="true" focusable="false"
                            data-prefix="fas" data-icon="angle-right" role="img" xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 256 512" data-fa-i2svg="">
                            <path fill="currentColor"
                                d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z">
                            </path>
                        </svg><!-- <i class="fas fa-angle-right"></i> -->
                    </a>
                </div>
                <div class="form-design">
                    <div class="account-form-box bg-green account-form-box-ricerche">

                        <div class="account-form-title color-green">
                            <span class="rounded-circle">
                                <svg class="svg-inline--fa fa-search fa-w-16 color-white" aria-hidden="true"
                                    focusable="false" data-prefix="fas" data-icon="search" role="img"
                                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
                                    <path fill="currentColor"
                                        d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z">
                                    </path>
                                </svg><!-- <i class="fas fa-search color-white"></i> -->
                            </span>
                            <h5>Ricerche</h5>
                        </div>

                        <p class="mb-0 fs-14">Nota: le schede candidate saranno eliminate alla scadenza impostata:</p>

                        <nav class="navbar navbar-expand-lg navbar-light bg-light search-list nav-green-bg">
                            <button class="navbar-toggler nav-btn-bg-color-green" type="button" data-toggle="collapse"
                                onchange="toggle()" data-target="#navbarNav" aria-controls="navbarNav"
                                aria-expanded="false" aria-label="Toggle navigation" (click)="navbarOnMobile = !navbarOnMobile">
                                <span class="navbar-toggler-icon menu-svg">
                                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                        xmlns:svgjs="http://svgjs.com/svgjs" version="1.1" width="30px" height="30px"
                                        x="0" y="0" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512"
                                        xml:space="preserve" class="">
                                        <g>
                                            <g xmlns="http://www.w3.org/2000/svg">
                                                <g>
                                                    <path
                                                        d="M501.333,96H10.667C4.779,96,0,100.779,0,106.667s4.779,10.667,10.667,10.667h490.667c5.888,0,10.667-4.779,10.667-10.667    S507.221,96,501.333,96z"
                                                        fill="#ffffff" data-original="#000000"  class=""></path>
                                                </g>
                                            </g>
                                            <g xmlns="http://www.w3.org/2000/svg">
                                                <g>
                                                    <path
                                                        d="M501.333,245.333H10.667C4.779,245.333,0,250.112,0,256s4.779,10.667,10.667,10.667h490.667    c5.888,0,10.667-4.779,10.667-10.667S507.221,245.333,501.333,245.333z"
                                                        fill="#ffffff" data-original="#000000"  class=""></path>
                                                </g>
                                            </g>
                                            <g xmlns="http://www.w3.org/2000/svg">
                                                <g>
                                                    <path
                                                        d="M501.333,394.667H10.667C4.779,394.667,0,399.445,0,405.333C0,411.221,4.779,416,10.667,416h490.667    c5.888,0,10.667-4.779,10.667-10.667C512,399.445,507.221,394.667,501.333,394.667z"
                                                        fill="#ffffff" data-original="#000000"  class=""></path>
                                                </g>
                                            </g>
                                            <g xmlns="http://www.w3.org/2000/svg">
                                            </g>
                                            <g xmlns="http://www.w3.org/2000/svg">
                                            </g>
                                            <g xmlns="http://www.w3.org/2000/svg">
                                            </g>
                                            <g xmlns="http://www.w3.org/2000/svg">
                                            </g>
                                            <g xmlns="http://www.w3.org/2000/svg">
                                            </g>
                                            <g xmlns="http://www.w3.org/2000/svg">
                                            </g>
                                            <g xmlns="http://www.w3.org/2000/svg">
                                            </g>
                                            <g xmlns="http://www.w3.org/2000/svg">
                                            </g>
                                            <g xmlns="http://www.w3.org/2000/svg">
                                            </g>
                                            <g xmlns="http://www.w3.org/2000/svg">
                                            </g>
                                            <g xmlns="http://www.w3.org/2000/svg">
                                            </g>
                                            <g xmlns="http://www.w3.org/2000/svg">
                                            </g>
                                            <g xmlns="http://www.w3.org/2000/svg">
                                            </g>
                                            <g xmlns="http://www.w3.org/2000/svg">
                                            </g>
                                            <g xmlns="http://www.w3.org/2000/svg">
                                            </g>
                                        </g>
                                    </svg>
                                </span>
                            </button>
                            <div class="collapse navbar-collapse green-background navbar-desktop" id="navbarNav">
                                <div class="btn-group">
                                    <ul class="table-responsive navbar-nav hide-menubar-preview">
                                        <li style="font-size: 12px;font-weight: bolder;text-transform: uppercase;display: flex;cursor: pointer;"
                                            *ngFor="let section of sections" [ngClass]="{'link-active-green ': isCurrentSection(section.section)}"
                                            class="nav-item nav-ul-li">
                                            <a (click)="changeSection(section.section)" class="dropdown-link th-btn-padding nav-link padding-left-right">
                                                {{section.nice_name}}
                                                <strong class="message-icon" *ngIf="section.number_matchs > 0"> {{section.number_matchs}} </strong>
                                            </a>
                                            <span class="listing-gap"></span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="collapse navbar-collapse green-background" id="navbarNav" style="display: block!important;"  *ngIf="navbarOnMobile">
                                <div class="btn-group">
                                    <ul class="table-responsive navbar-nav hide-menubar-preview">
                                        <li style="font-size: 12px;font-weight: bolder;text-transform: uppercase;display: flex;cursor: pointer;"
                                            *ngFor="let section of sections" [ngClass]="{'link-active-green ': isCurrentSection(section.section)}"
                                            class="nav-item nav-ul-li">
                                            <a (click)="changeSection(section.section)" class="dropdown-link th-btn-padding nav-link padding-left-right">
                                                {{section.nice_name}}
                                                <strong class="message-icon" *ngIf="section.number_matchs > 0"> {{section.number_matchs}} </strong>
                                            </a>
                                            <span class="listing-gap"></span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </nav>
                        <br>

                        <div class="table-responsive">
                            <div id="example1_wrapper" class="dataTables_wrapper no-footer">
                                <div class="dataTables_scroll">
                                    <div class="dataTables_scrollHead"
                                        style="overflow: hidden; position: relative; border: 0px; width: 100%;">
                                        <div class="dataTables_scrollHeadInner"
                                            style="box-sizing: content-box; width: 853px; padding-right: 0px;">
                                            <table class="table dataTable no-footer hide-for-mobile" role="grid"
                                                style="margin-left: 0px; width: 853px;">
                                                <thead class="thead-dark">
                                                    <tr role="row">
                                                        <th class="sorting_asc" rowspan="1" colspan="1"
                                                            style="width: 144.5px;" aria-label="Visiona scheda/e">
                                                            Visiona scheda/e</th>
                                                        <th class="sorting_disabled" rowspan="1" colspan="1"
                                                            style="width: 145.312px;" aria-label="Tipologia">Tipologia
                                                        </th>
                                                        <th class="sorting" tabindex="0" aria-controls="example1"
                                                            rowspan="1" colspan="1" style="width: 132.969px;"
                                                            aria-label="Job alert: activate to sort column ascending">
                                                            Job alert</th>
                                                        <th class="sorting" tabindex="0" aria-controls="example1"
                                                            rowspan="1" colspan="1" style="width: 146.297px;"
                                                            aria-label="Scadenza: activate to sort column ascending">
                                                            Scadenza</th>
                                                        <th class="sorting" tabindex="0" aria-controls="example1"
                                                            rowspan="1" colspan="1" style="width: 137.656px;"
                                                            aria-label="Status: activate to sort column ascending">
                                                            Status</th>
                                                        <th class="sorting_disabled" rowspan="1" colspan="1"
                                                            style="width: 146.266px;" aria-label="Matching">Matching
                                                        </th>
                                                    </tr>
                                                </thead>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="dataTables_scrollBody" style="overflow: auto; width: 100%;">
                                        <table class="table dataTable no-footer hide-for-mobile" id="example1" role="grid"
                                            aria-describedby="example1_info" style="width: 853px;">
                                            <thead class="thead-dark">
                                                <tr role="row" style="height: 0px;">
                                                    <th class="sorting_asc" rowspan="1" colspan="1"
                                                        style="width: 144.5px; padding-top: 0px; padding-bottom: 0px; border-top-width: 0px; border-bottom-width: 0px; height: 0px;"
                                                        aria-label="Visiona scheda/e">
                                                        <div class="dataTables_sizing"
                                                            style="height:0;overflow:hidden;">Visiona scheda/e</div>
                                                    </th>
                                                    <th class="sorting_disabled" rowspan="1" colspan="1"
                                                        style="width: 145.312px; padding-top: 0px; padding-bottom: 0px; border-top-width: 0px; border-bottom-width: 0px; height: 0px;"
                                                        aria-label="Tipologia">
                                                        <div class="dataTables_sizing"
                                                            style="height:0;overflow:hidden;">Tipologia</div>
                                                    </th>
                                                    <th class="sorting" aria-controls="example1" rowspan="1" colspan="1"
                                                        style="width: 132.969px; padding-top: 0px; padding-bottom: 0px; border-top-width: 0px; border-bottom-width: 0px; height: 0px;"
                                                        aria-label="Job alert: activate to sort column ascending">
                                                        <div class="dataTables_sizing"
                                                            style="height:0;overflow:hidden;">Job alert</div>
                                                    </th>
                                                    <th class="sorting" aria-controls="example1" rowspan="1" colspan="1"
                                                        style="width: 146.297px; padding-top: 0px; padding-bottom: 0px; border-top-width: 0px; border-bottom-width: 0px; height: 0px;"
                                                        aria-label="Scadenza: activate to sort column ascending">
                                                        <div class="dataTables_sizing"
                                                            style="height:0;overflow:hidden;">Scadenza</div>
                                                    </th>
                                                    <th class="sorting" aria-controls="example1" rowspan="1" colspan="1"
                                                        style="width: 137.656px; padding-top: 0px; padding-bottom: 0px; border-top-width: 0px; border-bottom-width: 0px; height: 0px;"
                                                        aria-label="Status: activate to sort column ascending">
                                                        <div class="dataTables_sizing"
                                                            style="height:0;overflow:hidden;">Status</div>
                                                    </th>
                                                    <th class="sorting_disabled" rowspan="1" colspan="1"
                                                        style="width: 146.266px; padding-top: 0px; padding-bottom: 0px; border-top-width: 0px; border-bottom-width: 0px; height: 0px;"
                                                        aria-label="Matching">
                                                        <div class="dataTables_sizing"
                                                            style="height:0;overflow:hidden;">Matching</div>
                                                    </th>
                                                </tr>
                                            </thead>

                                            <tbody *ngIf="!currentSection">
                                            
                                                <tr class="odd">
                                                    <td valign="top" colspan="6" class="dataTables_empty">Nessuna ricerca presente.</td>
                                                </tr>
                                            </tbody>
                                            <tbody *ngIf="currentSection">
                                                <tr role="row" class="odd" *ngFor="let search of currentSection.searchs" [ngClass]="{'expires': isExpires(search)}">
                                                    <td>
                                                        <a routerLink="/inserimento-ricerca/searches/{{currentSection.slug}}/{{search.uuid}}">
                                                            <p class="listing-title">{{search.name}}</p><br>
                                                        </a><br><br>
                                                    </td>
                                                    <td></td>
                                                    <td>{{search.date | date: 'dd/MM/yyyy'}}</td>
                                                    <td>{{search.expires | date: 'dd/MM/yyyy'}}</td>
                                                    <td>{{getStatus(search)}}<br><br></td>
                                                    <td>
                                                        <button *ngIf="!hasMatchs(search)" (click)="view(search)" type="button" style="width: 120px; border-radius: 1px" 
                                                        class="btn btn-primary btn-sm btn-block bg-color-green btn-flat" data-toggle="modal" disabled>NO MATCH</button>
                                                        <button *ngIf="hasMatchs(search) && search.number_matchs == 0 && !isExpires(search)" (click)="view(search)" type="button" style="width: 120px; border-radius: 1px" 
                                                        class="btn btn-primary btn-sm btn-block bg-color-green btn-flat" data-toggle="modal" >Visiona</button>
                                                        <button *ngIf="hasMatchs(search) &&  search.number_matchs > 0 && !isExpires(search)" (click)="view(search)" type="button" style="width: 120px; border-radius: 1px" 
                                                           class="btn btn-primary btn-sm btn-block bg-color-green btn-flat" data-toggle="modal">Visiona 
                                                           |
                                                           <svg class="svg-inline--fa fa-envelope fa-w-16" aria-hidden="true" focusable="false" data-prefix="fa"
                                                              data-icon="envelope" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
                                                              <path fill="currentColor"
                                                                 d="M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z">
                                                              </path>
                                                           </svg><!-- <i class="fa fa-envelope"></i> -->
                                                           {{search.number_matchs}} 
                                                        </button>

                                                        <button *ngIf="isExpires(search)" disabled type="button" style="width: 120px; border-radius: 1px" 
                                                            class="btn btn-primary btn-sm btn-block bg-color-green btn-flat" data-toggle="modal">Scaduta 
                                                         </button>

                                                        <a class="listing-delete" href="javascript:;" title="Delete" (click)="delete(search)"><svg
                                                                class="svg-inline--fa fa-trash fa-w-14" aria-hidden="true" focusable="false" data-prefix="fa"
                                                                data-icon="trash" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"
                                                                data-fa-i2svg="">
                                                                <path fill="currentColor"
                                                                    d="M432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16zM53.2 467a48 48 0 0 0 47.9 45h245.8a48 48 0 0 0 47.9-45L416 128H32z">
                                                                </path>
                                                            </svg><!-- <i class="fa fa-trash"></i> --> </a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class="dataTables_info" id="example1_info" role="status" aria-live="polite">Showing
                                    0 to 0 of 0 entries</div>
                                <div class="dataTables_paginate paging_simple_numbers" id="example1_paginate"><a
                                        class="paginate_button previous disabled" aria-controls="example1"
                                        data-dt-idx="0" tabindex="0" id="example1_previous">Previous</a><span></span><a
                                        class="paginate_button next disabled" aria-controls="example1" data-dt-idx="1"
                                        tabindex="0" id="example1_next">Next</a></div>
                            </div>
                        </div>

                        <div class="show-for-mobile" *ngIf="currentSection">
                            <div *ngFor="let search of currentSection.searchs" [ngClass]="{'expires': isExpires(search)}">
                               
                               <table style="font-size:14px!important;width:100%">
                                  <tr>
                                     <td style="padding-bottom: 1rem;vertical-align: baseline;width: 115px;">Visiona scheda/e</td>
                                     <td style="padding-bottom: 1rem;">
                                        <a routerLink="/inserimento-ricerca/searches/{{currentSection.slug}}/{{search.uuid}}">
                                            <p style="margin: 0;font-size: 14px;">{{search.name}}</p>
                                        </a>
                                     </td>
                                  </tr>
                                  <tr>
                                     <td style="padding-bottom: 1rem;">Job alert</td>
                                     <td style="padding-bottom: 1rem;">{{search.date | date: 'dd/MM/yyyy'}}</td>
                                  </tr>
                                  <tr>
                                    <td style="padding-bottom: 1rem;">Scandenza</td>
                                    <td style="padding-bottom: 1rem;">{{search.expires | date: 'dd/MM/yyyy'}}</td>
                                 </tr>
                                  <tr>
                                     <td style="padding-bottom: 1rem;">Status</td>
                                     <td style="padding-bottom: 1rem;"> {{getStatus(search)}} </td>
                                  </tr>
                                  <tr>
                                     <td style="padding-bottom: 1rem;vertical-align: baseline;">Matching</td>
                                     <td style="padding-bottom: 1rem;">
                                        <button *ngIf="!hasMatchs(search)" (click)="view(search)" type="button" style="width: 100%; border-radius: 1px" 
                                        class="btn btn-primary btn-sm btn-block bg-color-green btn-flat" data-toggle="modal" disabled>NO MATCH</button>
                                        <button *ngIf="hasMatchs(search) && search.number_matchs == 0 && !isExpires(search)" (click)="view(search)" type="button" style="width: 100%; border-radius: 1px" 
                                        class="btn btn-primary btn-sm btn-block bg-color-green btn-flat" data-toggle="modal" >Visiona</button>
                                        <button *ngIf="hasMatchs(search) &&  search.number_matchs > 0 && !isExpires(search)" (click)="view(search)" type="button" style="width: 100%; border-radius: 1px" 
                                           class="btn btn-primary btn-sm btn-block bg-color-green btn-flat" data-toggle="modal">Visiona 
                                           |
                                           <svg class="svg-inline--fa fa-envelope fa-w-16" aria-hidden="true" focusable="false" data-prefix="fa"
                                              data-icon="envelope" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
                                              <path fill="currentColor"
                                                 d="M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z">
                                              </path>
                                           </svg><!-- <i class="fa fa-envelope"></i> -->
                                           {{search.number_matchs}} 
                                        </button>

                                        <button *ngIf="isExpires(search)" disabled type="button" style="width: 100%; border-radius: 1px" 
                                            class="btn btn-primary btn-sm btn-block bg-color-green btn-flat" data-toggle="modal">Scaduta 
                                         </button>

                                        <a class="listing-delete" href="javascript:;" title="Delete" (click)="delete(search)" style="float: none;"><svg
                                                class="svg-inline--fa fa-trash fa-w-14" aria-hidden="true" focusable="false" data-prefix="fa"
                                                data-icon="trash" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"
                                                data-fa-i2svg="">
                                                <path fill="currentColor"
                                                    d="M432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16zM53.2 467a48 48 0 0 0 47.9 45h245.8a48 48 0 0 0 47.9-45L416 128H32z">
                                                </path>
                                            </svg><!-- <i class="fa fa-trash"></i> --> </a>
                                     </td>
                                  </tr>
                               </table>
                               <hr />
                            </div>
                         </div>


                    </div>
                </div>
            </div>
        </div>
    </section>
</div>